.plt-asccess-description {
  min-width: fit-content;
  .asccess-description-block {
    .asccess-description-text {
      gap: 24px;
      .plt-badge {
        display: block;
      }
    }
  }
  // max-width: 150px;
}

.plt-asccess-wrapper {
  gap: var(--plt-space-400);
}

.plt-role-wrapper {
  max-width: 280px;

  .plt-role-description {
    // max-width: 220px;
    flex: auto;
  }
}

.privacy-dropdown {

  .css-1pty5lh-control:before,
  .css-1pty5lh-control:after {
    z-index: 0;
  }
}