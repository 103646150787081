// font family
// .font-family-primary {
//   font-family: $base-font-family;
// }

// :root {
//   --plt-font-family-primary: var(--plt-font-family-primary);
//   --plt-font-family-secondary: var(--plt-font-family-secondary);
// }

.plt-font-family-primary {
  font-family: var(--plt-font-family-primary);
}

.plt-font-family-secondary {
  font-family: var(--plt-font-family-secondary);
}

// Font


/// example
.plt-heading-3xl {
  color: var(--plt-text-secondary);
  font-size: var(--plt-heading-3xl-font-size);
  font-weight: var(--plt-heading-3xl-font-weight);
  line-height: var(--plt-heading-3xl-line-height);
  // font-family: var(--plt-heading-3xl-font-family-secondary);
  font-family: var(--plt-heading-3xl-font-family);

}

//

.plt-heading-2xl {
  color: var(--plt-text-secondary);
  font-size: var(--plt-heading-2xl-font-size);
  font-weight: var(--plt-heading-2xl-font-weight);
  line-height: var(--plt-heading-2xl-line-height);
  font-family: var(--plt-heading-2xl-font-family);
}

.plt-heading-xl {
  color: var(--plt-text-secondary);
  font-size: var(--plt-heading-xl-font-size);
  font-weight: var(--plt-heading-xl-font-weight);
  line-height: var(--plt-heading-xl-line-height);
  font-family: var(--plt-heading-xl-font-family);
}

.plt-heading-lg {
  color: var(--plt-text-secondary);
  font-size: var(--plt-heading-lg-font-size);
  font-weight: var(--plt-heading-lg-font-weight);
  line-height: var(--plt-heading-lg-line-height);
  font-family: var(--plt-heading-lg-font-family);
}

.plt-heading-md {
  color: var(--plt-text-secondary);
  font-size: var(--plt-heading-md-font-size);
  font-weight: var(--plt-heading-md-font-weight);
  line-height: var(--plt-heading-md-line-height);
  font-family: var(--plt-heading-md-font-family);
}

.plt-heading-sm {
  color: var(--plt-text-secondary);
  font-size: var(--plt-heading-sm-font-size);
  font-weight: var(--plt-heading-sm-font-weight);
  line-height: var(--plt-heading-sm-line-height);
  font-family: var(--plt-heading-sm-font-family);
}

.plt-heading-xs {
  color: var(--plt-text-secondary);
  font-size: var(--plt-heading-xs-font-size);
  font-weight: var(--plt-heading-xs-font-weight);
  line-height: var(--plt-heading-xs-line-height);
  font-family: var(--plt-heading-xs-font-family);
}

.plt-heading-xxs {
  color: var(--plt-text-secondary);
  font-size: var(--plt-heading-xxs-font-size);
  font-weight: var(--plt-heading-xxs-font-weight);
  line-height: var(--plt-heading-xxs-line-height);
  font-family: var(--plt-heading-xxs-font-family);
}


//body example
.plt-body-lg {
  color: var(--plt-text-secondary);
  font-size: var(--plt-body-lg-font-size);
  font-weight: var(--plt-body-lg-font-weight);
  line-height: var(--plt-body-lg-line-height);
  font-family: var(--plt-body-lg-font-family);
}

//

.plt-body-md {
  color: var(--plt-text-secondary);
  font-size: var(--plt-body-md-font-size);
  font-weight: var(--plt-body-md-font-weight);
  line-height: var(--plt-body-md-line-height);
  font-family: var(--plt-body-md-font-family);
}

.plt-body-sm {
  color: var(--plt-text-secondary);
  font-size: var(--plt-body-sm-font-size);
  font-weight: var(--plt-body-sm-font-weight);
  line-height: var(--plt-body-sm-line-height);
  font-family: var(--plt-body-sm-font-family);

}

.plt-body-xs {
  color: var(--plt-text-secondary);
  font-size: var(--plt-body-xs-font-size);
  font-weight: var(--plt-body-xs-font-weight);
  line-height: var(--plt-body-xs-line-height);
  font-family: var(--plt-body-xs-font-family);
}

.plt-body-compact-md {
  color: var(--plt-text-secondary);
  font-size: var(--plt-body-compact-md-font-size);
  font-weight: var(--plt-body-compact-md-font-weight);
  line-height: var(--plt-body-compact-md-line-height);
  font-family: var(--plt-body-compact-md-font-family);
}

.plt-body-compact-sm {
  color: var(--plt-text-secondary);
  font-size: var(--plt-body-compact-sm-font-size);
  font-weight: var(--plt-body-compact-sm-font-weight);
  line-height: var(--plt-body-compact-sm-line-height);
  font-family: var(--plt-body-compact-sm-font-family);
}

.plt-body-compact-xs {
  color: var(--plt-text-secondary);
  font-size: var(--plt-body-compact-xs-font-size);
  font-weight: var(--plt-body-compact-xs-font-weight);
  line-height: var(--plt-body-compact-xs-line-height);
  font-family: var(--plt-body-compact-xs-font-family);
}

// ui text
.plt-ui-text-lg-medium {
  color: var(--plt-ui-text-lg-medium-text-secondary);
  font-size: var(--plt-ui-text-lg-medium-font-size);
  font-weight: var(--plt-ui-text-lg-medium-font-weight);
  line-height: var(--plt-ui-text-lg-medium-line-height);
  font-family: var(--plt-ui-text-lg-medium-font-family);
}

//
// .plt-ui-text-lg-semi-bold {
//   color: var(--plt-text-secondary);
//   font-size: var(--plt-font-size-400);
//   font-weight: var(--plt-font-weight-semibold);
//   line-height: var(--plt-line-height-120);
// }

.plt-ui-text-md-medium {
  color: var(--plt-text-secondary);
  font-size: var(--plt-ui-text-md-medium-font-size);
  font-weight: var(--plt-ui-text-md-medium-font-weight);
  line-height: var(--plt-ui-text-md-medium-line-height);
  font-family: var(--plt-ui-text-md-medium-font-family);
}

// .plt-ui-text-md-semi-bold {
//   color: var(--plt-text-secondary);
//   font-size: var(--plt-font-size-300);
//   font-weight: var(--plt-font-weight-semibold);
//   line-height: var(--plt-line-height-120);
// }
.plt-ui-text-sm-regular {
  color: var(--plt-text-secondary);
  font-size: var(--plt-ui-text-sm-regular-font-size);
  font-weight: var(--plt-ui-text-sm-regular-font-weight);
  line-height: var(--plt-ui-text-sm-regular-line-height);
  font-family: var(--plt-ui-text-sm-regular-font-family);
}


.plt-ui-text-sm-medium {
  color: var(--plt-text-secondary);
  font-size: var(--plt-ui-text-sm-medium-font-size);
  font-weight: var(--plt-ui-text-sm-medium-font-weight);
  line-height: var(--plt-ui-text-sm-medium-line-height);
  font-family: var(--plt-ui-text-sm-medium-font-family);
}

.plt-ui-text-sm-semi-bold {
  color: var(--plt-text-secondary);
  font-size: var(--plt-ui-text-sm-semi-bold-font-size);
  font-weight: var(--plt-ui-text-sm-semi-bold-font-weight);
  line-height: var(--plt-ui-text-sm-semi-bold-line-height);
  font-family: var(--plt-ui-text-sm-semi-bold-font-family);
}

.plt-ui-text-xs-medium {
  color: var(--plt-text-secondary);
  font-size: var(--plt-ui-text-xs-medium-font-size);
  font-weight: var(--plt-ui-text-xs-medium-font-weight);
  line-height: var(--plt-ui-text-xs-medium-line-height);
  font-family: var(--plt-ui-text-xs-medium-font-family);
}

// .plt-ui-text-xs-semi-bold {
//   color: var(--plt-text-secondary);
//   font-size: var(--plt-font-size-100);
//   font-weight: var(--plt-font-weight-semibold);
//   line-height: var(--plt-line-height-120);
// }



// colors
.plt-font-color-primary {
  color: var(--plt-text-primary) !important;
}

.plt-font-color-secondary {
  color: var(--plt-text-secondary) !important;
}

.plt-font-color-tertiary {
  color: var(--plt-text-tertiary) !important;
}

.plt-font-color-negative {
  color: var(--plt-text-negative) !important;
}

.plt-font-color-light {
  color: var(--plt-text-on-bg-fill) !important;
}

// Font transform
.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

// Font-weight
.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

// Line Heights
.line-height-12 {
  line-height: 1.2;
}

.line-height-13 {
  line-height: 1.3;
}

.line-height-14 {
  line-height: 1.4;
}

.line-height-15 {
  line-height: 1.5;
}
