.component-card {
  border: 1px solid var(--plt-border-secondary);
  border-radius: var(--plt-border-radius-300);

  .component-edit-btn {
    position: absolute;
    right: var(--plt-space-600);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
}

.component-area-ui {
  position: relative;
  padding: var(--plt-space-800) var(--plt-space-600);
  border-radius: var(--plt-border-radius-300) var(--plt-border-radius-300) 0 0;
  transition: all 0.3s;

  @media (max-width: 1024px) {
    overflow-x: scroll;
    // justify-content: flex-start !important;
  }

  &:hover {
    .component-edit-btn {
      opacity: 1;
      visibility: visible;
    }
  }

  .plt-modal-overlay {
    overflow: auto;
  }
}

.component-area-code {
  position: relative;
  background: var(--plt-background-tertiary);
  padding: var(--plt-space-600);
  border-top: 1px solid var(--plt-border-input);
  border-radius: 0 0 var(--plt-border-radius-300) var(--plt-border-radius-300);
  transition: all 0.3s;

  &:hover {
    .component-copy-btn {
      opacity: 1;
      visibility: visible;
    }
  }

  &-wrapper {
    overflow: auto;

    * {
      // font-family: var(--plt-body-compact-sm-font-family);
      font-family: monospace;
    }
  }

  pre {
    max-width: 370px;
  }

  .component-copy-btn {
    position: absolute;
    top: var(--plt-space-400);
    right: var(--plt-space-600);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;

    .plt-iconbtn {
      --plt-iconbtn-bg-color: var(--plt-background-secondary);
    }
  }
}
