@import "../../../assets/scss/helpers/responsive";


// .radius-card-wrapper {
//   .plt-common-card {
//     min-width: calc(50% - 12px);
//     max-width: calc(50% - 12px);
//     border: 1px solid var(--plt-border-secondary);
//     border-radius: var(--plt-border-radius-300);
//     .plt-common-card-img-box{
//       border: 1px solid var(--plt-border-secondary);
//       box-shadow: none;
//         &.dss-small-radius {
//             border-radius: var(--dss-border-radius-150);
//         }
//         &.dss-medium-radius {
//           border-radius: var(--dss-border-radius-400);
//         }
//         &.dss-large-radius {
//           border-radius: var(--dss-border-radius-700);
//         }
//         &.dss-full-radius {
//           border-radius: var(--dss-border-radius-full);
//         }
//       }
//   }


// }


.plt-usage-card-wrapper {
  max-width: 840px;
  flex-wrap: wrap;
  row-gap: 24px;

  .plt-usage-card-item {
    min-width: calc(50% - 12px);
    max-width: calc(50% - 12px);
    display: flex;
    flex-direction: column;

    @include mq(desktop-sm) {
      min-width: 100%;
      max-width: 100%;
    }
  }

  .plt-common-card {
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    // border-radius: var(--plt-border-radius-300);
    // border: 1px solid var(--plt-border-secondary);
    // background: var(--plt-background-tertiary);
  }

  .plt-common-card-content {
    flex-direction: column;
    background: var(--plt-background-on-bg-default);
    border-radius: var(--plt-border-radius-150) var(--plt-border-radius-150) 0 0;
    border: 1px solid var(--plt-border-secondary);
    border-bottom: 0;
    padding: 18px;
    gap: var(--plt-paragraph-spacing-200);
    // .plt-common-card-info {
    //   width: 90%;
    // }
  }
}
