.upgrade {
  &-img {
    max-width: 445px;
    border-radius: var(--plt-border-radius-200);
    overflow: hidden;
  }
  &-content {
    max-width: 445px;
  }
  &-list {
    list-style: none;
    &-item {
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }
}

