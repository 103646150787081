// @import "src/assets/scss/helpers/responsive";

.plt-card-container {
  --card-column-count: 4;
  --card-column-gap: var(--plt-space-600);
  display: grid;
  grid-template-columns: repeat(var(--card-column-count), 1fr);
  gap: var(--card-column-gap);

  @include mq(desktop-sm) {
    --card-column-count: 3;
  }

  @include mq(phone) {
    --card-column-count: 2;
  }

  @include mq(phone-sm) {
    --card-column-count: 1;
  }

  .plt-card {
    padding: var(--plt-space-300);
    border: 1px solid transparent;
    background: var(--plt-background-tertiary);
    border-radius: var(--plt-border-radius-300);
    transition: all 0.35s;

    &:hover {
      background: var(--plt-background-tertiary);
      border-color: var(--plt-border-secondary);
    }

    // @include mq(desktop) {
    //   max-width: 270px;
    //   min-width: 270px;
    // }
    // @include mq(desktop) {
    //   max-width: 220px;
    //   min-width: 220px;
    // }
    // @include mq(tablet) {
    //   max-width: 100%;
    //   min-width: 100%;
    // }

    &.active {
      background: var(--plt-background-tertiary);
      border-color: var(--plt-border-secondary);
    }

    .plt-card-desc {
      display: block;
    }
  }

  .plt-card-image {
    background-color: var(--plt-background-tertiary);
    border-radius: var(--plt-border-radius-200);
    overflow: hidden;

    img {
      min-width: 100%;
      //   max-width: 263px;
      //   min-width: 263px;
      min-height: 50px;
      max-height: 177px;
      object-fit: cover;
      //   // background-size: cover;
    }
  }

  // &.plt-card-inside {
  //   // column-gap: var(--plt-space-800);
  //   // max-width: 925px;

  //   .plt-card {
  //     // border-radius: var(--plt-border-radius-300);
  //     // transition: all 0.35s;

  //     &:hover {
  //       border-color: transparent;
  //     }
  //   }
  // }

  // .plt-card-body {

  // }
}
