.preview-page {
.container{
    display: block;
    max-width: 1280px;
    padding: 0 2%;
    margin: 0 auto;
    height: auto;
}

/*HEADER*/

header{
    background-image: url('https://i.postimg.cc/sfMRrnZM/bg.jpg');
    min-height: 600px;
    padding: 30px 0;
    background-size: cover;
    background-position: center;
}

.logo{
    position: relative;
    z-index: 2;
    background-image: url('https://www.aufaitux.com/ux-newsletter-img/aufaituxlogowhite.png');
    background-size: 100% 100%;
    width: 200px;
    height: 40px;
}

nav.desktop li{
    display: inline-block;
    margin: 0 15px;
}

nav.mobile{
    display: none;
}

nav.mobile ul{
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    padding: 70px 0 30px 0;
    top: 0;
    background: #31475e;
    text-align: center;
}

nav.mobile h3{
    position: relative;
    z-index: 2;
    color: white;
    cursor: pointer;
    font-size: 26px;
}

nav.mobile li{
    padding: 10px 0;
}

.call-header{
    padding: 110px 0 40px 0;
    max-width: 485px;
}


 .header-form{
    margin-top: 40px;
    padding-bottom: 40px;
    gap: 15px;
    .header-input {
      min-width: 300px;
      max-width: 500px;
    }
}

/*SECTION DESCRIPTION*/

 .description{
    text-align: center;
    padding: 30px 2%;
}

 .description p{
    max-width: 600px;
    margin: auto;
}

/*SECTION INFO*/

.info{
    padding: 50px 0;
}

.info .text-info{
    width: 50%;
    max-width: 450px;
}

.info .text-info h3{
    margin-top: 25px;
    text-align: center;
}

.info .text-info p{
    margin-top: 35px;
    text-align: center;
}

.info img{
    width: 50%;
    max-width: 100%;
}


/*SECTION SERVICES*/

 .services{
    padding: 25px 0;
}

.title-services{
    text-align: center;
}

.flex-services{
    display: flex;
    flex-wrap: wrap;
}

.space-services{
    display: flex;
    padding: 30px;
    width: 33.3%;
}

.box-services{
    background: white;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 8px 20px 0 rgba(224, 224, 224, .5);
    height: 100%;
}

.box-services img{
    width: 100%;
}

.wrapper-services{
    text-align: center;
    padding: 20px;
}

.wrapper-services h2{
    color: #31475e;
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 10px;
}

.wrapper-services p{
    font-size: 14px;
    color: #6a7c92;
    margin-bottom: 10px;
}




/*FOOTER*/

footer{
    margin: 50px 0;
}

.box-footer{
    width: calc((100%/3));
    padding: 0 15px;
}

.text-center {
  text-align: center;
}

.box-footer h2{
    color: #31475e;
    font-size: 22px;
    font-weight: normal;
    margin-bottom: 15px;
}

.box-footer p{
    color: #31475e;
    font-weight: lighter;
    font-size: 15px;
}

.box-footer form{
    margin-top: 10px;
}

.box-footer .dss-input{
    min-width: 275px;
}

@media screen and (max-width: 1200px){
    .box-footer{
        width: 50%;
       
    }
}



@media screen and (max-width: 768px){
    nav.desktop{
        display: none;
    }

    nav.mobile{
        display: block;
    }

}

.banner-tab {
  background: white;
}
/*SECTION FORM*/

.banner-tab{
    min-width: 550px;
    max-width: 550px;
    padding: 30px;
}

// .banner-form{
//  form {
//     display: flex;
//     flex-direction: column;
//     gap: 14px;

//  }
// }


}