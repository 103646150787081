// .plt-color-description {
//   min-width: 140px;
//   max-width: 140px;
// }

.plt-color-token-name {
  .plt-badge {
    width: max-content;
    max-width: 200px;
  }
}

.plt-color-column {
  .plt-color-box {
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    background: var(--plt-primary-1000);
    border-radius: var(--plt-border-radius-150);
  }

  .plt-color-detail {
    width: max-content;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    gap: var(--plt-space-200);
  }
}
