.tabs-container {
  display: flex;
  min-width: 250px;
  max-width: 250px;
  background: var(--plt-background-tertiary);
  opacity: 1;
  visibility: visible;
  transition: all 0.35s;

  &.active {
    transform: translateX(-100%);
  }

  .tabs-link.disabled {
    pointer-events: none;

    // h3 {
    //   color: gray !important;
    // }
  }

  .side-tabs {
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: var(--plt-space-600) var(--plt-space-1000);

    .logo {
      display: flex;
      align-items: center;
      text-decoration: none;
      position: relative;

      .user-dropdown {
        left: -20px;
        top: 52px;
      }

      .logo-icon {
        display: flex;
        align-items: center;
        overflow: hidden;
        min-width: var(--plt-logo-size);
        max-width: var(--plt-logo-size);
        // margin-right: 8px;

        img {
          // width: 100%;
          // min-width: 132px;
          // max-width: 132px;
          // max-height: 46px;
          // min-height: 46px;
          background-color: #f6f6f6;
          object-fit: cover;
          max-height: 40px;
        }

        .text-elem {
          fill: var(--plt-text-primary);
          transition: all 0.35s;
        }
      }

      .logo-edit-icon {
        position: absolute;
        right: 0;
        bottom: 0;
      }

      .logo-text {
        text-decoration: none;
      }

      .tabs-link {
        text-decoration: none;
      }

      h2 {
        color: #000;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: bold;
        line-height: 1.5;
        margin-left: var(--plt-space-300);
      }
    }

    .newpage-link {
      position: relative;

      .tabs-link {
        flex: 1 1 auto;
        color: var(--plt-text-secondary);
        text-decoration: none;
        padding: var(--plt-space-200);
        border-radius: var(--plt-border-radius-150);
        transition: all 0.4s;

        &:hover,
        &.active-text {
          color: var(--dss-color-primary-800);
          background-color: var(--plt-background-primary);
        }
      }

      .newpage-link-setting {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        cursor: pointer;
      }

      // .user-dropdown {
      //   display: none;
      // }
      &:hover {
        .newpage-link-setting {
          opacity: 1;
          visibility: visible;
          pointer-events: all;
        }
      }

      .user-dropdown {
        top: 32px;
        left: -25px;
      }
    }

    .tabs-title {
      color: var(--plt-text-tertiary);
      text-transform: uppercase;
    }
  }
}
