// .custom-dropdown {
//   position: relative; // Change to relative
//   min-width: 239px;

//   .dropdown-header {
//     display: flex;
//     justify-content: space-between;
//     transition: all 0.4s;
//     cursor: pointer;
//     padding: var(--plt-space-300);
//     border: var(--plt-border-width-100) solid var(--plt-border-secondary);
//     border-radius: var(--plt-border-radius-100);
//     background-color: var(--plt-background-surface);
//     color: var(--plt-text-primary);
//     font-size: var(--plt-ui-text-sm-medium-font-size);
//     font-weight: var(--plt-ui-text-sm-medium-font-weight);
//     line-height: var(--plt-ui-text-sm-medium-line-height);
//     font-family: var(--plt-ui-text-sm-medium-font-family);

//     &:hover {
//       border: var(--plt-border-width-100) solid var(--plt-border-tertiary);
//     }

//     .plt-custom-select-icon {
//       min-width: 16px;
//       max-width: 16px;
//       min-height: 16px;
//       max-height: 16px;
//       margin-left: var(--plt-space-200);
//     }
//   }

//   .dropdown-list {
//     position: absolute;
//     top: 105%;
//     left: 0;
//     right: 0;
//     border: 1px solid var(--plt-border-secondary);
//     border-radius: var(--plt-border-radius-100);
//     background-color: var(--plt-background-surface);
//     padding: var(--plt-space-200);
//     z-index: 1000;
//     min-width: max-content;
//     max-height: 250px;
//     overflow-y: auto;

//     .dropdown-item {
//       padding: var(--plt-space-200);
//       border-radius: var(--plt-border-radius-100);
//       cursor: pointer;
//       transition: all 0.4s;

//       &:hover {
//         background-color: var(--plt-background-secondary);
//       }
//     }
//   }

//   &.modal-dropdown {
//     .dropdown-list {
//       position: fixed; // Change to fixed
//     }
//   }
// }


.custom-dropdown {

  .react-select__control {
    background-color: var(--plt-background-tertiary);
    min-height: 44px;
    border: 0 !important;
    cursor: pointer;
    transition: all 0.35s;

    &::before,
    &::after {
      top: initial;
      background-color: var(--plt-background-inverse);
      transition: all 0.35s;
    }
  }

  .react-select__value-container {
    padding: 0 12px;

    .react-select__single-value {
      color: var(--plt-text-primary);
      font-size: var(--plt-ui-text-sm-medium-font-size);
      font-weight: var(--plt-ui-text-sm-medium-font-weight);
      line-height: var(--plt-ui-text-sm-medium-line-height);
      font-family: var(--plt-ui-text-sm-medium-font-family);
      text-transform: capitalize;
      margin: 0;
    }

    .react-select__input {
      color: var(--plt-text-primary) !important;
      font-size: var(--plt-ui-text-sm-medium-font-size) !important;
      font-weight: var(--plt-ui-text-sm-medium-font-weight) !important;
      line-height: var(--plt-ui-text-sm-medium-line-height) !important;
      font-family: var(--plt-ui-text-sm-medium-font-family) !important;
    }

    .css-1jqq78o-placeholder {
      margin: 0;
      font-size: var(--plt-font-size-200);
      font-weight: var(--plt-font-weight-medium);
      color: var(--plt-text-primary);
    }
  }

  .react-select__menu {
    right: 0;
    min-width: 155px;
    background-color: var(--dss-color-neutrals-100);
    font-size: var(--plt-ui-text-sm-medium-font-size);
    font-weight: var(--plt-ui-text-sm-medium-font-weight);
    line-height: var(--plt-ui-text-sm-medium-line-height);
    font-family: var(--plt-ui-text-sm-medium-font-family);
    text-transform: capitalize;
    padding: var(--plt-space-200);
    border: 1px solid var(--plt-border-secondary);
    border-radius: var(--plt-border-radius-100);
    box-shadow: none;

    .react-select__option {
      background-color: inherit;
      color: var(--plt-text-primary);
      transition: all 0.35s;
      cursor: pointer;

      &:hover {
        background-color: var(--plt-background-secondary);
      }
    }
  }

  // .react-select__menu-portal>div{

  //   color: var(--plt-text-primary);
  //   font-size: var(--plt-ui-text-sm-medium-font-size);
  //   font-weight: var(--plt-ui-text-sm-medium-font-weight);
  //   line-height: var(--plt-ui-text-sm-medium-line-height);
  //   font-family: var(--plt-ui-text-sm-medium-font-family);
  //   &>div {
  //     padding: var(--plt-space-200);
  //   }
  // }
}


.plt-select-dropdown-secondary {

  .css-1pty5lh-control:before,
  .css-1pty5lh-control:after {
    z-index: 0;
  }

  .css-orzr7h-control:before,
  .css-orzr7h-control:after {
    z-index: 0;
  }

  .css-9agal2-control,
  .css-orzr7h-control {
    border: none;

    &:hover {
      border: none;
    }
  }

  // .css-9agal2-control,.css-orzr7h-control {
  //   min-height: 33px;
  // }

  .react-select__control {
    // border-color: transparent;
    border: none;
    min-height: 33px;
    max-height: 33px;

    &:hover {
      border: none;
    }

  }

  .css-1jqq78o-placeholder,
  .css-1dimb5e-singleValue {
    // padding: 0;
    // margin: 0;
    // color: var(--plt-text-primary);
    min-width: 80px;
  }

  .react-select__value-container {
    padding: 6px 8px;

    // margin: 0;
    .react-select__input-container {
      padding: 0;
      margin: 0;
    }
  }

}


.plt-dropdown-secondary {
  background-color: var(--plt-background-tertiary);

  .custom-dropdown {
    border-right: var(--plt-border-width-100) solid var(--plt-border-secondary);
  }

  .react-select__control {
    background-color: var(--plt-background-tertiary);
    min-height: 41px;
    border-color: transparent;

    .react-select__value-container {
      padding: 0 24px;
    }
  }

  .react-select__menu {
    border: none;
    border-radius: 0;
    padding: 0;
    background-color: var(--plt-background-tertiary);
  }


}
