.plt-table-wrapper {
  overflow: auto;
}

.plt-table-container {
  --plt-table-border-color: var(--plt-border-secondary);
  --plt-table-border-radius: var(--plt-border-radius-200);
  width: 100%;
  // max-width: 860px;

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;
    text-align: left;

    th {
      font-family: var(--plt-font-family-secondary);
      color: var(--plt-text-tertiary);
      font-size: var(--plt-heading-xs-font-size);
      font-weight: var(--plt-heading-xs-font-weight);
      line-height: var(--plt-heading-xs-line-height);
      padding: var(--plt-space-100) var(--plt-space-400);
    }

    td {
      max-width: max-content;
      color: var(--plt-text-secondary);
      font-size: var(--plt-font-size-200);
      font-weight: var(--plt-font-weight-regular);
      line-height: var(--plt-line-height-150);
      vertical-align: top;
      padding: var(--plt-space-400);
    }

    tbody {
      tr {
        background: var(--plt-background-tertiary);

        &:hover {
          .plt-table-hover-icon {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      td {
        &:first-child {
          border-bottom-left-radius: var(--plt-table-border-radius);
          border-top-left-radius: var(--plt-table-border-radius);
        }

        &:last-child {
          border-bottom-right-radius: var(--plt-table-border-radius);
          border-top-right-radius: var(--plt-table-border-radius);
        }
      }
    }

    .plt-iconbtn {
      min-width: 24px;
      max-width: 24px;
      min-height: 24px;
      max-height: 24px;
      background: var(--plt-background-surface);
      border-radius: var(--plt-border-radius-100);
      transition: all 0.35s;

      svg {
        width: 15px;
        height: 15px;
      }

      &:hover {
        background: var(--plt-background-tertiary-hover);
      }
    }
  }
}

// .plt-color-token-name {
//   min-width: fit-content;
//   max-width: fit-content;
// }


.plt-table-secondary {
  max-width: 100%;
  border: 1px solid var(--plt-table-border-color);
  border-radius: var(--plt-table-border-radius);

  table {
    border-collapse: collapse;

    th {
      padding: var(--plt-space-200) var(--plt-space-400);

      &:first-child {
        border-top-left-radius: var(--plt-table-border-radius);
      }
    }

    tr {

      th,
      td {
        &:not(:first-child) {
          border-left: 1px solid var(--plt-table-border-color);
        }
      }
    }

    tbody {
      tr {
        background: transparent;

        &:hover {
          .plt-table-hover-icon {
            opacity: 1;
            visibility: visible;
          }
        }

        &:last-child {
          td {
            --plt-table-border-radius: var(--plt-border-radius-200);

            &:first-child {
              border-bottom-left-radius: var(--plt-table-border-radius);
            }

            &:last-child {
              border-bottom-right-radius: var(--plt-table-border-radius);
            }
          }
        }
      }

      td {
        padding: var(--plt-space-300) var(--plt-space-400);
        border-top: 1px solid var(--plt-table-border-color);
        --plt-table-border-radius: 0;

        .plt-heading-text {
          color: var(--plt-text-secondary);
        }
      }
    }
  }

  table td,
  .plt-heading-usage,
  .plt-heading-info {
    font-size: var(--plt-font-size-200);
    font-weight: var(--plt-font-weight-regular);
    color: var(--plt-text-secondary);
    line-height: var(--plt-line-height-120);
  }
}

.plt-color-token-table {
  .plt-color-token-desc {
    .plt-color-desc {
      min-width: 150px;
    }
  }
}

// .plt-table-tertiary {
//   &.plt-table {
//     table {
//       border-spacing: 0;
//       padding-bottom: 150px;

//       th {
//         padding: var(--plt-space-200) 0;
//         border-top: 1px solid var(--plt-table-border-color);
//       }

//       td {
//         border-top: 1px solid var(--plt-table-border-color);
//         border-radius: 0 !important;
//         background: transparent;
//         padding: var(--plt-space-400) 0;

//         &:first-child {
//           min-width: initial;
//         }
//       }
//     }
//   }
// }
