.dss-toaster {
  // --_toaster-paddingX: var(--dss-spacing-500);
  // --_toaster-paddingY: var(--dss-spacing-400);
  --_toaster-spacing: var(--dss-spacing-toaster-inset-normal);
  --_toaster-border-radius: var(--dss-border-radius-toaster-normal);
  --_toaster-border-width: var(--dss-border-width-toaster-normal);
  --_toaster-bg-color: var(--dss-color-background-toaster-default);
  --_toaster-msg-container-gap: var(--dss-spacing-toaster-inline-leadingicon-messagetext);
  --_toaster-content-gap: var(--dss-spacing-toaster-inline-messagetext-closeicon);
  --_toaster-msg-color: var(--dss-color-text-toaster-primary);
  --_toaster-msg-font: var(--dss-font-toaster-uitext-message-text-normal);
  // --_toaster-msg-font-weight: var(--dss-font-toaster-uitext-message-text-normal-font-weight);
  // --_toaster-msg-font-size: var(--dss-font-toaster-uitext-message-text-normal-font-size);
  --_toaster-leadingicon-size: var(--dss-sizing-toaster-icon-large);
  --_toaster-close-size: var(--dss-sizing-button-icononly-normal);
  // --_toaster-close-bg-color: var(--dss-color-background-toaster-fill-icon);
  --_toaster-close-border-radius: var(--dss-border-radius-toaster-normal);
  --_toaster-action-btn-bg: transparent;

  // position: fixed;
  // z-index: 1;
  // top: 40px;
  // right: 40px;
  display: flex;
  align-items: center;
  gap: var(--_toaster-content-gap);
  min-width: 328px;
  padding: var(--_toaster-spacing);
  border-radius: var(--_toaster-border-radius);
  background-color: var(--_toaster-bg-color);

  &:hover {
    --_toaster-bg-color: var(--dss-color-background-toaster-hovered);
  }
  &:active {
    --_toaster-bg-color: var(--dss-color-background-toaster-pressed);
  }


  &-leading {
    display: flex;
    width: 100%;
    gap: var(--_toaster-msg-container-gap);
    // &-icon {
    //   display: flex;
    //   align-items: center;
    //   max-width: var(--_toaster-leadingicon-size);
    //   max-height: var(--_toaster-leadingicon-size);
    // }
  }

  &-trailing {
    display: flex;
    gap: var(--dss-spacing-toaster-inline-actionlink-closeicon);
  }

  &-msg {
    color: var(--_toaster-msg-color);
    // font-size: var(--_toaster-msg-font-size);
    // font-weight: var(--_toaster-msg-font-weight);
    font: var(--_toaster-msg-font);
  }

  &-msg-container {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    gap: var(--_toaster-msg-container-gap);
    width: 100%;
  }

  &-close-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: var(--_toaster-close-size);
    min-height: var(--_toaster-close-size);
    max-width: var(--_toaster-close-size);
    max-height: var(--_toaster-close-size);
    // background-color: var(--_toaster-close-bg-color);
    border-radius: var(--_toaster-close-border-radius);
    border-radius: var(--dss-border-radius-full);
    margin-left: auto;
    cursor: pointer;

  }

  &-action-footer {
    flex-direction: column;
    --_toaster-msg-container-gap: var(--dss-spacing-toaster-stack-messagetext-actionlink);
  }
}
