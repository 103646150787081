.user-dropdown {
  position: absolute;
  top: 70px;
  right: 25px;
  z-index: 10;
  background-color: var(--plt-background-surface);
  border: 1px solid var(--plt-border-secondary);
  border-radius: var(--plt-border-radius-100);
  width: fit-content; // Set the width as needed
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: var(--plt-space-200);

  ul {
    list-style: none;
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    gap: var(--plt-space-200);
    min-width: 177px;
    padding: var(--plt-space-200);
    cursor: pointer;
    border-radius: var(--plt-border-radius-100);
    transition: all 0.35s;
    +.dropdown-item {
      margin-top: var(--plt-space-100);
    }

    &:hover {
      background-color: var(--plt-background-secondary);
    }
  }
}