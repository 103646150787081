/* Button Styles */
.dss-btn {
  --dss_btn-width: auto;

  --_dss-btn-bg-color: var(--dss-color-background-button-default-primary);
  --_dss-btn-font-color: var(--dss-color-text-button-default-primary);

  //typography
  // --_dss-btn-font-weight: var(--dss-font-button-uitext-normal-font-weight);
  // --_dss-btn-font-size: var(--dss-font-button-uitext-normal-font-size);
  // --_dss-btn-font-family: var(--dss-font-button-uitext-normal-font-family);
  // --_dss-btn-line-height: var(--dss-font-button-uitext-normal-line-height);
  font: var(--dss-font-button-uitext-normal);

  --_dss-btn-letter-spacing: var(--dss-font-letter-spacing-normal);
  --_dss-btn-font-case: var(--dss-text-case-none);
  --_dss-btn-text-decoration: var(--dss-text-decoration-none);
  --_dss-btn-padding: var(--dss-spacing-button-inset-normal);
  --_dss-btn-border-width: var(--dss-border-width-button-normal);
  --_dss-btn-hover-bg-color: var(--dss-color-background-button-default-hovered);
  --_dss-btn-border-color: transparent;
  --_dss-btn-hover-border-color: var(--dss-color-border-button-fill-hovered);
  --_dss-btn-border-radius: var(--dss-border-radius-button-normal);
  --_dss-btn-gap: var(--dss-spacing-button-inline-normal-leadingicon-buttontext-traillingicon);
  --_dss-btn-shadow: none;
  --_dss-btn-active-bg-color: var(--dss-color-background-button-default-pressed);
  --_dss-btn-active-font-color: var(--dss-color-text-button-fill-primary);
  --_dss-btn-active-shadow: var(--dss-box-shadow-button-fill-pressednormal);
  --_dss-btn-bg-disabled: var(--dss-color-background-button-default-disabled);
  --_dss-btn-border-disabled: var(--dss-color-border-button-fill-disabled);
  --_dss-btn-icon-size: var(--dss-sizing-button-icon-normal);
  --_dss-btn-icon-color: var(--dss-color-icon-button-fill-primary);
  --_dss-btn-icon-disabled-color: var(--dss-color-icon-button-fill-disabled);
  --_dss-btn-cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--dss_btn-width);
  gap: var(--_dss-btn-gap);
  background-color: var(--_dss-btn-bg-color);
  color: var(--_dss-btn-font-color) !important;
  text-align: center;

  //typography
  // font-size: var(--_dss-btn-font-size);
  // font-weight: var(--_dss-btn-font-weight);
  // line-height: var(--_dss-btn-line-height);
  // font-family: var(--_dss-btn-font-family);

  letter-spacing: var(--_dss-btn-letter-spacing);
  text-transform: var(--_dss-btn-font-case);
  text-decoration: var(--_dss-btn-text-decoration);
  vertical-align: middle;
  padding: var(--_dss-btn-padding);
  border: var(--_dss-btn-border-width) solid var(--_dss-btn-border-color);
  border-radius: var(--_dss-btn-border-radius);
  box-shadow: var(--_dss-btn-shadow);
  cursor: var(--_dss-btn-cursor);
  user-select: none;
  transition: all 0.3s ease-in-out;

  &:hover,
  &.hovered {
    --_dss-btn-bg-color: var(--_dss-btn-hover-bg-color);
    --_dss-btn-font-color: var(--_dss-btn-hover-font-color);
    --_dss-btn-border-color: var(--_dss-btn-hover-border-color);
    // --_dss-btn-text-decoration: var(--_dss-btn-hover-text-decoration);
    // --_dss-btn-border-width: var(--_dss-btn-hover-border-width);
  }

  &:active,
  &.pressed {
    --_dss-btn-bg-color: var(--_dss-btn-active-bg-color);
    --_dss-btn-font-color: var(--_dss-btn-active-font-color);
    --_dss-btn-border-color: var(--_dss-btn-active-border-color);
    --_dss-btn-shadow: var(--_dss-btn-active-shadow);
  }

  &:disabled,
  &.disabled,
  &[aria-disabled="true"] {
    --_dss-btn-bg-color: var(--_dss-btn-bg-disabled);
    --_dss-btn-font-color: var(--_dss-btn-font-disabled);
    --_dss-btn-border-color: var(--_dss-btn-border-disabled);
    --_dss-btn-cursor: no-drop;
    --_btn-icon-color: var(--dss-color-text-button-default-icon-disabled);
    pointer-events: none;
  }

  &:active,
  &.pressed {
    &.dss-btn-lg {
      --_dss-btn-active-shadow: var(--dss-box-shadow-button-fill-pressedlarge);
    }

    &.dss-btn-md {
      --_dss-btn-active-shadow: var(--dss-box-shadow-button-fill-pressednormal);
    }

    &.dss-btn-sm {
      --_dss-btn-active-shadow: var(--dss-box-shadow-button-fill-presseddense);
    }
  }

  .dss-icon svg * {
    stroke: var(--_dss-btn-icon-color);
  }

  svg {
    * {
      transition: all 0.3s;
    }
  }

  .prefix-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: var(--_dss-btn-icon-size);
    max-width: var(--_dss-btn-icon-size);
    min-height: var(--_dss-btn-icon-size);
    max-height: var(--_dss-btn-icon-size);
  }
  .suffix-icon {
    min-width: var(--_dss-btn-icon-size);
    max-width: var(--_dss-btn-icon-size);
    min-height: var(--_dss-btn-icon-size);
    max-height: var(--_dss-btn-icon-size);
  }

  &.icon-only {
    width: var(--dss-sizing-button-icononly-normal);
    height: var(--dss-sizing-button-icononly-normal);
    border-radius: var(--dss-border-radius-button-icononly-full);
  }

  &-primary {
    --_dss-btn-bg-color: var(--dss-color-background-button-fill-primary);
    --_dss-btn-font-color: var(--dss-color-text-button-fill-primary);
    --_dss-btn-hover-bg-color: var(--dss-color-background-button-fill-hovered);
    --_dss-btn-hover-font-color: var(--dss-color-text-button-fill-primary);
    --_dss-btn-hover-border-color: var(--dss-color-border-button-fill-hovered);
    --_dss-btn-active-bg-color: var(--dss-color-background-button-fill-pressed);
    --_dss-btn-active-border-color: var(--dss-color-border-button-fill-pressed);
    --_dss-btn-active-font-color: var(--dss-color-text-button-fill-primary);
    // --_dss-btn-active-shadow: var(--dss-shadow-fill-pressed-large);
    --_dss-btn-border-radius: var(--dss-border-radius-button-normal);
    --_dss-btn-border-color: var(--dss-color-border-button-fill-primary);
    --_dss-btn-bg-disabled: var(--dss-color-background-button-fill-disabled);
    --_dss-btn-font-disabled: var(--dss-color-text-button-fill-disabled);
    --_dss-btn-border-color-disabled: var(--dss-color-border-button-fill-disabled);
    --_dss-btn-icon-color: var(--dss-color-icon-button-fill-primary);
    --_dss-btn-icon-disabled-color: var(--dss-color-icon-button-fill-disabled);

    &:active {
      &.dss-btn-lg {
        --_dss-btn-active-shadow: var(--dss-box-shadow-button-fill-pressedlarge);
      }

      &.dss-btn-md {
        --_dss-btn-active-shadow: var(--dss-box-shadow-button-fill-pressednormal);
      }

      &.dss-btn-sm {
        --_dss-btn-active-shadow: var(--dss-box-shadow-button-fill-presseddense);
      }
    }
  }

  &-secondary {
    --_dss-btn-font-color: var(--dss-color-text-button-default-primary);
    --_dss-btn-bg-color: var(--dss-color-background-button-default-primary);
    --_dss-btn-hover-font-color: var(--dss-color-text-button-default-primary);
    --_dss-btn-hover-bg-color: var(--dss-color-background-button-default-hovered);
    --_dss-btn-hover-border-color: var(--dss-color-border-button-default-hovered);
    --_dss-btn-active-bg-color: var(--dss-color-background-button-default-pressed);
    --_dss-btn-active-border-color: var(--dss-color-border-button-default-pressed);
    // --_dss-btn-active-shadow: var(--dss-shadow-fill-pressed-large);
    --_dss-btn-bg-disabled: var(--dss-color-background-button-default-disabled);
    --_dss-btn-border-disabled: var(--dss-color-border-button-default-disabled);
    --_dss-btn-font-disabled: var(--dss-color-text-button-default-disabled);
    // --_dss-btn-border-radius: var(--dss-border-radius-200);
    --_dss-btn-active-font-color: var(--dss-color-text-button-default-primary);
    --_dss-btn-icon-color: var(--dss-color-icon-button-default-primary);
    --_dss-btn-icon-disabled-color: var(--dss-color-icon-button-default-disabled);
    // &:hover,
    // &.hovered {
    // --_dss-btn-border-color: var(--dss-color-border-button-fill-hovered);
    //   // --_dss-btn-hover-bg-color: var(--dss-color-background-button-default-hovered)
    // }

    &:active,
    &.pressed {
      &.dss-btn-lg {
        --_dss-btn-active-shadow: var(--dss-box-shadow-button-default-pressedlarge);
      }

      &.dss-btn-md {
        --_dss-btn-active-shadow: var(--dss-box-shadow-button-default-pressednormal);
      }

      &.dss-btn-sm {
        --_dss-btn-active-shadow: var(--dss-box-shadow-button-default-presseddense);
      }
    }
  }

  &-outlined {
    --_dss-btn-bg-color: var(--dss-color-background-empty);
    // --_dss-btn-border-width: var(--dss-border-width-button-100);
    --_dss-btn-border-color: var(--dss-color-border-button-stroke-primary);
    --_dss-btn-font-color: var(--dss-color-text-button-stroke-primary);
    --_dss-btn-hover-bg-color: var(--dss-color-background-button-stroke-hovered);
    // --_dss-btn-hover-border-width: var(--dss-border-width-button-100);
    --_dss-btn-hover-border-color: var(--dss-color-border-button-stroke-hovered);
    --_dss-btn-hover-font-color: var(--dss-color-text-button-stroke-primary);
    --_dss-btn-active-font-color: var(--dss-color-text-button-stroke-primary);
    // --_dss-btn-active-shadow: var(--dss-shadow-stroke-pressed-large);
    --_dss-btn-active-bg-color: var(--dss-color-background-button-stroke-pressed);
    --_dss-btn-bg-disabled: var(--dss-color-background-empty);
    --_dss-btn-border-disabled: var(--dss-color-border-button-stroke-disabled);
    --_dss-btn-font-disabled: var(--dss-color-text-button-stroke-disabled);
    // --_dss-btn-border-radius: var(--dss-border-radius-200);
    --_dss-btn-icon-color: var(--dss-color-icon-button-outline-primary);
    --_dss-btn-icon-disabled-color: var(--dss-color-icon-button-outline-disabled);
    // &:hover,
    // &.hovered {
    //   --_dss-btn-bg-color: var(--dss-color-background-button-stroke-hovered);
    //   --_dss-btn-border-color: var(--dss-color-border-button-stroke-hovered);
    // }

    &:active,
    &.pressed {
      --_dss-btn-bg-color: var(--dss-color-background-button-stroke-pressed);
      --_dss-btn-border-color: var(--dss-color-border-button-stroke-pressed);

      &.dss-btn-lg {
        --_dss-btn-active-shadow: var(--dss-box-shadow-button-stroke-pressedlarge);
      }

      &.dss-btn-md {
        --_dss-btn-active-shadow: var(--dss-box-shadow-button-stroke-pressednormal);
      }

      &.dss-btn-sm {
        --_dss-btn-active-shadow: var(--dss-box-shadow-button-stroke-presseddense);
      }
    }

    &:disabled,
    &.disabled,
    &[aria-disabled="true"] {
      --_dss-btn-font-color: var(--dss-color-text-button-stroke-disabled);
      --_dss-btn-border-color: var(--dss-color-border-button-stroke-disabled);
      pointer-events: none;
      --_btn-cursor: no-drop;
    }
  }

  &-ghost {
    // --_dss-btn-bg-color: var(--dss-color-background-empty);
    --_dss-btn-bg-color: var(--dss-color-background-button-ghost-default);
    --_dss-btn-font-color: var(--dss-color-text-button-ghost-primary);
    --_dss-btn-hover-font-color: var(--dss-color-text-button-ghost-primary);
    --_dss-btn-hover-bg-color: var(--dss-color-background-button-ghost-hovered);
    --_dss-btn-hover-border-color: var(--dss-color-border-button-ghost-hovered);
    --_dss-btn-active-bg-color: var(--dss-color-background-button-ghost-pressed);
    // --_dss-btn-active-shadow: var(--dss-shadow-ghost-pressed-large);
    --_dss-btn-active-font-color: var(--dss-color-text-button-ghost-primary);
    --_dss-btn-bg-disabled: var(--dss-color-background-empty);
    --_dss-btn-font-disabled: var(--dss-color-text-button-ghost-disabled);
    --_dss-btn-icon-color: var(--dss-color-icon-button-ghost-primary);
    --_dss-btn-icon-disabled-color: var(--dss-color-icon-button-ghost-disabled);
    // &:hover,
    // &.hovered {
    //   --_dss-btn-border-color: var(--dss-color-border-button-ghost-hovered);
    // }

    &:active,
    &.pressed {
      --_dss-btn-border-color: var(--dss-color-border-button-ghost-pressed);
      &.dss-btn-lg {
        --_dss-btn-active-shadow: var(--dss-box-shadow-button-ghost-pressedlarge);
      }

      &.dss-btn-md {
        --_dss-btn-active-shadow: var(--dss-box-shadow-button-ghost-pressednormal);
      }

      &.dss-btn-sm {
        --_dss-btn-active-shadow: var(--dss-box-shadow-button-ghost-presseddense);
      }
    }

    &.disabled {
      --_dss-btn-border-color: transparent;
    }
  }

  &-fluid {
    --dss_btn-width: 100%;
    --_dss-btn-border-radius: var(--dss-border-radius-button-large);
  }

  &-danger {
    --_dss-btn-bg-color: var(--dss-color-background-button-danger-primary);
    --_dss-btn-font-color: var(--dss-color-text-button-danger-primary);
    --_dss-btn-hover-bg-color: var(--dss-color-background-button-danger-hovered);
    --_dss-btn-hover-font-color: var(--dss-color-text-button-danger-primary);
    --_dss-btn-hover-border-color: var(--dss-color-border-button-danger-hovered);
    --_dss-btn-active-bg-color: var(--dss-color-background-button-danger-pressed);
    // --_dss-btn-active-shadow: var(--dss-shadow-danger-pressed-large);
    --_dss-btn-active-font-color: var(--dss-color-text-button-danger-primary);
    --_dss-btn-bg-disabled: var(--dss-color-background-button-danger-disabled);
    --_dss-btn-font-disabled: var(--dss-color-text-button-danger-disabled);
    --_dss-btn-border-color:var(--dss-color-border-button-danger-primary);
    --_dss-btn-icon-color: var(--dss-color-icon-button-danger-primary);
    --_dss-btn-icon-disabled-color: var(--dss-color-icon-button-danger-disabled);
    // --_dss-btn-border-radius: var(--dss-border-radius-200);
    // &:hover,
    // &.hovered {
    //   --_dss-btn-border-color: var(--dss-color-border-button-danger-hovered);
    // }


    &:active,
    &.pressed {
      --_dss-btn-border-color: var(--dss-color-border-button-danger-pressed);
      &.dss-btn-lg {
        --_dss-btn-active-shadow: var(--dss-box-shadow-button-danger-pressedlarge);
      }

      &.dss-btn-md {
        --_dss-btn-active-shadow: var(--dss-box-shadow-button-danger-pressednormal);
      }

      &.dss-btn-sm {
        --_dss-btn-active-shadow: var(--dss-box-shadow-button-danger-presseddense);
      }
    }
  }

  &-link {
    --_dss-btn-padding: var(--dss-spacing-0) !important;
    --_dss-btn-bg-color: var(--dss-color-background-empty);
    --_dss-btn-font-color: var(--dss-color-text-button-link-primary);

    // --_dss-btn-font-family: var(--dss-font-button-link-uitext-normal-font-family);
    // --_dss-btn-font-weight: var(--dss-font-button-link-uitext-normal-font-weight);
    // --_dss-btn-font-size: var(--dss-font-button-link-uitext-normal-font-size);
    // --_dss-btn-line-height: var(--dss-font-button-link-uitext-normal-line-height);

    --_dss-btn-hover-bg-color: var(--dss-color-background-empty);
    --_dss-btn-hover-font-color: var(--dss-color-text-button-link-hovered);
    --_dss-btn-hover-text-decoration: var(--dss-text-decoration-underline);
    --_dss-btn-active-bg-color: var(--dss-color-background-empty);
    --_dss-btn-active-font-color: var(--dss-color-text-button-link-pressed);
    --_dss-btn-active-shadow: none;
    --_dss-btn-shadow: none;
    --_dss-btn-border-width: 0;
    --_dss-btn-bg-disabled: var(--dss-color-background-empty);
    --_dss-btn-font-disabled: var(--dss-color-text-button-link-disabled);
    --_dss-btn-text-decoration: var(--_dss-btn-hover-text-decoration);

    font: var(--dss-font-button-link-uitext-normal);

    &.dss-btn-sm,
    &.dss-btn-lg,
    &.dss-btn-xl {
      --_dss-btn-padding: var(--dss-spacing-0);
    }

    &.dss-btn-sm {
      // --_dss-btn-font-family: var(--dss-font-button-link-uitext-dense-font-family);
      // --_dss-btn-font-weight: var(--dss-font-button-link-uitext-dense-font-weight);
      // --_dss-btn-font-size: var(--dss-font-button-link-uitext-dense-font-size);
      // --_dss-btn-line-height: var(--dss-font-button-link-uitext-dense-line-height);
      font: var(--dss-font-button-link-uitext-dense);
    }

    &.dss-btn-lg {
      // --_dss-btn-font-family: var(--dss-font-button-link-uitext-large-font-family);
      // --_dss-btn-font-weight: var(--dss-font-button-link-uitext-large-font-weight);
      // --_dss-btn-font-size: var(--dss-font-button-link-uitext-large-font-size);
      // --_dss-btn-line-height: var(--dss-font-button-link-uitext-large-line-height);
      font: var(--dss-font-button-link-uitext-large)
    }

    &:active,
    &.pressed {
      --_dss-btn-shadow: none;
      &.dss-btn-lg {
        --_dss-btn-active-shadow: none;
        
        --_dss-btn-font-color: var(--dss-color-text-button-link-pressed);
      }

      &.dss-btn-md {
        --_dss-btn-active-shadow: none;
        // --_dss-btn-shadow: none;
        --_dss-btn-font-color: var(--dss-color-text-button-link-pressed);
      }

      &.dss-btn-sm {
        --_dss-btn-active-shadow: none;
        // --_dss-btn-shadow: none;
        --_dss-btn-font-color: var(--dss-color-text-button-link-pressed);
      }
    }
  }

  &-icon {
    &.disabled {
      // --_dss-btn-bg-color: var(--dss-color-background-button-fill-disabled);
      // --_dss-btn-border-width: var(--dss-border-width-button-100);
      // // --_dss-btn-border-color: var(--dss-color-background-button-strock-disabled);
      // --_dss-btn-border-color: var(--dss-color-border-button-fill-disabled);

      .dss-icon svg * {
        stroke: var(--_dss-btn-icon-disabled-color);
      }
    }
  }

  // &-icon-secondary {
  //   // --_dss-btn-padding: var(--dss-sizing-300);
  //   // --_dss-btn-bg-color: var(--dss-color-background-button-default-primary);
  //   // --_dss-btn-hover-bg-color: var(--dss-color-background-button-default-hovered);
  //   // --_dss-btn-active-bg-color: var(--dss-color-background-button-default-pressed);
  //   // --_dss-btn-active-shadow: var(--dss-shadow-default-pressed-normal);
  //   // --_dss-btn-bg-disabled: var(--dss-color-background-button-default-disabled);

  //   --_dss-btn-bg-color: var(--dss-color-background-button-default-primary);
  //   --_dss-btn-text-color: var(--dss-color-text-button-default-primary);
  //   --_dss-btn-text-color-hovered: var(--dss-color-text-button-default-primary);
  //   --_dss-btn-text-color-pressed: var(--dss-color-text-button-default-primary);
  //   // --_dss-btn-border-radius: var(--dss-border-radius-200);
  //   --_dss-btn-border-color: transparent;
  //   --_dss-btn-bg-color-hovered: var(--dss-color-background-button-default-hovered);
  //   --_dss-btn-bg-disabled: var(--dss-color-background-button-default-disabled);
  //   --_dss-btn-text-disabled-color: var(--dss-color-text-button-default-disabled);
  //   // --_dss-btn-text-font-weight: var(--dss-font-weight-medium);
  //   // --_dss-btn-text-line-height: var(--dss-line-height-120);
  //   --_dss-btn-shadow-pressed: var(--dss-box-shadow-button-default-pressednormal);
  //   --_dss-btn-icon-color: var(--dss-color-text-button-default-primary);
  //   // --_dss-btn-icon-color-disabled: var(--dss-color-text-button-default-disabled);
  // }

  // &-icon-primary {
  //   --_dss-btn-padding: var(--dss-sizing-300);
  //   --_dss-btn-bg-color: var(--dss-color-background-button-fill-primary);
  //   --_dss-btn-hover-bg-color: var(--dss-color-background-button-fill-hovered);
  //   --_dss-btn-active-bg-color: var(--dss-color-background-button-fill-pressed);
  //   --_dss-btn-active-shadow: var(--dss-box-shadow-button-fill-pressednormal);
  //   --_dss-btn-bg-disabled: var(--dss-color-background-button-fill-disabled);
  // }

  // &-icon-outlined {
  //   --_dss-btn-padding: var(--dss-sizing-300);
  //   --_dss-btn-border-width: var(--dss-border-width-button-100);
  //   --_dss-btn-bg-color: var(--dss-color-background-empty);
  //   --_dss-btn-border-color: var(--dss-color-background-button-stroke-primary);
  //   --_dss-btn-hover-bg-color: var(--dss-color-background-button-stroke-hovered);
  //   --_dss-btn-hover-border-width: var(--dss-border-width-button-100);
  //   --_dss-btn-hover-border-color: var(--dss-color-background-button-stroke-primary);
  //   --_dss-btn-active-bg-color: var(--dss-color-background-button-stroke-pressed);
  //   --_dss-btn-active-shadow: var(--dss-box-shadow-button-default-pressednormal);
  //   --_dss-btn-active-border-color: var(--dss-color-background-button-stroke-primary);
  //   --_dss-btn-bg-disabled: var(--dss-color-background-empty);
  //   --_dss-btn-border-disabled: var(--dss-color-background-button-stroke-disabled);
  // }

  // &-icon-ghost {
  //   --_dss-btn-padding: var(--dss-sizing-300);
  //   --_dss-btn-bg-color: var(--dss-color-background-empty);
  //   --_dss-btn-hover-bg-color: var(--dss-color-background-button-ghost-hovered);
  //   --_dss-btn-active-bg-color: var(--dss-color-background-button-ghost-pressed);
  //   --_dss-btn-active-shadow: var(--dss-box-shadow-button-ghost-pressed-normal);
  //   --_dss-btn-bg-disabled: var(--dss-color-background-empty);
  // }

  // &-icon-danger {
  //   --_dss-btn-padding: var(--dss-sizing-300);
  //   --_dss-btn-bg-color: var(--dss-color-background-button-danger-primary);
  //   --_dss-btn-hover-bg-color: var(--dss-color-background-button-danger-hovered);
  //   --_dss-btn-active-bg-color: var(--dss-color-background-button-danger-pressed);
  //   --_dss-btn-active-shadow: var(--dss-box-shadow-button-danger-pressed-normal);
  //   --_dss-btn-bg-disabled: var(--dss-color-background-button-danger-disabled);
  // }

  // &-xs {
  //   --_dss-btn-padding: var(--dss-spacing-button-inset-dense);
  //   --_dss-btn-gap: var(--dss-spacing-100);
  //   --_dss-btn-border-radius: var(--dss-border-radius-button-dense);
  //   // --_dss-btn-font-size: var(--dss-font-size-100);
  //   --_dss-btn-font-disabled: var(--dss-color-text-button-fill-primary);
  // }

  &-sm {
    --_dss-btn-padding: var(--dss-spacing-button-inset-dense);
    --_dss-btn-gap:  var(--dss-spacing-button-inline-dense-leadingicon-buttontext-traillingicon);
    --_dss-btn-border-radius: var(--dss-border-radius-button-dense);
    // --_dss-btn-font-size: var(--dss-font-size-200);
    // --_dss-btn-font-disabled: var(--dss-color-text-button-fill-primary);

    //typography
    //typography
    // --_dss-btn-font-family: var(--dss-font-button-uitext-dense-font-family);
    // --_dss-btn-font-weight: var(--dss-font-button-uitext-dense-font-weight);
    // --_dss-btn-font-size: var(--dss-font-button-uitext-dense-font-size);
    // --_dss-btn-line-height: var(--dss-font-button-uitext-dense-line-height);
    font: var(--dss-font-button-uitext-dense);
    .prefix-icon {
      --_dss-btn-icon-size: var(--dss-sizing-button-icon-dense);
    }
    .suffix-icon {
      --_dss-btn-icon-size: var(--dss-sizing-button-icon-dense);
    }
    &.icon-only {
      width: var(--dss-sizing-button-icononly-dense);
      height: var(--dss-sizing-button-icononly-dense);
      border-radius: var(--dss-border-radius-button-icononly-full);
    }
  }

  &-md {
    // --_dss-btn-font-family: var(--dss-font-button-uitext-normal-font-family);
    // --_dss-btn-font-weight: var(--dss-font-button-uitext-normal-font-weight);
    // --_dss-btn-font-size: var(--dss-font-button-uitext-normal-font-size);
    // --_dss-btn-line-height: var(--dss-font-button-uitext-normal-line-height);
    font: var(--dss-font-button-uitext-normal);
    .prefix-icon {
      --_dss-btn-icon-size: var(--dss-sizing-button-icon-normal);
    }
    .suffix-icon {
      --_dss-btn-icon-size: var(--dss-sizing-button-icon-normal);
    }
  }

  &-lg {
    --_dss-btn-padding: var(--dss-spacing-button-inset-large);
    --_dss-btn-gap: var(--dss-spacing-button-inline-large-leadingicon-buttontext-traillingicon);
    --_dss-btn-border-radius: var(--dss-border-radius-button-large);
    // --_dss-btn-font-size: var(--dss-font-size-400);
    // --_dss-btn-font-disabled: var(--dss-color-text-button-fill-disabled);

    //typography
    // --_dss-btn-font-family: var(--dss-font-button-uitext-large-font-family);
    // --_dss-btn-font-weight: var(--dss-font-button-uitext-large-font-weight);
    // --_dss-btn-font-size: var(--dss-font-button-uitext-large-font-size);
    // --_dss-btn-line-height: var(--dss-font-button-uitext-large-line-height);
    font: var(--dss-font-button-uitext-large);
    .prefix-icon {
      --_dss-btn-icon-size: var(--dss-sizing-button-icon-large);
    }
    .suffix-icon {
      --_dss-btn-icon-size: var(--dss-sizing-button-icon-large);
    }
    &.icon-only {
      width: var(--dss-sizing-button-icononly-large);
      height: var(--dss-sizing-button-icononly-large);
      border-radius: var(--dss-border-radius-button-icononly-full);
    }
  }

  // &-xl {
  //   --_dss-btn-padding: var(--dss-spacing-button-extra-large);
  //   --_dss-btn-gap: var(--dss-spacing-200);
  //   // --_dss-btn-border-radius: var(--dss-border-radius-150);
  //   // --_dss-btn-font-size: var(--dss-font-size-500);
  //   --_dss-btn-font-disabled: var(--dss-color-text-button-fill-primary);
  // }

  &.dss-btn-fluid {
    width: 100%;
  }
}

.plt-btn {
  --plt_btn-width: auto;

  --_plt-btn-bg-color: var(--plt-background-button-default-primary);
  --_plt-btn-font-color: var(--plt-text-button-default-primary);
  --_plt-btn-font-weight: var(--plt-font-weight-medium);
  --_plt-btn-font-family: var(--plt-font-family-secondary);
  --_plt-btn-font-size: var(--plt-font-size-300);
  --_plt-btn-line-height: var(--plt-line-height-120);
  --_plt-btn-letter-spacing: var(--plt-font-letter-spacing-normal);
  --_plt-btn-font-case: var(--plt-text-case-none);
  --_plt-btn-text-decoration: var(--plt-text-decoration-none);
  --_plt-btn-padding: var(--plt-space-button-normal);
  --_plt-btn-border-width: var(--plt-border-width-0);
  --_plt-btn-hover-bg-color: var(--plt-background-button-default-hovered);
  --_plt-btn-border-color: transparent;
  --_plt-btn-border-radius: var(--plt-border-radius-150);
  --_plt-btn-gap: var(--plt-space-100);
  --_plt-btn-shadow: none;
  --_plt-btn-active-shadow: var(--plt-shadow-default-pressed-normal);
  --_plt-btn-bg-disabled: var(--plt-background-button-fill-disabled);
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--dss_btn-width);
  gap: var(--_plt-btn-gap);
  background-color: var(--_plt-btn-bg-color);
  color: var(--_plt-btn-font-color) !important;
  text-align: center;
  font-size: var(--_plt-btn-font-size);
  font-weight: var(--_plt-btn-font-weight);
  font-family: var(--_plt-btn-font-family);
  line-height: var(--_plt-btn-line-height);
  letter-spacing: var(--_plt-btn-letter-spacing);
  text-transform: var(--_plt-btn-font-case);
  text-decoration: var(--_plt-btn-text-decoration);
  vertical-align: middle;
  padding: var(--_plt-btn-padding);
  border: var(--_plt-btn-border-width) solid var(--_plt-btn-border-color);
  border-radius: var(--_plt-btn-border-radius);
  box-shadow: var(--_plt-btn-shadow);
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease-in-out;

  &:active {
    --_plt-btn-bg-color: var(--_plt-btn-active-bg-color);
    --_plt-btn-font-color: var(--_plt-btn-active-font-color);
    --_plt-btn-border-color: var(--_plt-btn-active-border-color);
    --_plt-btn-shadow: var(--_plt-btn-active-shadow);
  }

  &:hover {
    --_plt-btn-bg-color: var(--_plt-btn-hover-bg-color);
    --_plt-btn-font-color: var(--_plt-btn-hover-font-color);
    --_plt-btn-border-color: var(--_plt-btn-hover-border-color);
    --_plt-btn-text-decoration: var(--_plt-btn-hover-text-decoration);
    --_plt-btn-border-width: var(--_plt-btn-hover-border-width);
  }

  &:disabled,
  &.disabled,
  &[aria-disabled="true"] {
    --_plt-btn-bg-color: var(--_plt-btn-bg-disabled);
    --_plt-btn-font-color: var(--_plt-btn-font-disabled);
    // --_plt-btn-border-color: var(--_plt-btn-border-disabled);
    --_plt-btn-border-color: var(--_plt-background-button-strock-disabled);
    // --_plt-btn-bg-color: var(--_plt-background-button-fill-disabled);
    --_btn-icon-color: var(--_plt-btn-icon-color-disabled);
    --_btn-cursor: no-drop;
    pointer-events: none;
  }

  .plt-icon svg * {
    stroke: var(--_plt-btn-hover-font-color);
  }

  svg {
    * {
      transition: all 0.3s;
    }
  }

  &-primary {
    --_plt-btn-bg-color: var(--plt-background-button-fill-primary);
    --_plt-btn-font-color: var(--plt-text-button-fill-primary);
    --_plt-btn-hover-bg-color: var(--plt-background-button-fill-hovered);
    --_plt-btn-hover-font-color: var(--plt-text-button-fill-primary);
    --_plt-btn-active-bg-color: var(--plt-background-button-fill-pressed);
    --_plt-btn-active-font-color: var(--plt-text-button-fill-primary);
    --_plt-btn-active-shadow: var(--plt-shadow-fill-pressed-normal);
    --_plt-btn-font-disabled: var(--plt-text-button-fill-primary);
    --_plt-btn-bg-disabled: var(--plt-background-button-fill-disabled);
  }

  &-secondary {
    --_plt-btn-font-color: var(--plt-text-button-default-primary);
    --_plt-btn-bg-color: var(--plt-background-button-default-primary);
    --_plt-btn-hover-font-color: var(--plt-text-button-default-primary);
    --_plt-btn-hover-bg-color: var(--plt-background-button-default-hovered);
    --_plt-btn-active-bg-color: var(--plt-background-button-default-pressed);
    --_plt-btn-active-shadow: var(--plt-shadow-default-pressed-normal);
    --_plt-btn-bg-disabled: var(--plt-background-button-default-disabled);
    --_plt-btn-font-disabled: var(--plt-text-button-default-disabled);
  }

  &-outlined {
    --_plt-btn-bg-color: var(--plt-background-button-ghost-primary);
    --_plt-btn-border-width: var(--plt-border-width-100);
    --_plt-btn-border-color: var(--plt-background-button-stroke-primary);
    --_plt-btn-font-color: var(--plt-text-button-stroke-primary);
    --_plt-btn-hover-bg-color: var(--plt-background-button-stroke-hovered);
    --_plt-btn-hover-border-width: var(--plt-border-width-100);
    --_plt-btn-hover-border-color: var(--plt-background-button-stroke-primary);
    --_plt-btn-hover-font-color: var(--plt-text-button-stroke-primary);
    --_plt-btn-active-font-color: var(--plt-text-button-stroke-primary);
    --_plt-btn-active-shadow: var(--plt-shadow-default-pressed-normal);
    --_plt-btn-active-bg-color: var(--plt-background-button-stroke-pressed);
    --_plt-btn-bg-disabled: var(--plt-background-button-ghost-primary);
    --_plt-btn-border-disabled: var(--plt-background-button-stroke-disabled);
    --_plt-btn-font-disabled: var(--plt-text-button-stroke-disabled);
  }

  &-ghost {
    // --_plt-btn-bg-color: var(--plt-background-button-ghost-primary);
    --_plt-btn-bg-color: var(--plt-background-button-ghost-primary);
    --_plt-btn-font-color: var(--plt-text-button-ghost-primary);
    --_plt-btn-hover-font-color: var(--plt-text-button-ghost-primary);
    --_plt-btn-hover-bg-color: var(--plt-background-button-ghost-hovered);
    --_plt-btn-active-bg-color: var(--plt-background-button-ghost-pressed);
    --_plt-btn-active-shadow: var(--plt-shadow-ghost-pressed-normal);
    --_plt-btn-active-font-color: var(--plt-text-button-ghost-primary);
    --_plt-btn-bg-disabled: var(--plt-background-button-ghost-primary);
    --_plt-btn-font-disabled: var(--plt-text-button-ghost-disabled);
  }

  &-fluid {
    --dss_btn-width: 100%;
  }

  &-danger {
    --_plt-btn-bg-color: var(--plt-background-button-danger-primary);
    --_plt-btn-font-color: var(--plt-text-button-danger-primary);
    --_plt-btn-hover-bg-color: var(--plt-background-button-danger-hovered);
    --_plt-btn-hover-font-color: var(--plt-text-button-danger-primary);
    --_plt-btn-active-bg-color: var(--plt-background-button-danger-pressed);
    --_plt-btn-active-shadow: var(--plt-shadow-danger-pressed-normal);
    --_plt-btn-active-font-color: var(--plt-text-button-danger-primary);
    --_plt-btn-bg-disabled: var(--plt-background-button-danger-disabled);
    --_plt-btn-font-disabled: var(--plt-text-button-danger-primary);
  }

  &-link {
    --_plt-btn-padding: var(--plt-space-0);
    --_plt-btn-bg-color: var(--plt-background-button-ghost-primary);
    --_plt-btn-font-color: var(--plt-text-button-link-primary);
    --_plt-btn-hover-bg-color: var(---plt-background-button-ghost-primary);
    --_plt-btn-hover-font-color: var(--plt-text-button-link-hovered);
    --_plt-btn-hover-text-decoration: var(--plt-text-decoration-underline);
    --_plt-btn-active-bg-color: var(---plt-background-button-ghost-primary);
    --_plt-btn-active-font-color: var(--plt-text-button-link-pressed);
    --_plt-btn-active-shadow: none;
    --_plt-btn-bg-disabled: var(--plt-background-button-ghost-primary);
    --_plt-btn-font-disabled: var(--plt-text-button-link-disabled);
    --_plt-btn-text-decoration: var(--_plt-btn-hover-text-decoration);

    &.plt-btn-sm,
    &.plt-btn-lg,
    &.plt-btn-xl {
      --_plt-btn-padding: var(--plt-space-0);
    }
  }

  &-icon {
    &.disabled {
      --_plt-btn-bg-color: transparent;
      --_plt-btn-border-width: var(--plt-border-width-100);
      // --_plt-btn-border-color: var(--plt-background-button-strock-disabled);
      --_plt-btn-border-color: var(--_plt-btn-font-disabled);

      .plt-icon svg * {
        stroke: var(--_plt-btn-font-disabled);
      }
    }
  }

  &-icon-secondary {
    // --_plt-btn-padding: var(--plt-size-300);
    // --_plt-btn-bg-color: var(--plt-background-button-default-primary);
    // --_plt-btn-hover-bg-color: var(--plt-background-button-default-hovered);
    // --_plt-btn-active-bg-color: var(--plt-background-button-default-pressed);
    // --_plt-btn-active-shadow: var(--plt-shadow-default-pressed-normal);
    // --_plt-btn-bg-disabled: var(--plt-background-button-default-disabled);

    --_plt-btn-bg-color: var(--plt-background-button-default-primary);
    --_plt-btn-text-color: var(--plt-text-button-default-primary);
    --_plt-btn-text-color-hovered: var(--plt-text-button-default-primary);
    --_plt-btn-text-color-pressed: var(--plt-text-button-default-primary);
    --_plt-btn-border-radius: var(--plt-border-radius-200);
    --_plt-btn-border-color: transparent;
    --_plt-btn-bg-color-hovered: var(--plt-background-button-default-hovered);
    --_plt-btn-bg-disabled: var(--plt-background-button-default-disabled);
    --_plt-btn-text-disabled-color: var(--plt-text-button-default-disabled);
    --_plt-btn-text-font-weight: var(--plt-font-weight-medium);
    --_plt-btn-text-line-height: var(--plt-line-height-120);
    --_plt-btn-shadow-pressed: var(--plt-shadow-default-pressed-normal);
    --_plt-btn-icon-color: var(--plt-text-button-default-primary);
    --_plt-btn-icon-color-disabled: var(--plt-text-button-default-disabled);
  }

  &-icon-primary {
    --_plt-btn-padding: var(--plt-size-300);
    --_plt-btn-bg-color: var(--plt-background-button-fill-primary);
    --_plt-btn-hover-bg-color: var(--plt-background-button-fill-hovered);
    --_plt-btn-active-bg-color: var(--plt-background-button-fill-pressed);
    --_plt-btn-active-shadow: var(--plt-shadow-fill-pressed-normal);
    --_plt-btn-bg-disabled: var(--plt-background-button-fill-disabled);
  }

  &-icon-outlined {
    --_plt-btn-padding: var(--plt-size-300);
    --_plt-btn-border-width: var(--plt-border-width-100);
    --_plt-btn-bg-color: var(--plt-background-button-ghost-primary);
    --_plt-btn-border-color: var(--plt-background-button-stroke-primary);
    --_plt-btn-hover-bg-color: var(--plt-background-button-stroke-hovered);
    --_plt-btn-hover-border-width: var(--plt-border-width-100);
    --_plt-btn-hover-border-color: var(--plt-background-button-stroke-primary);
    --_plt-btn-active-bg-color: var(--plt-background-button-stroke-pressed);
    --_plt-btn-active-shadow: var(--plt-shadow-default-pressed-normal);
    --_plt-btn-active-border-color: var(--plt-background-button-stroke-primary);
    --_plt-btn-bg-disabled: var(--plt-background-button-ghost-primary);
    --_plt-btn-border-disabled: var(--plt-background-button-stroke-disabled);
  }

  &-icon-ghost {
    --_plt-btn-padding: var(--plt-size-300);
    --_plt-btn-bg-color: var(--plt-background-button-ghost-primary);
    --_plt-btn-hover-bg-color: var(--plt-background-button-ghost-hovered);
    --_plt-btn-active-bg-color: var(--plt-background-button-ghost-pressed);
    --_plt-btn-active-shadow: var(--plt-shadow-ghost-pressed-normal);
    --_plt-btn-bg-disabled: var(--plt-background-button-ghost-primary);
  }

  &-icon-danger {
    --_plt-btn-padding: var(--plt-size-300);
    --_plt-btn-bg-color: var(--plt-background-button-danger-primary);
    --_plt-btn-hover-bg-color: var(--plt-background-button-danger-hovered);
    --_plt-btn-active-bg-color: var(--plt-background-button-danger-pressed);
    --_plt-btn-active-shadow: var(--plt-shadow-danger-pressed-normal);
    --_plt-btn-bg-disabled: var(--plt-background-button-danger-disabled);
  }

  &-xs {
    --_plt-btn-padding: var(--plt-space-button-denser);
    --_plt-btn-gap: var(--plt-space-100);
    --_plt-btn-border-radius: var(--plt-border-radius-100);
    --_plt-btn-font-size: var(--plt-font-size-100);
    --_plt-btn-font-disabled: var(--plt-text-button-fill-primary);
  }

  &-sm {
    --_plt-btn-padding: var(--plt-space-button-dense);
    --_plt-btn-gap: var(--plt-space-100);
    --_plt-btn-border-radius: var(--plt-border-radius-150);
    --_plt-btn-font-size: var(--plt-font-size-200);
    --_plt-btn-font-disabled: var(--plt-text-button-fill-primary);
  }

  &-lg {
    --_plt-btn-padding: var(--plt-space-button-large);
    --_plt-btn-gap: var(--plt-space-200);
    --_plt-btn-border-radius: var(--plt-border-radius-200);
    --_plt-btn-font-size: var(--plt-font-size-400);
    --_plt-btn-font-disabled: var(--plt-text-button-fill-primary);
  }

  &-xl {
    --_plt-btn-padding: var(--plt-space-button-extra-large);
    --_plt-btn-gap: var(--plt-space-200);
    --_plt-btn-border-radius: var(--plt-border-radius-200);
    --_plt-btn-font-size: var(--plt-font-size-500);
    --_plt-btn-font-disabled: var(--plt-text-button-fill-primary);
  }

  &.plt-btn-fluid {
    width: 100%;
  }
}
