
.dss-scale-table-container {
  overflow: auto;
}

/* adding space to all the cells */
.dss-scale-table th {
  padding: var(--dss-spacing-table-headercell-inset-normal);
  color: var(--dss-color-text-table-secondary);
  // font-size: var(--plt-font-size-300);
  // font-weight: var(--plt-font-weight-regular);
  // line-height: var(--plt-line-height-120);
  font: var(--dss-font-table-header-cell-normal);
  background-color: var(--dss-color-background-table-headercell-default);
  &:hover{
    background-color: var(--dss-color-background-table-headercell-hovered);
  }
}

.dss-scale-table td {
  padding: var(--dss-spacing-table-bodycell-inset-normal);
  // font-size: var(--plt-font-size-200);
  // font-weight: var(--plt-font-weight-regular);
  color: var(--dss-color-text-table-primary);
  // font: var(--dss-font-table-body-cell-lead-text-normal);
  font: var(--dss-font-table-body-cell-sub-text-normal);
  // line-height: var(--plt-line-height-120);
  background-color: var(--dss-color-background-table-bodycell-default);
  &:hover{
    background-color: var(--dss-color-background-table-bodycell-hovered);
  }
}
// .plt-heading-usage, .plt-heading-info {
//  font-size: var(--plt-font-size-200);
//     font-weight: var(--plt-font-weight-regular);
//     color: var(--plt-text-secondary);
//     line-height: var(--plt-line-height-120);
//   }
/*end of adding space to all the cells */

/* basic table styles */
.dss-scale-table {
  width: 100%;
}
.dss-scale-table,
.dss-scale-table td,
.dss-scale-table tr,
.dss-scale-table th {
  border-collapse: collapse;
  border: var(--dss-border-width-table-normal) solid var(--dss-color-border-table-default-stroke);
  border-radius: var(--dss-border-radius-table-normal);
  text-align: left;
  vertical-align: top;
}

// .dss-scale-table th:last-child {
//   min-width: 240px;
// }

// .dss-scale-table td{
//   width: 248px;
// }