.avatar {
  --_dss-avatar-size: var(--dss-sizing-avatar-default-normal);
  --_dss-avatar-font-color: var(--dss-color-text-avatar-textonly-primary);
  // --_dss-avatar-font-size: var(--dss-font-avatar-text-normal-font-size);
  // --_dss-avatar-font-weight: var(--dss-font-weight-semi-bold);
  // --_dss-avatar-line-height: var(--dss-line-height-120);
  --_dss-avatar-bg-color: var(--dss-color-background-avatar-silhouette-default);
  --_dss-avatar-border-width: var(--dss-border-width-avatar-default-normal);
  // --_dss-avatar-border-color:var(--dss-color-border-avatar-default-normal);
  --_dss-avatar-border-radius: var(--dss-border-radius-avatar-default-full);
  --_dss-avatar-status-size: var(--dss-sizing-avatar-default-statusicon-large);
  --_dss-avatar-active-size: var(--dss-sizing-avatar-silhouette-icon-normal);
  --_dss-avatar-user-size: 100%;
  // --_dss-avatar-font-size: var(--dss-font-avatar-textonly-normal);
  box-sizing: border-box;
  color: var(--_dss-avatar-font-color);
  text-align: center;
  // font-size: var(--_dss-avatar-font-size);
  // font-weight: var(--_dss-avatar-font-weight);
  // line-height: var(--_dss-avatar-line-height);
  // font: var(--_dss-avatar-font-size);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--_dss-avatar-bg-color);
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
  position: relative;
  width: var(--_dss-avatar-size);
  height: var(--_dss-avatar-size);
  flex-shrink: 0;
  // border: var(--_dss-avatar-border-width) solid var(--_dss-avatar-border-color);
  // border:  var(--_dss-avatar-border-width) solid var(--_dss-avatar-border-color);
  border-radius: var(--_dss-avatar-border-radius);


  .avatar-text {
    --_dss-avatar-font-size: var(--dss-font-avatar-textonly-normal);
    font: var(--_dss-avatar-font-size);
  }


  &:has(img) {
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &.silhouette {
    --_dss-avatar-size: var(--dss-sizing-avatar-silhouette-normal);
    --_dss-avatar-border-radius: var(--dss-border-radius-avatar-silhouette-full);
    // --_dss-avatar-border-width: var(--dss-border-width-avatar-silhouette-normal);
    --_dss-avatar-bg-color: var(--dss-color-background-avatar-silhouette-default);
    --_dss-avatar-status-size: var(--dss-sizing-avatar-silhouette-statusicon-large);
    // --_dss-avatar-border-color: var(--dss-color-border-avatar-silhouette-normal);
  }

  &.textonly {
    --_dss-avatar-size: var(--dss-sizing-avatar-textonly-normal);
    --_dss-avatar-border-radius: var(--dss-border-radius-avatar-textonly-full);
    // --_dss-avatar-border-width: var(--dss-border-width-avatar-textonly-normal);
    --_dss-avatar-bg-color: var(--dss-color-background-avatar-textonly-silhouette-default);
    --_dss-avatar-status-size: var(--dss-sizing-avatar-textonly-statusicon-large);
    // --_dss-avatar-border-color: var(--dss-color-border-avatar-textonly-normal);
  }

  &:has(svg) {
    --_dss-avatar-user-size: var(--dss-sizing-avatar-silhouette-icon-normal);

    svg {
      display: inline-flex;
      align-items: center;
      min-width: var(--_dss-avatar-user-size);
      height: var(--_dss-avatar-user-size);
      flex-shrink: 0;
    }
  }

  &-xxl {
    --_dss-avatar-size: var(--dss-sizing-avatar-default-extraextralarge);

    // --_dss-avatar-font-size: var(--dss-font-avatar-text-xxl-font-size);
    // --_dss-avatar-font-weight: var(--dss-font-avatar-text-xxl-font-weight);
    // --_dss-avatar-line-height: var(--dss-font-avatar-text-xxl-line-height);
    // --_dss-avatar-font-size: var(--dss-font-avatar-textonly-extraextralarge);
    &.silhouette {
      --_dss-avatar-size: var(--dss-sizing-avatar-silhouette-extraextralarge);
      --_dss-avatar-status-size: var(--dss-sizing-avatar-silhouette-statusicon-extraextralarge);
    }

    &.textonly {
      --_dss-avatar-size: var(--dss-sizing-avatar-textonly-extraextralarge);
      --_dss-avatar-status-size: var(--dss-sizing-avatar-textonly-statusicon-extraextralarge);
      // --_dss-avatar-font-size: var(--dss-font-avatar-textonly-extraextralarge);
    }

    &:has(svg) {
      --_dss-avatar-user-size: var(--dss-sizing-avatar-silhouette-icon-extraextralarge);
    }

    .avatar-text {
      --_dss-avatar-font-size: var(--dss-font-avatar-textonly-extraextralarge);
    }
  }

  &-xl {
    --_dss-avatar-size: var(--dss-sizing-avatar-default-extralarge);

    // --_dss-avatar-font-size: var(--dss-font-avatar-textonly-extralarge);
    // --_dss-avatar-font-size: var(--dss-font-size-900);
    // --_dss-avatar-font-weight: var(--dss-font-weight-bold);
    &.silhouette {
      --_dss-avatar-size: var(--dss-sizing-avatar-silhouette-extralarge);
      --_dss-avatar-status-size: var(--dss-sizing-avatar-silhouette-statusicon-extralarge);
    }

    &.textonly {
      --_dss-avatar-size: var(--dss-sizing-avatar-textonly-extralarge);
      --_dss-avatar-status-size: var(--dss-sizing-avatar-textonly-statusicon-extralarge);
      // --_dss-avatar-font-size: var(--dss-font-avatar-textonly-extralarge);
    }

    &:has(svg) {
      --_dss-avatar-user-size: var(--dss-sizing-avatar-silhouette-icon-extralarge);
    }

    .avatar-text {
      --_dss-avatar-font-size: var(--dss-font-avatar-textonly-extralarge);
    }
  }

  &-lg {
    --_dss-avatar-size: var(--dss-sizing-avatar-default-large);

    // --_dss-avatar-font-size: var(--dss-font-avatar-textonly-large);
    // --_dss-avatar-font-size: var(--dss-font-avatar-text-large-font-size);
    // --_dss-avatar-font-weight: var(--dss-font-weight-bold);
    &.silhouette {
      --_dss-avatar-size: var(--dss-sizing-avatar-silhouette-large);
      --_dss-avatar-status-size: var(--dss-sizing-avatar-silhouette-statusicon-large);
    }

    &.textonly {
      --_dss-avatar-size: var(--dss-sizing-avatar-textonly-large);
      // --_dss-avatar-font-size: var(--dss-font-avatar-textonly-large);
    }

    &:has(svg) {
      --_dss-avatar-user-size: var(--dss-sizing-avatar-silhouette-icon-large);
    }

    .avatar-text {
      --_dss-avatar-font-size: var(--dss-font-avatar-textonly-large);
    }
  }

  // &-md {
  //   --_dss-avatar-size: var(--dss-sizing-avatar-normal);
  //   --_dss-avatar-font-size: var(--dss-font-avatar-text-normal-font-size);
  //   --_dss-avatar-font-weight: var(--dss-font-weight-bold);

  //   &:has(svg) {
  //     --_dss-avatar-user-size: var(--dss-sizing-avatar-silhouette-icon-normal);
  //   }
  // }

  &-sm {
    --_dss-avatar-size: var(--dss-sizing-avatar-default-dense);

    // --_dss-avatar-font-size: var(--dss-font-size-100);
    // --_dss-avatar-font-size: var(--dss-font-avatar-textonly-dense);
    &.silhouette {
      --_dss-avatar-size: var(--dss-sizing-avatar-silhouette-dense);
    }

    &.textonly {
      --_dss-avatar-size: var(--dss-sizing-avatar-textonly-dense);
      // --_dss-avatar-font-size: var(--dss-font-avatar-textonly-dense);
    }

    &:has(svg) {
      --_dss-avatar-user-size: var(--dss-sizing-avatar-silhouette-icon-dense);
    }

    .avatar-text {
      --_dss-avatar-font-size: var(--dss-font-avatar-textonly-dense);
    }
  }

  &-xs {
    --_dss-avatar-size: var(--dss-sizing-avatar-default-denser);

    // --_dss-avatar-font-size: var(--dss-font-size-100);
    // --_dss-avatar-font-size: var(--dss-font-avatar-textonly-denser);
    &.silhouette {
      --_dss-avatar-size: var(--dss-sizing-avatar-silhouette-denser);
    }

    &.textonly {
      --_dss-avatar-size: var(--dss-sizing-avatar-textonly-denser);
      // --_dss-avatar-font-size: var(--dss-font-avatar-textonly-denser);
    }

    &:has(svg) {
      --_dss-avatar-user-size: var(--dss-sizing-avatar-silhouette-icon-denser);
    }

    .avatar-text {
      --_dss-avatar-font-size: var(--dss-font-avatar-textonly-denser);
    }
  }

  // &-circle {
  //   --_dss-avatar-border-radius: var(--dss-border-radius-avatar-xxl);
  // }
}


.avatar-badge {
  display: inline-flex;
  --_dss-avatar-badge-border-color: var(--dss-color-border-avatar-default-statusactive);
  --_dss-avatar-badge-border-width: var(--dss-border-width-avatar-default-normal);
  --_dss-avatar-badge-bg-color: var(--dss-color-background-avatar-silhouette-statusactive);
  --_dss-avatar-active-border-color: var(--dss-color-border-avatar-default-primary);
  // --_dss-avatar-badge-padding: var(--dss-spacing-100);
  --_dss-avatar-status-top-position: 0;
  --_dss-avatar-status-right-position: 0;
  --_dss-avatar-active-right-position: 0;
  --_dss-avatar-active-bottom-position: 0;
  --_dss-avatar-active-bg-color: var(--dss-color-background-avatar-textonly-silhouette-statusactive);
  // --_dss-avatar-status-size: var(--dss-sizing-200);
  position: relative;

  .avatar {
    &-xxl + .avatar-status {
      // --_dss-avatar-status-size: var(--dss-sizing-600);
      // --_dss-avatar-badge-border-width: 2px;
      
      --_dss-avatar-status-size: var(--dss-sizing-avatar-default-statusicon-extraextralarge);
      --_dss-avatar-status-top-position: 8px;
      --_dss-avatar-status-right-position: 8px;
    }

    &-xxl + .avatar-active {
      // --_dss-avatar-active-size: var(--dss-sizing-500);
      // --_dss-avatar-badge-border-width: 2px;
      --_dss-avatar-active-right-position: 17px;
    }

    &-xl + .avatar-status {
      --_dss-avatar-status-size: var(--dss-sizing-avatar-default-statusicon-extralarge);
      --_dss-avatar-status-right-position: 8px;
    }

    &-xl + .avatar-active {
      // --_dss-avatar-active-size: var(--dss-sizing-300);
      --_dss-avatar-active-right-position: 9px;
      --_dss-avatar-active-bottom-position: 0px;
    }

    &-lg + .avatar-status {
      --_dss-avatar-status-size: var(--dss-sizing-avatar-default-statusicon-large);
      // --_dss-avatar-badge-padding: 2px;
    }

    &-lg + .avatar-active {
      // --_dss-avatar-active-size: var(--dss-sizing-200);
      --_dss-avatar-active-right-position: 2px;
      --_dss-avatar-active-bottom-position: 2px;
    }

    &-status {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      // --_dss-avatar-status-size: var(--dss-sizing-avatar-default-statusicon-large);
      width: var(--_dss-avatar-status-size);
      height: var(--_dss-avatar-status-size);
      // padding: var(--_dss-avatar-badge-padding);
      position: absolute;
      right: var(--_dss-avatar-status-right-position);
      top: var(--_dss-avatar-status-top-position);
      border-radius: var(--dss-border-radius-avatar-default-status-full);
      border: var(--_dss-avatar-badge-border-width) solid var(--_dss-avatar-badge-border-color);
      background: var(--_dss-avatar-badge-bg-color);
    }

    &-active {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      --_dss-avatar-active-size: var(--dss-sizing-avatar-silhouette-icon-normal);
      width: var(--_dss-avatar-active-size);
      height: var(--_dss-avatar-active-size);
      position: absolute;
      right: var(--_dss-avatar-active-right-position);
      bottom: var(--_dss-avatar-active-bottom-position);
      border-radius: var(--dss-border-radius-avatar-default-full);
      border: var(--_dss-avatar-badge-border-width) solid var(--_dss-avatar-active-border-color);
      background: var(--_dss-avatar-active-bg-color);
    }
  }
}

.avatar-group {
  display: flex;
  position: relative;
  $avatar-count: attr(data-avatar-count);
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  --_dss-avatargroup-count-bg-color: var(--dss-color-background-avatargroup-primary);
  --_dss-avatargroup-count-border-color: var(--dss-color-border-avatargroup-secondary);
  // --_dss-avatar-border-width: var(--dss-border-width-avatargroup-dense);
  --_dss-avatargroup-count-font-color: var(--dss-color-text-avatargroup-primary);
  --_dss-avatargroup-count-font-size: var(--dss-font-avatargroup-text-dense);
  --_dss-avatargroup-border-width: var(--dss-border-width-avatargroup-dense);
  --_dss-avatargroup-border-color: var(--dss-color-border-avatargroup-primary);
  --_dss-avatargroup-inline: var(--dss-spacing-avatargroup-inline-normal);

  .avatar {
    // --_dss-avatar-border-width: var(--dss-border-width-400);
    border: var(--_dss-avatargroup-border-width) solid var(--_dss-avatargroup-border-color);
    margin-right: var(--_dss-avatargroup-inline);

    // &-circle {
    //   --_dss-avatar-border-radius: var(--dss-border-radius-avatargroup-xxl);
    // }
    &-lg {
      --_dss-avatar-size: var(--dss-sizing-avatargroup-large);
      --_dss-avatargroup-border-width: var(--dss-border-width-avatargroup-large);
      --_dss-avatar-border-radius: var(--dss-border-radius-avatargroup-large-full);
      --_dss-avatargroup-count-font-size: var(--dss-font-avatargroup-text-normal);
      --_dss-avatargroup-inline: var(--dss-spacing-avatargroup-inline-large);
      // &.avatar-group-count {
      //   --_dss-avatargroup-count-font-size: var( --dss-font-avatargroup-text-normal);
      // }
    }

    &-md {
      --_dss-avatar-size: var(--dss-sizing-avatargroup-normal);
      --_dss-avatargroup-border-width: var(--dss-border-width-avatargroup-normal);
      --_dss-avatar-border-radius: var(--dss-border-radius-avatargroup-normal-full);
      --_dss-avatargroup-count-font-size: var(--dss-font-avatargroup-text-dense);
      // &.avatar-group-count {
      //   --_dss-avatargroup-count-font-size: var( --dss-font-avatargroup-text-dense);
      // }
    }

    &-sm {
      --_dss-avatar-size: var(--dss-sizing-avatargroup-dense);
      --_dss-avatargroup-border-width: var(--dss-border-width-avatargroup-dense);
      --_dss-avatar-border-radius: var(--dss-border-radius-avatargroup-dense-full);
      --_dss-avatargroup-count-font-size: var(--dss-font-avatargroup-text-denser);
      --_dss-avatargroup-inline: var(--dss-spacing-avatargroup-inline-dense);
      // &.avatar-group-count {
      //   --_dss-avatargroup-count-font-size: var( --dss-font-avatargroup-text-denser);
      // }
    }
  }

  // .avatar-remaining {
  //   --_dss-avatar-bg-color: var(--dss-background-secondary);
  //   --_dss-avatar-font-color: var(--dss-color-text-form-primary);
  //   --_dss-avatar-font-size: var(--dss-font-size-200);

  //   &.avatar-xs,
  //   &.avatar-sm {
  //     --_dss-avatar-font-size: var(--dss-font-avatar-text-denser-font-size);
  //   }

  //   &.avatar-md {
  //     --_dss-avatar-font-size: var(--dss-font-avatar-text-dense-font-size);
  //   }

  //   &.avatar-lg {
  //     --_dss-avatar-font-size: var(--dss-font-avatar-text-normal-font-size);
  //   }

  //   &.avatar-xl {
  //     --_dss-avatar-font-size: var(--dss-font-size-400);
  //   }

  //   &.avatar-xxl {
  //     --_dss-avatar-font-size: var(--dss-font-avatar-text-large-font-size);
  //   }
  // }


  &-count {
    --_dss-avatargroup-border-color: var(--dss-color-border-avatargroup-secondary);
    background-color: var(--_dss-avatargroup-count-bg-color);
    // border:  var(--_dss-avatargroup-border-width) solid var(--dss-color-border-avatargroup-secondary);
    // border-color: var(--_dss-avatargroup-count-border-color);
    // --_dss-avatar-border-width: var(--dss-border-width-avatargroup-dense);
    color: var(--_dss-avatargroup-count-font-color);
    font: var(--_dss-avatargroup-count-font-size);
    // --_dss-avatar-font-weight: var(--dss-font-weight-medium);
  }
}
