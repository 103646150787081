.dss-input {
  --_input-wrapper-gap: var(--dss-spacing-textfield-default-normal-stack-field-helpertext);
  --_input-label-wrapper-gap: var(--dss-spacing-textfield-default-normal-stack-label-field);
  --_input-leading-gap: var(--dss-spacing-textfield-default-normal-inline-leadingicon-prefix-value);
  --_input-trailing-gap: var(--dss-spacing-textfield-default-normal-inline-suffix-traillingicon);
  --_input-validation-content-gap: var(--dss-spacing-textfield-default-normal-inline-validatioicon-helpertext);
  --_input-label-color: var(--dss-color-text-textfield-secondary);
  // --_input-label-font-weight: var(--dss-font-textfield-uitext-label-normal-font-weight);
  // --_input-label-font-size: var(--dss-font-textfield-uitext-label-normal-font-size);
  // --_input-label-line-height: var(--dss-font-textfield-uitext-label-normal-line-height);
  // --_input-paddingX: var(--dss-spacing-300);
  // --_input-paddingY: var(--dss-spacing-300);
  --_input-spacing: var(--dss-spacing-textfield-default-inset-normal);
  --_input-bg-color: var(--dss-color-background-textfield-inactive);
  --_input-border-width: var(--dss-border-width-textfield-normal);
  --_input-border-color: var(--dss-color-border-textfield-inactive);
  --_input-border-color-hover: var(--dss-color-border-textfield-focused);
  --_input-border-color-focused: var(--dss-color-border-textfield-focused);
  --_input-border-color-filled: var(--dss-color-border-textfield-filled);
  --_input-border-color-disabled: var(--dss-color-border-textfield-disabled);
  --_input-border-color-error: var(--dss-color-border-textfield-negative);
  --_input-border-color-readonly: var(--dss-color-border-textfield-readonly);
  --_input-active-border-color: var(--dss-color-border-textfield-filled);
  --_input-border-radius: var(--dss-border-radius-textfield-normal);
  --_input-placeholder-color: var(--dss-color-text-textfield-placeholder);
  --_input-valuetext-font: var(--dss-font-textfield-uitext-valuetext-normal);
  --_input-label-font: var(--dss-font-textfield-uitext-label-normal);
  // --_input-placeholder-font-weight: var(--dss-font-textfield-uitext-valuetext-normal-font-weight);
  // --_input-placeholder-font-size: var(--dss-font-textfield-uitext-valuetext-normal-font-size);
  --_input-text-color: var(--dss-color-text-textfield-primary);
  --_input-cursor: text;
  --_input-prefix-color: var(--dss-color-text-textfield-tertiary);
  // --_input-prefix-font-weight: var(--dss-font-textfield-uitext-valuetext-normal-font-weight);
  // --_input-prefix-font-size: var(--dss-font-textfield-uitext-valuetext-normal-font-size);
  // --_input-prefix-line-height: var(--dss-font-textfield-uitext-valuetext-normal-line-height);
  --_input-validation-color: var(--dss-color-text-textfield-negative);
  --_input-validation-font: var(--dss-font-textfield-uitext-validation-normal);
  // --_input-validation-font-size: var(--dss-font-textfield-uitext-validation-normal-font-size);
  // --_input-validation-line-height: var(--dss-font-textfield-uitext-validation-normal-line-height);


  &-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 392px;
    max-width: 392px;
    gap: var(--_input-wrapper-gap);
  }

  &-label-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--_input-label-wrapper-gap);
  }

  &-label {
    color: var(--_input-label-color);
    text-transform: capitalize;
    font: var(--_input-label-font);
    // font-weight: var(--_input-label-font-weight);
    // font-size: var(--_input-label-font-size);
    // line-height: var(--_input-label-line-height);
  }

  &-icon {
    min-width: var(--dss-sizing-textfield-icon-normal);
    max-width: var(--dss-sizing-textfield-icon-normal);
    min-height: var(--dss-sizing-textfield-icon-normal);
    max-height: var(--dss-sizing-textfield-icon-normal);
  }

  &-container {
    display: flex;
    align-items: center;
    // gap: var(--_input-container-gap);
    padding: var(--_input-spacing);
    background-color: var(--_input-bg-color);
    border: var(--_input-border-width) solid var(--_input-border-color);
    border-radius: var(--_input-border-radius);
    min-height: 44px;
    cursor: var(--_input-cursor);

    .dss-input-field {
      flex: 1;
      border: none !important;
      // background-color: var(--_input-bg-color);
      background-color: transparent;
      // caret-color: var(--_input-caret-color);
      outline: none;
      color: var(--_input-text-color);
      // font-weight: var(--_input-placeholder-font-weight);
      // font-size: var(--_input-placeholder-font-size);
      font: var(--_input-valuetext-font);
      resize: none;
      transition: all .35s;
      cursor: var(--_input-cursor);

      &::placeholder {
        color: var(--_input-placeholder-color);
        // font-weight: var(--_input-placeholder-font-weight);
        // font-size: var(--_input-placeholder-font-size);
        font: var(--_input-valuetext-font)
      }
    }

    &:hover {
      --_input-border-color: var(--_input-border-color-hover);
    }

    &.focused {
      --_input-border-color: var(--_input-border-color-focused);
      --_input-bg-color: var(--dss-color-background-textfield-focused);
    }

    &.filled {
      --_input-border-color: var(--_input-border-color-filled);
      --_input-bg-color: var(--dss-color-background-textfield-filled);
    }

    &.disabled {
      --_input-border-color: var(--_input-border-color-disabled);
      --_input-bg-color: var(--dss-color-background-textfield-disabled);
    }

    &.has-value {
      --_input-border-color: var(--_input-border-color-filled);
    }

    &.error {
      --_input-border-color: var(--_input-border-color-error);
    }

    &.readonly {
      --_input-border-color: var(--dss-color-border-textfield-default-readonly);
      --_input-bg-color: var(--dss-color-background-textfield-readonly);
    }
  }

  &-leading {
    display: flex;
    align-items: center;
    gap: var(--_input-leading-gap);
  }
  &-trailing {
    display: flex;
    align-items: center;
    gap: var(--_input-trailing-gap);
  }


  &.disabled {
    --_input-label-color: var(--dss-color-text-textfield-disabled);
    --_input-border-color: var(--_input-border-color-disabled);
    --_input-active-border-color: var(--dss-color-border-textfield-default-disabled);
    --_input-bg-color: var(--dss-color-background-textfield-disabled);
    --_input-prefix-color: var(--dss-color-text-textfield-disabled);
    --_input-cursor: no-drop;
    pointer-events: none;

    .dss-input-icon svg * {
      stroke: var(--dss-color-text-textfield-disabled);
      
    }
  }
  
  &.read-only {
    --_input-bg-color: var(--dss-color-background-textfield-readonly);
    border-color: var(--_input-border-color-readonly);
    --_input-active-border-color: var(--dss-color-border-textfield-readonly);
  }

  .dss-prefix-text {
    color: var(--_input-prefix-color);
    font: var(--_input-valuetext-font);
  }

  .dss-suffix-text {
    color: var(--_input-prefix-color);
    font: var(--_input-valuetext-font);
  }

  .dss-error-container {
    display: flex;
    align-items: center;
    gap: var(--_input-validation-content-gap);
    transform: translateY(-6px);
    opacity: 0;
    visibility: hidden;
    display: none;
    transition: all .4s;
    z-index: 1;

    &.active {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
      display: inline-flex;
    }
  }

  .dss-error-container {
    .dss-error-message {
      color: var(--_input-validation-color);
      // font-size: var(--_input-validation-font-size);
      // line-height: var(--_input-validation-line-height);
      font: var(--_input-validation-font);
    }
  }

  .has-value {
    --_input-border-color: var(--_input-border-color-filled);
  }


  &-fluid {
    min-width: 100%;
    max-width: 100%;
    --_input-wrapper-gap: var(--dss-spacing-textfield-fluid-normal-stack-field-helpertext);
    // --_input-label-wrapper-gap: var(--dss-spacing-textfield-fluid-normal-stack-label-field);
    --_input-leading-gap: var(--dss-spacing-textfield-fluid-normal-inline-prefix-value);
    --_input-trailing-gap: var(--dss-spacing-textfield-fluid-normal-inline-sufix-traillingicon);
    --_input-validation-content-gap: var(--dss-spacing-textfield-fluid-normal-inline-validatioicon-helpertext);
    --_input-spacing: var(--dss-spacing-textfield-fluid-inset-normal);
    // --_input-border-width: var(--dss-border-width-textfield-fluid-normal);
    // --_input-border-color: var(--dss-color-border-textfield-fluid-inactive);
    // --_input-border-color-hover: var(--dss-color-border-textfield-fluid-focused);
    // --_input-border-color-focused: var(--dss-color-border-textfield-fluid-focused);
    // --_input-border-color-filled: var(--dss-color-border-textfield-fluid-filled);
    // --_input-border-color-disabled: var(--dss-color-border-textfield-fluid-disabled);
    // --_input-border-color-error: var(--dss-color-border-textfield-fluid-negative);
    // --_input-border-color-readonly: var(--dss-color-border-textfield-fluid-readonly);
    .dss-input-container {
      position: relative;
      // gap: var(--_input-label-wrapper-gap);
      // --floating-label-x: var(--_input-paddingX);
      // --floating-label-y: var(--_input-paddingY);
      // --floating-label-transform: translateY(calc(10px - var(--floating-label-y)));
      --floating-label-active-transform: scale(1) translateY(-11px) translateX(0px);

      .dss-input-label {
        position: absolute;
        left: var(--floating-label-x);
        // padding-top: 4px;
        // padding-bottom: 3px;
        transform: var(--floating-label-transform);
        transform-origin: 0 0;
        transition: all .4s;
        z-index: 1;
      }

      &.focused {
        .dss-input-label {
          --floating-label-transform: var(--floating-label-active-transform)
        }
      }

      &.has-value {
        .dss-input-label {
          --floating-label-transform: var(--floating-label-active-transform)
        }
      }

      .dss-input-field {
        padding: 19px 0 0 0;
      }

      &.leadingicon {
        .dss-input-label {
          --floating-label-x: 36px;
        }
      }

      &.prefix {
        .dss-prefix-text {
          display: none;
        }
      }

      &.focused.prefix,
      &.has-value.prefix {
        .dss-prefix-text {
          display: block;
          margin-top: 18px;
        }
      }

      &.withtag {
        .dss-input-label {
          --floating-label-transform: var(--floating-label-active-transform)
        }

        .dss-prefix-text {
          display: block;
          margin-top: 18px;
        }

        .dss-tag {
          margin-top: 18px;
        }
      }

    }
  }

  // text-field dense

  &-sm {
    min-width: 327px;
    --_input-border-radius: var(--dss-border-radius-textfield-dense);
    --_input-border-width: var(--dss-border-width-textfield-dense);
    --_input-spacing: var(--dss-spacing-textfield-default-inset-dense);
    --_input-wrapper-gap: var(--dss-spacing-textfield-default-dense-stack-field-helpertext);
    --_input-label-wrapper-gap: var(--dss-spacing-textfield-default-dense-stack-label-field);
    --_input-leading-gap: var(--dss-spacing-textfield-default-dense-inline-leadingicon-prefix-value);
    --_input-trailing-gap: var(--dss-spacing-textfield-default-dense-inline-suffix-traillingicon);
    --_input-validation-content-gap: var(--dss-spacing-textfield-default-dense-inline-validatioicon-helpertext);
    // --_input-placeholder-font-weight: var(--dss-font-textfield-uitext-valuetext-dense-font-weight);
    // --_input-placeholder-font-size: var(--dss-font-textfield-uitext-valuetext-dense-font-size);
    // --_input-prefix-font-weight: var(--dss-font-textfield-uitext-valuetext-dense-font-weight);
    // --_input-prefix-font-size: var(--dss-font-textfield-uitext-valuetext-dense-font-size);
    // --_input-prefix-line-height: var(--dss-font-textfield-uitext-valuetext-dense-line-height);
    // --_input-validation-font-size: var(--dss-font-textfield-uitext-validation-dense-font-size);
    // --_input-validation-line-height: var(--dss-font-textfield-uitext-validation-dense-line-height);
    --_input-valuetext-font: var(--dss-font-textfield-uitext-valuetext-dense);
    --_input-label-font: var(--dss-font-textfield-uitext-label-dense);
    --_input-validation-font: var(--dss-font-textfield-uitext-validation-dense);

    .dss-input-container {
      min-height: 36px;
      --_input-border-radius: var(--dss-border-radius-textfield-dense);
    }

    .dss-input-icon {
      min-width: var(--dss-sizing-textfield-icon-dense);
      max-width: var(--dss-sizing-textfield-icon-dense);
      min-height: var(--dss-sizing-textfield-icon-dense);
      max-height: var(--dss-sizing-textfield-icon-dense);
    }

    // text-field fluid - dense
    &.dss-input-fluid {
      min-width: 100%;
      --_input-wrapper-gap: var(--dss-spacing-textfield-fluid-dense-stack-field-helpertext);
    --_input-leading-gap: var(--dss-spacing-textfield-fluid-dense-inline-prefix-value);
    --_input-trailing-gap: var(--dss-spacing-textfield-fluid-dense-inline-sufix-traillingicon);
    --_input-validation-content-gap: var(--dss-spacing-textfield-fluid-dense-inline-validatioicon-helpertext);
    --_input-spacing: var(--dss-spacing-textfield-fluid-inset-dense);
      .dss-input-container {
        min-height: 44px;
        --_input-spacing: var(--dss-spacing-textfield-fluid-inset-dense);
      }
    }


  }
}
