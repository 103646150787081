.plt-token-details {
  padding: 0 var(--plt-space-600);

  .plt-iconbtn plt-iconbtn-sm plt-token-value-icon {
    .plt-iconbtn {
      min-width: 24px;
      max-width: 24px;
      min-height: 24px;
      max-height: 24px;
    }

  }
}


.token-list {
  gap: 0 !important;
  padding: 0 var(--plt-space-600);
  margin-bottom: var(--plt-space-800);

  .token-item {
    align-items: baseline;
    padding-bottom: var(--plt-space-800);

    // border-bottom: 1px solid #EBE5E5;
    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

}

.plt-padding-wrapper {
  column-gap: var(--plt-space-300);
  padding: 0 var(--plt-space-600);

  .plt-select-dropdown-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
}
