@import "../../assets/scss/helpers/responsive";


.plt-common-card-wrapper {
  max-width: 840px;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: var(--plt-space-600);
  row-gap: var(--plt-space-500);
}

.plt-common-card {
  min-width: calc((100% / 2) - 12px);
  max-width: calc((100% / 2) - 12px);
  border: 1px solid var(--plt-border-secondary);
  border-radius: var(--plt-border-radius-300);
  background: var(--plt-background-tertiary);
  @include mq(desktop) {
    min-width: 100%;
    max-width: 100%;
  }
  // &.active {
  //   border: 1px solid var(--plt-border-secondary);
  //   .edit-btn {
  //     display: block;
  //   }
  //   .plt-common-card-img-box {
  //     box-shadow: none;
  //     border: none;
  //   }
  // }
}

.plt-common-card-box {
  width: 100%;
  .plt-iconbtn{
    background: var(--plt-background-surface);
  }
}

.plt-common-card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // font-size: var(--plt-font-size-400);
  // font-weight: var(--plt-font-weight-600);
  // line-height: var(--plt-line-height-100);
  // color: var(--plt-text-primary);
  //   .edit-btn {
  //     display: none;
  //     min-width: 30px;
  //     max-width: 30px;
  //     min-height: 30px;
  //     max-height: 30px;
  //     background: var(--plt-background-surface);
  //     // padding: 0;
  //     // border-radius: var(--plt-space-150);
  //     // border: 1px solid transparent;
  //     // transition: all 0.35s;

  // }
}

.plt-common-card-des {
  max-width: 180px;
  // font-size: var(--plt-font-size-300);
  // font-weight: var(--plt-font-weight-regular);
  // line-height: var(--plt-line-height-140);
  // color: var(--plt-text-secondary);
  // margin-bottom: 27px;
}

// .plt-common-card-color {
//   padding: var(--plt-space-50) var(--plt-space-100);
//   border-radius: 5px;
//   background: #EFEFEF;
//   // font-size: var(--plt-font-size-200);
//   // font-weight: var(--plt-font-weight-regular);
//   // line-height: var(--plt-line-height-100);
//   // color: var(--plt-text-secondary);
// }

.plt-common-card-axis {
  gap: 25px;
  // .plt-common-card-axis-txt {
  //   font-size: 14px;
  //   font-weight: var(--plt-font-weight-regular);
  //   line-height: var(--plt-line-height-130);
  //   color: #282828;
  // }
  .plt-common-card-axis-value {
    // font-size: 14px;
    // font-weight: var(--plt-font-weight-regular);
    // line-height: var(--plt-line-height-130);
    // color: #282828;
    margin-left: var(--plt-space-50);
  }
}

.plt-common-card-img-box {
  min-width: 146px;
  max-width: 146px;
  min-height: 150px;
  max-height: 150px;
  background: var(--plt-background-surface);
  border-radius: var(--plt-border-radius-150);
  // box-shadow: 0px 4px 6px -2px #17171780;
  // border: 1px solid #8C9DAA;
}

.plt-common-card-box-wrapper {
  align-items: flex-end;
  column-gap: var(--plt-space-400);
}
.plt-common-card-content {
  flex: 1 1 auto;
}

.dss-shadow {
  &-0 {
    box-shadow: var(--dss-shadow-0);
  }
  &-100 {
    box-shadow: var(--dss-shadow-100);
  }
  &-300 {
    box-shadow: var(--dss-shadow-300);
  }
  &-600 {
    box-shadow: var(--dss-shadow-600);
  }
  &-fill {
    box-shadow: var(--dss-shadow-fill-pressed-normal);
  }
}

// application-card

.plt-application-card {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background: var(--plt-background-tertiary);
  border-radius: var(--plt-border-radius-300);
  padding-top: var(--plt-space-800);
  padding-bottom: var(--plt-space-800);
}
.plt-application-card-wrapper {
  display: flex;
  align-items: center;
}

.plt-common-card-badge {
  min-width: fit-content;
  padding: var(--plt-space-100) var(--plt-space-200);
  border-radius: 5px;
  background: var(--plt-background-surface);
  border: 1px solid var(--plt-border-secondary);
  color: var(--plt-text-secondary);
  font-size: var(--plt-font-size-200);
  font-weight: var(--plt-font-weight-regular);
  line-height: var(--plt-line-height-120);
  border-radius: var(--plt-border-radius-700);
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 8px;
    left: 100%;
    width: 64px;
    height: 7px;
    background-image: url("data:image/svg+xml,%3Csvg width='64' height='7' viewBox='0 0 64 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='2' width='61' height='3' fill='%23630B99'/%3E%3Ccircle cx='60.5' cy='3.5' r='3.5' fill='%23630B99'/%3E%3C/svg%3E%0A");
  }
}
