
.plt-heading-usage {
  max-width: 152px;
}

.plt-heading-values {
  display: flex;
  flex-direction: column;
  gap: var(--plt-space-200);
  .plt-heading-info {
    display: block;
  }
}

.plt-heading-values-wrapper,.plt-heading-values{
  transition: all .35s;
}

.plt-table-hover-icon {
  opacity: 0;
  visibility: hidden;
  transition: all .35s;
}