.dss-toggler {
  --_toggler-gap: var(--dss-spacing-toggle-inline-normal);

  // track
  --_toggler-track-width: var(--dss-sizing-toggle-large);
  --_toggler-track-height: var(--dss-sizing-toggle-dense);
  --_toggler-track-bg-color: var(--dss-color-background-toggle-default);
  --_toggler-track-border-color: var(--dss-color-border-toggle-default);
  --_toggler-track-border-radius: var(--dss-border-radius-toggle-normal-full);
  --_toggler-track-border-width: var(--dss-border-width-toggle-normal);
  --_toggler-track-spacing: var(--dss-spacing-toggle-inset-normal);
  

  --_toggler-knob-size: var(--dss-sizing-toggle-denser);
  --_toggler-knob-offset: var(--dss-sizing-50);
  --_toggler-knob-bg-color: var(--dss-color-background-toggle-switch);
  --_toggler-knob-border-radius: var(--dss-border-radius-full);
  --_toggler-knob-box-shadow: var(--dss-box-shadow-toggle-dropshadow-switch-normal);

  // font
  --_dss-toggler-font: var(--dss-font-toggle-uitext-normal);
  // --_dss-toggler-font-weight: var(--dss-font-toggle-uitext-normal-font-weight);
  // --_dss-toggler-font-size: var(--dss-font-toggle-uitext-normal-font-size);
  --_dss-toggler-font-color: var(--dss-color-text-toggle-default);
  // --_dss-toggler-line-height: var(--dss-font-toggle-uitext-normal-line-height);
  // --_dss-toggler-font-family: var(--dss-font-toggle-uitext-normal-font-family);

  display: flex;
  align-items: center;
  column-gap: var(--_toggler-gap);

  .dss-toggler-elem {
    position: relative;
    display: inline-flex;
    align-items: center;
    background-color: var(--_toggler-track-bg-color);
    padding: var(--_toggler-track-spacing);
    width: var(--_toggler-track-width);
    height: var(--_toggler-track-height);
    border: var(--_toggler-track-border-width) solid var(--_toggler-track-border-color);
    border-radius: var(--_toggler-track-border-radius);
    transition: all 0.4s;
    cursor: pointer;

    &:hover {
      --_toggler-track-bg-color: var(--dss-color-background-toggle-hovered);
      --_toggler-track-border-color: var(--dss-color-border-toggle-hovered);
      --_dss-toggler-font-color: var(--dss-color-text-toggle-hovered);
    }

    &:before {
      content: "";
      position: absolute;
      left: var(--_toggler-knob-offset);
      background-color: var(--_toggler-knob-bg-color);
      width: var(--_toggler-knob-size);
      aspect-ratio: 1;
      border-radius: var(--_toggler-knob-border-radius);
      box-shadow: var(--_toggler-knob-box-shadow);
      transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    }
  }

  .dss-toggler-toggler-input {
    display: none;
  }

  .dss-toggler-text {
    // font-weight: var(--_dss-toggler-font-weight);
    // font-size: var(--_dss-toggler-font-size);
    color: var(--_dss-toggler-font-color);
    // line-height: var(--_dss-toggler-line-height);
    // font-family: var(--_dss-toggler-font-family);
    font: var(--_dss-toggler-font);
  }

  &-large {
    --_toggler-track-width: var(--dss-sizing-toggle-extralarge);
    --_toggler-track-height:var(--dss-sizing-toggle-normal);
    --_toggler-gap: var(--dss-spacing-toggle-inline-large);
    --_dss-toggler-font: var(--dss-font-toggle-uitext-large);
    --_toggler-track-border-width: var(--dss-border-width-toggle-large);
    --_toggler-track-border-radius: var(--dss-border-radius-toggle-large-full);
    --_toggler-track-spacing: var(--dss-spacing-toggle-inset-large);
    --_toggler-knob-size: var(--dss-sizing-toggle-dense);
    --_toggler-knob-box-shadow: var(--dss-box-shadow-toggle-dropshadow-switch-large);
    
  }

  &:has(.dss-toggler-toggler-input:checked) {
    .dss-toggler-elem {
      --_toggler-track-bg-color: var(--dss-color-background-toggle-pressed);
      --_toggler-track-border-color: var(--dss-color-border-toggle-pressed);
      --_dss-toggler-font-color: var(--dss-color-text-toggle-pressed);
      &:before {
        left: calc(100% - (var(--_toggler-knob-offset) + var(--_toggler-knob-size)));
      }
    }
  }

  &:has(.dss-toggler-toggler-input:disabled) {
    --_toggler-track-bg-color: var(--dss-color-background-toggle-disabled);
    --_toggler-track-border-color: var(--dss-color-border-toggle-disabled);
    --_dss-toggler-font-color: var(--dss-color-text-toggle-disabled);
    pointer-events: none;

    &:has(.dss-toggler-toggler-input:checked) {
      .dss-toggler-elem {
        --_toggler-track-bg-color: var(--dss-color-background-toggle-disabled);
        --_toggler-track-border-color: var(--dss-color-border-toggle-disabled);
      }
    }
  }
}
