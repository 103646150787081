// .dss-custom-checkbox {
//   --_dss-checkbox-width: var(--dss-sizing-checkbox-default-normal);
//   --_dss-checkbox-height: var(--dss-sizing-checkbox-default-normal);
//   --_dss-checkbox-border-color: var(--dss-color-border-checkbox-default-inactive);
//   --_dss-checkbox-check-color: var(--dss-color-background-checkbox-default-pressed);
//   --_dss-checkbox-border-radius: var(--dss-border-radius-checkbox-default-normal);
//   --_dss-checkbox-border-width: var(--dss-border-width-100);
//   --_dss-checkbox-border-padding: var(--dss-sizing-500);
//   --_dss-checkbox-text-color: var(--dss-color-text-checkbox-default);
//   --dss-checkbox-checked-width: 5.5px;
//   --dss-checkbox-checked-height: 8.5px;
//   --_dss-checkbox-checked-top: 3.5px;
//   --_dss-checkbox-checked-left: 6px;

//   display: flex;

//   .dss-checkbox {
//     input {
//       padding: 0;
//       height: initial;
//       width: initial;
//       margin-bottom: 0;
//       display: none;
//       cursor: pointer;
//     }

//     label {
//       position: relative;
//       color: var(--_dss-checkbox-text-color);
//       cursor: pointer;

//       // &:before {
//       //   content: '';
//       //   top: -1px;
//       //   background-color: var(--dss-checkbox-background-color);
//       //   border: var(--_dss-checkbox-border-width) solid var(--_dss-checkbox-border-color);
//       //   border-radius: var(--_dss-checkbox-border-radius);
//       //   width: var(--_dss-checkbox-width);
//       //   height: var(--_dss-checkbox-height);
//       //   display: inline-block;
//       //   position: relative;
//       //   vertical-align: middle;
//       //   cursor: pointer;
//       //   margin-right: var(--_dss-checkbox-label-margin-right);
//       // }
//     }

//     input:checked + label:after {
//       content: '';
//       display: block;
//       position: absolute;
//       top: var(--_dss-checkbox-checked-top);
//       left: var(--_dss-checkbox-checked-left);
//       width: var(--dss-checkbox-checked-width);
//       height: var(--dss-checkbox-checked-height);
//       border: var(--_dss-checkbox-border-width) solid var(--_dss-checkbox-check-color);
//       border-width: 0 2px 2px 0;
//       transform: rotate(45deg);
//       --_dss-checkbox-text-color: var(--dss-color-text-checkbox-default-pressed);
//     }
//   }


// }

.dss-checkbox-wrapper {
  --_checkbox-size: var(--dss-sizing-checkbox-default-normal);
  --_checkbox-border-width: var(--dss-border-width-checkbox-default-normal);
  --_checkbox-border-color: var(--dss-color-border-checkbox-default-inactive);
  --_checkbox-border-color-hovered: var(--dss-color-border-checkbox-default-hovered);
  --_checkbox-border-color-active: var(--dss-color-border-checkbox-default-pressed);
  --_checkbox-bg-color: var(--dss-color-background-checkbox-default-inactive);
  --_checkbox-bg-color-hovered: var(--dss-color-background-checkbox-default-hovered);
  --_checkbox-bg-color-active: var(--dss-color-background-checkbox-default-pressed);
  --_checkbox-icon-color: '';
  --_checkbox-icon-size: var(--dss-sizing-checkbox-default-denser);
  --_checkbox-border-radius: var(--dss-border-radius-checkbox-default-normal);
  --_checkbox-label-gap: var(--dss-spacing-checkbox-default-inline-normal-choice-label);
  --_checkbox-label-font-color: var(--dss-color-text-checkbox-default);
  --_checkbox-label-font-hovered: var(--dss-color-text-checkbox-hovered);
  --_checkbox-label-font-active: var(--dss-color-text-checkbox-pressed);
  --_checkbox-label-font-spacing: 0px;
  --_checkbox-disabled-border-color: var(--dss-color-border-checkbox-default-disabled);
  --_checkbox-disabled-background-color: var(--dss-color-background-checkbox-default-disabled);
  --_checkbox-label-font-color-disabled: var(--dss-color-text-checkbox-disabled);
  --_checkbox-icon-color-disabled: var(--dss-color-icon-checkbox-default-disabled);

  display: flex;
  align-items: center;
  gap: var(--_checkbox-label-gap);
  // cursor: pointer;

  &:hover {
    // color: var(--dss-color-text-checkbox-hovered);
    // --_dss-checkbox-text-color: var(--dss-color-text-checkbox-hovered);
    .dss-checkbox {
      --_checkbox-border-color: var(--_checkbox-border-color-hovered);
      --_checkbox-bg-color: var(--dss-color-background-checkbox-default-hovered);
      & + .dss-checkbox-label {
        color: var(--_checkbox-label-font-hovered);
      }
    }
    
  }

  .dss-checkbox {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--_checkbox-size);
    height: var(--_checkbox-size);
    border-radius: var(--_checkbox-border-radius);
    border: var(--_checkbox-border-width) solid var(--_checkbox-border-color);
    outline: none;
    background-color: var(--_checkbox-bg-color);
    appearance: none;
    transition: all 0.2s ease;
    cursor: pointer;

    &::after {
      content: '';
      width: calc(var(--_checkbox-size) / 3.5);
      height: calc(var(--_checkbox-size) / 2);
      border: 1px solid var(--_checkbox-icon-color);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      transition: all 0.2s ease;
      margin-top: -2px;
    }

    &:checked {
      --_checkbox-border-color: var(--_checkbox-border-color-active);
      background-color: var(--_checkbox-bg-color-active);
      // color: var(--_checkbox-label-font-active);

      &::after {
        --_checkbox-icon-color: var(--dss-color-icon-checkbox-default-primary);
      }
      & + .dss-checkbox-label {
        color: var(--_checkbox-label-font-active);
      }
    }

    &-label {
      color: var(--_checkbox-label-font-color);
      letter-spacing: var(--_checkbox-label-font-spacing);
      // font-size: var(--dss-font-checkbox-default-uitext-normal-font-size);
      // font-weight: var(--dss-font-checkbox-default-uitext-normal-font-weight);
      // line-height: var(--dss-font-checkbox-default-uitext-normal-line-height);
      font:  var(--dss-font-checkbox-default-uitext-normal);
      cursor: pointer;

      &-small {
        // font-size: var(--dss-font-checkbox-default-uitext-dense-font-size);
        // font-weight: var(--dss-font-checkbox-default-uitext-dense-font-weight);
        // line-height: var(--dss-font-checkbox-default-uitext-dense-line-height);
        font:  var(--dss-font-checkbox-default-uitext-dense);
      }

      &-disable {
        color: var(--_checkbox-label-font-color-disabled);
      }
    }

  

    // &-large {
    //   --_checkbox-size: var(--dss-spacing-600);
    //   --_checkbox-icon-size: var(--dss-sizing-400);
    // }

    &-small {
      --_checkbox-size: var(--dss-sizing-checkbox-default-dense);
      --_checkbox-border-width: var(--dss-border-width-checkbox-default-dense);
      --_checkbox-border-radius: var(--dss-border-radius-checkbox-default-dense);
      --_checkbox-label-gap: var(--dss-spacing-checkbox-default-inline-dense-choice-label);
    }

    &-default {
      --_checkbox-border-color: var(--dss-color-border-checkbox-default-inactive);
      --_checkbox-border-color-hovered: var(--dss-color-border-checkbox-default-hovered);
      --_checkbox-border-color-active: var(--dss-color-border-checkbox-default-pressed);
      --_checkbox-bg-color: var(--dss-color-background-checkbox-default-inactive);
      --_checkbox-bg-color-hovered: var(--dss-color-background-checkbox-default-hovered);
      --_checkbox-bg-color-active: var(--dss-color-background-checkbox-default-pressed);
      // --_checkbox-icon-color: var(--dss-color-border-checkbox-default-surface);
    }

    &-outline {
      --_checkbox-border-radius: var(--dss-border-radius-checkbox-outline-normal);
      --_checkbox-size: var(--dss-sizing-checkbox-outline-normal);
      --_checkbox-border-width: var(--dss-border-width-checkbox-outline-normal);
      --_checkbox-border-color: var(--dss-color-border-checkbox-outline-inactive);
      --_checkbox-border-color-hovered: var(--dss-color-border-checkbox-outline-hovered);
      --_checkbox-border-color-active: var(--dss-color-border-checkbox-outline-pressed);
      // --_checkbox-bg-color-active: var(--dss-color-background-checkbox-default-pressed);
      --_checkbox-bg-color: var(--dss-color-background-checkbox-outline-inactive);
      --_checkbox-bg-color-hovered: var(--dss-color-background-checkbox-outline-hovered);
      --_checkbox-bg-color-active: var(--dss-color-background-checkbox-outline-pressed);
      --_dss-checkbox-text-color: var(--dss-color-text-checkbox-outline-default);
      --_checkbox-label-gap: var(--dss-spacing-checkbox-outline-inline-normal-choice-label);
      --_checkbox-icon-size: var(--dss-sizing-checkbox-outline-denser);
      --_checkbox-disabled-border-color: var(--dss-color-border-checkbox-outline-disabled);
      --_checkbox-disabled-background-color: transparent;
      // --_checkbox-label-font-color-disabled: var(--dss-color-text-checkbox-outline-disabled);
      --_checkbox-icon-color-disabled: var(--dss-color-icon-checkbox-outline-disabled);

      &-label {
        font:  var(--dss-font-checkbox-outline-uitext-normal);
  
        &-small {
          font:  var(--dss-font-checkbox-outline-uitext-dense);
        }

      }
      &:checked {
        --_checkbox-border-color: var(--_checkbox-border-color-active);
        --_checkbox-bg-color-active: transparent;
        // --_dss-checkbox-text-color: var(--dss-color-text-checkbox-outline-pressed);

        &::after {
          --_checkbox-icon-color: var(--dss-color-icon-checkbox-outline-primary);
        }
      }

      &.dss-checkbox-small {
        --_checkbox-border-width: var(--dss-border-width-checkbox-outline-dense);
        --_checkbox-border-radius: var(--dss-border-radius-checkbox-outline-dense);
        --_checkbox-size: var(--dss-sizing-checkbox-outline-dense);
        --_checkbox-label-gap: var(--dss-spacing-checkbox-outline-inline-dense-choice-label);

      }
    }

    &:disabled,
    &-disable {
      // --_checkbox-border-color: var(--dss-color-border-checkbox-default-disabled);
      // --_checkbox-border-color-hovered: var(--dss-color-border-checkbox-default-disabled);
      // --_checkbox-border-color-active: var(--dss-color-border-checkbox-default-disabled);
      // --_checkbox-bg-color-active: var(--dss-color-background-checkbox-default-disabled);
      --_checkbox-border-color: var(--_checkbox-disabled-border-color);
      --_checkbox-background-color: var(--_checkbox-disabled-background-color);
      --_checkbox-icon-color: var(--_checkbox-icon-color-disabled);
      pointer-events: none;

      &:checked {
        --_checkbox-bg-color-active: var(--_checkbox-disabled-background-color);
        --_checkbox-border-color: var(--_checkbox-disabled-border-color);
        --_checkbox-border-color-hovered: var(--_checkbox-disabled-border-color);
        &::after {
          --_checkbox-icon-color: var(--_checkbox-icon-color-disabled);
        }
      }

      & + .dss-checkbox-label {
        pointer-events: none;
        color: var(--_checkbox-label-font-color-disabled);
      }

      // &:hover + .dss-checkbox-label{
      //   color: var(--_checkbox-label-font-color-disabled) !important;
      // }
    }
  }

  
}
