.plt-font-preview {
  max-width: 355px;
  text-align: center;
}

// .plt-dropdown-secondary {
//   background-color: #f8f8f8; /* Light background */
//   border-radius: 10px; /* Rounded corners */
//   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
// }
.plt-dropdown-wrapper {
  z-index: 5;
}

.plt-select-dropdown-wrapper span {
  font-weight: bold;
  // margin-right: 10px;
}
.react-select__control {
  .react-select__indicator  {
    display: none;
  }
}


.plt-select-dropdown-wrapper .custom-select {
  width: 150px; /* Adjust width as needed */
  border-radius: 5px;
}

.plt-select-dropdown-wrapper .custom-select:hover {
  border-color: #007bff; /* Blue border on hover */
}

button.cancel {
  background-color: #f8f8f8; /* Light grey */
  color: #000;
}

button.add {
  background-color: #673ab7; /* Purple */
  color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

button.add:hover {
  background-color: #5c299a; /* Darker purple on hover */
}
