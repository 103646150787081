.plt-token-contents-doc {
  min-width: fit-content;
  padding-left: 14px;
  border-left: 1px solid var(--dss-color-neutrals-300);

  .plt-token-contents-title {
    // color: var(--plt-text-primary);
    // font-size: var(--plt-font-size-200);
    // font-weight: var(--plt-font-weight-regular);
    // line-height: var(--plt-line-height-100);
    margin-bottom: 10px;
  }

  .plt-token-contents-lists {
    list-style: none;
    // padding: 0;

    .plt-token-contents-item {
      position: relative;
      max-width: 125px;
      cursor: pointer;
      color: var(--plt-text-tertiary);
      margin-left: -15px;
      margin-bottom: var(--plt-space-300);
      padding: 0 14px;
      transition: all 0.3s;
      border-left: 2px solid transparent;

      &:not(.active):hover {
        // background-color: #f0f0f0;
        color: var(--dss-color-primary-800);
        // border-left: 2px solid var(--dss-color-primary-800);
        transition: 0.3s;

        &::before {
          content: "";
          position: absolute;
          left: -2px;
          min-width: 2px;
          max-width: 2px;
          min-height: 20px;
          background: var(--dss-color-primary-800);
        }
      }

      &.active {
        color: var(--dss-color-primary-800);

        // border-left: 2px solid var(--dss-color-primary-800);
        &::before {
          content: "";
          position: absolute;
          left: -1px;
          min-width: 2px;
          max-width: 2px;
          min-height: 20px;
          background: var(--dss-color-primary-800);
        }
      }
    }
  }
}
