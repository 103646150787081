@import ".../../../../assets/scss/helpers/responsive";

.otp-container {
  // border-radius:  var(--plt-border-radius-100);
  align-items: center;
  column-gap: var(--plt-space-150);
  display: flex;
  justify-content: flex-start;
  width: 100%;

  .otp-input {
    width: 64px;
    height: 64px;
    text-align: center;
    padding: var(--plt-space-200);
    color: var(--plt-text-secondary);
    // font-size: var(--plt-font-size-600);
    // font-weight: var(--plt-font-weight-regular);
    // line-height: var(--plt-line-height-100);
    border: var(--plt-border-width-100) solid var(--plt-border-tertiary);
    border-radius: var(--plt-border-radius-200);

    @include mq(tablet) {
      width: 20%;
      height: 60px;
    }

    &::placeholder {
      color: var(--plt-text-primary);

    }

    &:focus::placeholder {
      color: transparent;
    }
  }

  .line-after-three-inputs {
    // color: var(--plt-text-secondary);
    // font-family: var(--plt-font-family-secondary);
    // font-weight: var(--plt-font-weight-regular);
    // font-size: var(--plt-font-size-800);
    // line-height: var(--plt-line-height-100);
    padding: 0 10px;
  }
}
