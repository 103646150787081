.header {
  padding: var(--plt-space-600);

}

.user-info {
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--plt-background-tertiary);
}



.main-logo {
  display: flex;
  align-items: center;

  .logo-link {
    text-decoration: none;
  }

  .logo-icon {
    display: flex;
    max-width: var(--plt-logo-size);
    // min-width: 36px;
    // max-width: 36px;
    // min-height: 36px;
    // max-height: 36px;
  }

  .logo-text {
    color: #000;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: bold;
    line-height: 1.5;
  }
}


.user-info-dropdown {
  .user-dropdown {
    min-width: 262px;
    padding: 0;
    border: none;
    border-radius: var(--plt-border-radius-100);
    overflow: hidden;
  }

  .divider {
    display: block;
    width: 100%;
    height: 1px;
    background: var(--plt-border-primary);
  }

  .profile-dropdown {
    .dropdown-list-block {
      padding: var(--plt-space-300) var(--plt-space-500);
      transition: all 0.35s;
      cursor: pointer;

      &:hover {
        background-color: var(--plt-background-secondary);
      }
    }

    .profile-dropdown-active {
      background-color: var(--plt-background-secondary);
    }

    .profile-details {
      padding: var(--plt-space-300) var(--plt-space-600);
    }

    .profile-details-img {
      min-width: var(--plt-size-800);
      max-width: var(--plt-size-800);
      min-height: var(--plt-size-800);
      max-height: var(--plt-size-800);

      .user-image {
        min-width: var(--plt-size-800);
        max-width: var(--plt-size-800);
        min-height: var(--plt-size-800);
        max-height: var(--plt-size-800);
      }
    }

    .dropdown-list-item-icon {
      svg {
        * {
          stroke: var(--plt-background-inverse);
          transition: all 0.35s;
        }
      }
    }
  }

  .domain-title {
    padding: var(--plt-space-100) var(--plt-space-600);
  }

  .domain-list {
    display: flex;
    flex-direction: column;
    // gap: 12px;
    margin-top: var(--plt-space-100);
  }

  .domain-account {
    padding: var(--plt-space-50) var(--plt-space-600);

    &-img {
      min-width: var(--plt-space-800);
      max-width: var(--plt-space-800);
      min-height: var(--plt-space-800);
      max-height: var(--plt-space-800);
    }
  }
}


.tab-profile-dropdown {
  position: absolute;
  z-index: 1;
  top: 24px;
  right: 40px;

  .user-info {
    min-width: var(--plt-size-800);
    max-width: var(--plt-size-800);
    min-height: var(--plt-size-800);
    max-height: var(--plt-size-800);
    // background: transparent;
  }

  .user-dropdown {
    top: 45px;
    right: 0;
  }
}

.setting-profile-dropdown {
  // position: relative;
  // padding-bottom: 40px;
  padding: 24px 40px 12px 40px;
  border-bottom: 1px solid var(--plt-border-secondary);

  .tab-profile-dropdown {
    position: relative;
    top: 0;
    right: 0;
  }

  .setting-header-lft {
    display: flex;
    flex-direction: column;
    gap: var(--plt-space-100);
  }
}


.plan-steps-box {
  background: var(--plt-semantics-informative-100);
  border-radius: var(--plt-border-radius-300);
  padding: var(--plt-space-300);
}
