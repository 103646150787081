

// :root{--plt-badge-caution:#B2630E;--plt-background-badge-caution:#F7EBDF;}
.plt-badge {
  // --_plt-badge-font-size: var(--plt-font-size-200);
  // --_plt-badge-font-color: var(--plt-text-secondary);
  // --_plt-badge-line-height: var(--plt-line-height-120);
  --_plt-badge-padding: var(--plt-space-50) var(--plt-space-150);
  --_plt-badge-border-radius: var(--plt-border-radius-100);
  --_plt-badge-bg-color: var(--plt-background-neutral);

  display: inline-block;
  padding: var(--_plt-badge-padding);
  border-radius: var(--_plt-badge-border-radius);
  background-color: var(--_plt-badge-bg-color);
  transition: all .35s;
  &:empty {
    display: none;
  }
 

  &-surface {
    --_plt-badge-bg-color: var(--plt-background-surface);
  }

  &-status {
  width: max-content;
  color: var(--plt-text-button-fill-primary);
  --_plt-badge-padding: 0 var(--plt-space-300);
  &.badgePrimary {
    --_plt-badge-bg-color: var(--plt-text-positive);
  }
  &.badgeNegative{
    --_plt-badge-bg-color: var(--plt-text-negative);
  }
  }

  &-neutral {
    // --_plt-badge-padding: var(--plt-space-50) var(--plt-space-150);
    background-color: var(--plt-background-secondary);
    border: 1px solid var(--plt-border-secondary);
    border-radius: var(--plt-border-radius-full);
    &:hover{
      background-color: var(--plt-background-primary);
    }
  }

  &-fill {
    border: 1px solid var(--_plt-badgefill-border-color);
    border-radius: var(--plt-border-radius-full);
    &-success {
      --_plt-badge-bg-color: var(--plt-background-positive-default);
      // color: var(--plt-background-informative);
      --_plt-badgefill-border-color: var(--plt-border-positive-default);
    }
    &-caution {
      // color: var(--plt-badge-caution);
      --_plt-badgefill-border-color: var(--plt-border-negative-default);
      background: var(--plt-background-negative-default);
    }
  }

}
