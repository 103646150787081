.dss-alert {
  --_alert-bg-color: '';
  --_alert-main-gap: var(--dss-spacing-alert-inline-between-text-ctc);
  --_alert-contents-gap: var( --dss-spacing-alert-inline-text-ctc-icononly);
  --_alert-contents-info-gap: var(--dss-spacing-alert-inline-leadingicon-text);
  --_alert-padding: var(--dss-spacing-alert-inset-normal);
  --_alert-border-radius: var(--dss-border-radius-alert-dense);
  --_alert-msg-container-gap: var(--dss-spacing-alert-stack-heading-bodytext);
  --_alert-msg-color: '';
  // --_alert-heading-size: var(--dss-font-alert-text-heading-large);
  // --_alert-heading-weight: var(--dss-font-alert-text-heading-large);
  // --_alert-heading-line-height: var(--dss-font-alert-text-heading-large);
  // --_alert-desc-size: var(--dss-font-alert-text-body-normal);
  // --_alert-desc-weight: var(--dss-font-alert-text-body-normal);
  // --_alert-desc-line-height: var(--dss-font-alert-text-body-normal);
  --_alert-close-size: var(--dss-sizing-800);
  --_alert-action-btn-bg: transparent;
  display: flex;
  gap: var(--_alert-main-gap);
  min-width: 336px;
  background-color: var(--_alert-bg-color);
  padding: var(--_alert-padding);
  border-radius: var(--_alert-border-radius);


  &-informative {
    --_alert-bg-color: var(--dss-color-background-alert-informative);
    --_alert-msg-color: var(--dss-color-text-alert-informative);
    --_alert-icon-color: var(--dss-color-icon-alert-informative);
  }

  &-caution {
    --_alert-bg-color: var(--dss-color-background-alert-caution);
    --_alert-msg-color: var(--dss-color-text-alert-caution);
    --_alert-icon-color: var(--dss-color-icon-alert-caution);
  }

  &-success {
    --_alert-bg-color: var(--dss-color-background-alert-success);
    --_alert-msg-color: var(--dss-color-text-alert-success);
    --_alert-icon-color: var(--dss-color-icon-alert-success);
  }

  &-danger {
    --_alert-bg-color: var(--dss-color-background-alert-danger);
    --_alert-msg-color: var(--dss-color-text-alert-danger);
    --_alert-icon-color: var(--dss-color-icon-alert-danger);
  }

  .dss-alert-contents-wrapper {
    display: flex;
    gap: var(--_alert-contents-gap);
    min-width: 0;
    flex: 1 1 0;
  }
  .dss-alert-contents-info {
    display: flex;
    gap: var(--_alert-contents-info-gap);
  }

  &-icon {
    min-width: var(--dss-sizing-alert-icon-normal);
    max-width: var(--dss-sizing-alert-icon-normal);
    min-height: var(--dss-sizing-alert-icon-normal);
    max-height: var(--dss-sizing-alert-icon-normal);

    svg {
      > * {
        stroke: var(--_alert-icon-color);
      }
    }
  }

  &-msg {
    color: var(--_alert-msg-color);
    // font-size: var(--_alert-heading-size);
    // font-weight: var(--_alert-heading-weight);
    // line-height: var(--_alert-heading-line-height);
    font: var(--dss-font-alert-text-heading-large);
  }

  &-desc {
    color: var(--_alert-msg-color);
    // font-size: var(--_alert-desc-size);
    // font-weight: var(--_alert-desc-weight);
    // line-height: var(--_alert-desc-line-height);
    font: var(--dss-font-alert-text-body-normal);
  }

  &-msg-container {
    display: flex;
    justify-content: space-between;
    gap: var(--_alert-msg-container-gap);
    width: 100%;
  }

  &-btn-group {
    align-items: baseline;
    gap: var(--_alert-main-gap);
  }

  &-close-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: var(--_alert-close-size);
    max-width: var(--_alert-close-size);
    min-height: var(--_alert-close-size);
    max-height: var(--_alert-close-size);
    margin-left: auto;
    cursor: pointer;

    svg {
      min-width: 12px;

      > * {
        stroke: var(--_alert-icon-color);
        stroke-width: 1;
      }
    }
  }

  &-action-footer {
    flex-direction: column;
  }
}
