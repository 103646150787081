.ql-snow {

  .ql-editor {
    font-family: 'Rethink Sans', sans-serif;
    h1 {
      font-size: var(--plt-heading-xl-font-size);
      color: var(--plt-text-primary);
      font-weight: var(--plt-heading-xl-font-weight);
      line-height: var(--plt-heading-xl-line-height);
      font-family: var(--plt-heading-xl-font-family);
      padding-bottom: var(--plt-space-200);
    }
    h2 {
      font-size: var(--plt-heading-lg-font-size);
      color: var(--plt-text-primary);
      font-weight: var(--plt-font-weight-semi-bold);
      line-height: var(--plt-heading-lg-line-height);
      font-family: var(--plt-heading-lg-font-family);
      padding-top: var(--plt-space-600);
    }
    h3 {
      font-size: var(--plt-heading-md-font-size);
      color: var(--plt-text-primary);
      font-weight: var(--plt-font-weight-semi-bold);
      line-height: var(--plt-heading-md-line-height);
      font-family: var(--plt-heading-md-font-family);
      padding-top: var(--plt-space-600);
    }
    h4 {
      font-size: var(--plt-heading-sm-font-size);
      color: var(--plt-text-primary);
      font-weight: var(--plt-font-weight-semi-bold);
      line-height: var(--plt-heading-sm-line-height);
      font-family: var(--plt-heading-sm-font-family);
       padding-top: var(--plt-space-600);
    }
    h5 {
      font-size: var(--plt-heading-xs-font-size);
      color: var(--plt-text-primary);
      font-weight: var(--plt-heading-xs-font-weight);
      line-height: var(--plt-heading-xs-line-height);
      font-family: var(--plt-heading-xs-font-family);
       padding-top: var(--plt-space-600);
    }
    h6 {
      font-size: var(--plt-heading-xxs-font-size);
      color: var(--plt-text-primary);
      font-weight: var(--plt-heading-xxs-font-weight);
      line-height: var(--plt-heading-xxs-line-height);
      font-family: var(--plt-heading-xxs-font-family);
      padding-top: var(--plt-space-600);
    }
    p {
      color: var(--plt-text-secondary);
      font-size: var(--plt-body-md-font-size);
      font-weight: var(--plt-body-md-font-weight);
      line-height: var(--plt-body-md-line-height);
      font-family: var(--plt-body-md-font-family);
    }
  }

}

.editor-wrapper {
  // border: 1px solid #ccc;
  // border-radius: 4px;
  // padding: 10px;
  .ql-toolbar.ql-snow{
    border-radius: 4px 4px 0 0;
  }
  .ql-container.ql-snow {
    border-radius: 0 0 4px 4px;
  }
}

.toolbar-grid {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.editor-container {
  min-height: 200px;
}

.editor-btn-wrapper {
  gap: var(--plt-space-200);
}

.html-preview {
  white-space: pre-wrap;
  background-color: #f5f5f5;
  padding: 10px;
  border: 1px solid #ddd;
}