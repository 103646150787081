@import "../../assets/scss/helpers/responsive";

.dss-textarea {
  --_textarea-wrapper-gap: var(--dss-spacing-textarea-default-normal-stack-field-helpertext);
  --_textarea-label-wrapper-gap: var(--dss-spacing-textarea-default-normal-stack-label-field);
  // --_textarea-leading-gap: var(--dss-spacing-textarea-default-normal-inline-leadingicon-prefix-value);
  // --_textarea-trailing-gap: var(--dss-spacing-textarea-default-normal-inline-suffix-traillingicon);
  --_textarea-validation-content-gap: var(--dss-spacing-textarea-default-normal-inline-validatioicon-helpertext);
  --_textarea-label-color: var(--dss-color-text-textarea-secondary);
  // --_textarea-label-font-weight: var(--dss-font-textarea-uitext-label-normal-font-weight);
  // --_textarea-label-font-size: var(--dss-font-textarea-uitext-label-normal-font-size);
  // --_textarea-label-line-height: var(--dss-font-textarea-uitext-label-normal-line-height);
  --_textarea-spacing: var(--dss-spacing-textarea-default-inset-normal);
  --_textarea-bg-color: var(--dss-color-background-textarea-inactive);
  --_textarea-border-width: var(--dss-border-width-textarea-normal);
  --_textarea-border-color: var(--dss-color-border-textarea-inactive);
  --_textarea-border-color-hover: var(--dss-color-border-textarea-focused);
  --_textarea-border-color-focused: var(--dss-color-border-textarea-focused);
  --_textarea-border-color-filled: var(--dss-color-border-textarea-filled);
  --_textarea-border-color-disabled: var(--dss-color-border-textarea-disabled);
  --_textarea-border-color-error: var(--dss-color-border-textarea-negative);
  --_textarea-border-color-readonly: var(--dss-color-border-textarea-readonly);
  --_textarea-active-border-color: var(--dss-color-border-textarea-focused);
  --_textarea-border-radius: var(--dss-border-radius-textarea-normal);
  --_textarea-placeholder-color: var(--dss-color-text-textarea-placeholder);
  --_textarea-valuetext-font: var(--dss-font-textarea-uitext-valuetext-normal);
  --_textarea-label-font: var(--dss-font-textarea-uitext-label-normal);
  --_textarea-helpertext-font: var(--dss-font-textarea-uitext-helpertext-normal);
  // --_textarea-placeholder-font-weight: var(--dss-font-textarea-uitext-value-text-normal-font-weight:);
  // --_textarea-placeholder-font-size: var(--dss-font-textarea-uitext-value-text-normal-font-size);
  --_textarea-text-color: var(--dss-color-text-textarea-primary);
  --_textarea-cursor: text;
  --_textarea-validation-color: var(--dss-color-text-textarea-negative);
  --_textarea-validation-font: var(--dss-font-textarea-uitext-validation-normal);
  --_textarea-count-container-gap: var(--dss-spacing-textarea-default-normal-inline-helpertext-count);
  --_textarea-count-text-color: var(--dss-color-text-textarea-tertiary);
  --_textarea-error-icon-size: var(--dss-sizing-textarea-icon-normal);
  // --_textarea-count-text-font-size: var(--dss-font-textarea-uitext-validation-normal-font-size);
  // --_textarea-count-text-font-weight: var(--dss-font-textarea-uitext-validation-normal-font-weight);
  // --_textarea-count-text-line-height: var(--dss-font-textarea-uitext-validation-normal-line-height);
  // --_textarea-count-text-spacing: var(--dss-font-letter-spacing-normal);

  &-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 494px;
    gap: var(--_textarea-wrapper-gap);
    // overflow: auto;
  }

  &-label-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--_textarea-label-wrapper-gap);
  }

  &-label {
    color: var(--_textarea-label-color);
    text-transform: capitalize;
    font: var(--_textarea-label-font);
  }

  &-container {
    display: flex;
    flex-direction: column;
    min-height: 160px;
    // gap: var(--_textarea-wrapper-gap);
    padding: var(--_textarea-spacing);
    background-color: var(--_textarea-bg-color);
    border: var(--_textarea-border-width) solid var(--_textarea-border-color);
    border-radius: var(--_textarea-border-radius);
    cursor: var(--_textarea-cursor);

    .dss-textarea-field {
      min-width: 469px;
      min-height: 144px;
      border: none;
      background-color: transparent;
      cursor: var(--_textarea-cursor);
      outline: none;
      color: var(--_textarea-text-color);
      // font-weight: var(--dss-font-textarea-uitext-value-text-normal-font-weight);
      // font-size: var(--dss-font-textarea-uitext-value-text-normal-font-size);
      font: var(--_textarea-valuetext-font);
      resize: none;

      @include mq(desktop) {
        min-width: 330px;
      }

      @include mq(desktop-sm) {
        min-width: 300px;
      }

      &::placeholder {
        color: var(--_textarea-placeholder-color);
        // font-weight: var(--_textarea-placeholder-font-weight);
        // font-size: var(--_textarea-placeholder-font-size);
        font: var(--_textarea-valuetext-font)
      }
    }

    &:hover {
      --_textarea-border-color: var(--_textarea-border-color-hover);
    }

    &.focused {
      --_textarea-border-color: var(--_textarea-border-color-focused);
      --_textarea-bg-color: var(--dss-color-background-textarea-focused);
    }

    &.filled {
      --_textarea-border-color: var(--_textarea-border-color-filled);
      --_textarea-bg-color: var(--dss-color-background-textarea-filled);
    }

    &.disabled {
      --_textarea-border-color: var(--_textarea-border-color-disabled);
      --_textarea-bg-color: var(--dss-color-background-textarea-disabled);
    }

    &.has-value {
      --_textarea-border-color: var(--dss-color-border-textarea-filled);
    }

    &.error {
      --_textarea-border-color: var(--dss-color-border-textarea-negative);
    }

    &.readonly {
      --_textarea-border-color: var(--dss-color-border-textarea-default-readonly);
      --_textarea-bg-color: var(--dss-color-background-textarea-readonly);
    }

    .dss-tag {
      width: fit-content;
    }
  }


  &.disabled {
    --_textarea-label-color: var(--dss-color-text-textarea-disabled);
    --_textarea-border-color: var(--dss-color-border-textarea-disabled);
    --_textarea-active-border-color: var(--dss-color-border-textarea-disabled);
    --_textarea-bg-color: var(--dss-color-background-textarea-disabled);
    --_textarea-cursor: no-drop;
    --_textarea-count-text-color: var(--dss-color-text-textarea-disabled);
    --_textarea-placeholder-color: var(--dss-color-text-textarea-disabled);
    pointer-events: none;
  }

  &.read-only {
    --_textarea-bg-color: var(--dss-color-background-textarea-readonly);
    border-color: var(--_textarea-border-color-readonly);
    --_textarea-active-border-color: var(--dss-color-border-textarea-readonly);
  }

  .dss-error-container {
    align-items: center;
    gap: var(--_textarea-validation-content-gap);
    transform: translateY(-6px);
    opacity: 0;
    visibility: hidden;
    display: none;
    transition: all .4s;
    z-index: 1;

    &.active {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
      display: inline-flex;
    }

    .dss-error-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: var(--_textarea-error-icon-size);
      max-width: var(--_textarea-error-icon-size);
      min-height: var(--_textarea-error-icon-size);
      max-height: var(--_textarea-error-icon-size);
    }

    .dss-error-message {
      color: var(--_textarea-validation-color);
      // font-size: var(--_textarea-validation-font-size);
      // letter-spacing: var(--_textarea-validation-spacing);
      // line-height: var(--_textarea-validation-line-height);
      font: var(--_textarea-validation-font);
    }
  }

  .has-value {
    --_textarea-border-color: var(--_textarea-border-color-filled);
  }

  .dss-count-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--_textarea-count-container-gap);

    .dss-count-text {
      color: var(--_textarea-count-text-color);
      // font-size: var(--_textarea-count-text-font-size);
      // font-weight: var(--_textarea-count-text-font-weight);
      // line-height: var(--_textarea-count-text-line-height);
      font: var(--_textarea-helpertext-font);
    }
  }

  &-fluid {
    min-width: 100%;
    --_textarea-wrapper-gap: var(--dss-spacing-textarea-fluid-normal-stack-field-helpertext);
    --_textarea-validation-content-gap: var(--dss-spacing-textarea-fluid-normal-inline-validatioicon-helpertext);
    --_textarea-spacing: var(--dss-spacing-textarea-fluid-inset-normal);
    --_textarea-label-wrapper-gap: var(--dss-spacing-textarea-fluid-normal-stack-label-value-chip);
    --_textarea-count-container-gap: var(--dss-spacing-textarea-fluid-normal-inline-helpertext-count);

    .dss-textarea-container {
      gap: var(--dss-spacing-textarea-fluid-normal-stack-label-value-chip);
    }
  }


  &-sm {

    --_textarea-border-radius: var(--dss-border-radius-textarea-dense);
    --_textarea-border-width: var(--dss-border-width-textarea-dense);
    --_textarea-spacing: var(--dss-spacing-textarea-default-inset-dense);
    --_textarea-wrapper-gap: var(--dss-spacing-textarea-default-dense-stack-field-helpertext);
    --_textarea-label-wrapper-gap: var(--dss-spacing-textarea-default-dense-stack-label-field);
    --_textarea-validation-content-gap: var(--dss-spacing-textarea-default-dense-inline-validatioicon-helpertext);
    --_textarea-valuetext-font: var(--dss-font-textarea-uitext-valuetext-dense);
    --_textarea-label-font: var(--dss-font-textarea-uitext-label-dense);
    --_textarea-validation-font: var(--dss-font-textarea-uitext-validation-dense);
    --_textarea-helpertext-font: var(--dss-font-textarea-uitext-helpertext-dense);
    --_textarea-count-container-gap: var(--dss-spacing-textarea-default-dense-inline-helpertext-count);
    --_textarea-error-icon-size: var(--dss-sizing-textarea-icon-dense);

    // text-field fluid - dense
    &.dss-textarea-fluid {
      --_textarea-wrapper-gap: var(--dss-spacing-textarea-fluid-dense-stack-field-helpertext);
      --_textarea-leading-gap: var(--dss-spacing-textarea-fluid-dense-inline-prefix-value);
      --_textarea-trailing-gap: var(--dss-spacing-textarea-fluid-dense-inline-sufix-traillingicon);
      --_textarea-validation-content-gap: var(--dss-spacing-textarea-fluid-dense-inline-validatioicon-helpertext);
      --_textarea-spacing: var(--dss-spacing-textarea-fluid-inset-dense);
      --_textarea-count-container-gap: var(--dss-spacing-textarea-fluid-dense-inline-helpertext-count);
    }


  }

}
