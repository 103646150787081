.tab-content-body{
  background-color: var(--plt-background-surface);
}

.tab-content-wrapper {
  padding: 48px 0;
}

.tab-content-setting {
  padding: 32px 0;
}