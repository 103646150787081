.plt-toggler {
  // --_toggler-color: var(--dss-text-primary);
  display: flex;
  align-items: center;
  // column-gap: var(--dss-sizing-16);
  pointer-events: none;

  .plt-toggler-elem {
    position: relative;
    display: flex;
    min-width: var(--dss-sizing-800);
    height: 16px;
    transition: all 0.4s;
    pointer-events: all;
  }

  .plt-toggler-slider {
    position: absolute;
    inset: 0;
    background-color: var(--dss-background-default);
    background-color: var(--dss-background-tertiary);
    border-radius: var(--dss-border-radius-full);
    transition: all 0.4s;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      left: 3px;
      top: calc(50% - 12px / 2);
      background-color: var(--dss-background-surface);
      width: var(--dss-sizing-300);
      aspect-ratio: 1;
      border-radius: 50%;
      // box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
      box-shadow: var(--dss-shadow-300);
      transition: all 0.4s;
    }
  }

  // .plt-toggler-content {
  // 	display: flex;
  // 	align-items: center;
  // 	column-gap: 8px;
  // }

  // .plt-toggler-icon {
  // 	font-size: 18px;
  // 	font-weight: 400;
  // 	color: var(--_toggler-color);
  // 	line-height: 1.3;
  // }

  // .plt-toggler-text {
  // 	font-weight: 400;
  // 	font-size: 14px;
  // 	color: var(--_toggler-color);
  // 	line-height: 1.3;
  // }

  .plt-toggler-toggler-input {
    display: none;
    transition: all 0.4s;

    &:checked {
      & + .plt-toggler-slider {
        // --_toggler-active-color: var(--dss-accent);
        // background-color: var(--_toggler-active-color);
        // background-color: var(--dss-background-default);
        background-color: var(--dss-background-selected);

        &:before {
          transform: translateX(16px);
        }
      }
    }
  }

  // &-disable {

  // 	.plt-toggler-slider {
  // 		background-color: var(--dss-background-disabled);
  // 		&::before {
  // 			box-shadow: var(--dss-shadow-0);
  // 		}
  // 	}
  // 	.plt-toggler-elem {
  // 		pointer-events: none;
  // 	}
  // 	.plt-toggler-toggler-input {
  // 			&:checked {
  // 				& + .plt-toggler-slider {
  // 					background-color: var(--dss-background-disabled);
  // 				}
  // 			}
  // 	}
  // 	}

  // Enable toggle option also for label text
  // &.plt-toggler-cta {
  // 	pointer-events: all;
  // }

  // &.plt-toggler {
  // 	&-primary {
  // 		.plt-toggler-toggler-input {
  // 			&:checked {
  // 				& + .plt-toggler-slider {
  // 					--_toggler-active-color: var(--dss-accent);
  // 				}
  // 			}
  // 		}
  // 	}

  // &-secondary {
  // 	.plt-toggler-toggler-input {
  // 		&:checked {
  // 			& + .plt-toggler-slider {
  // 				--_toggler-active-color: var(--dss-color-primary);
  // 			}
  // 		}
  // 	}
  // }
  // }
  &-large {
    .plt-toggler-elem {
      min-width: var(--dss-sizing-1000);
      height: 20px;
    }

    .plt-toggler-slider {
      &:before {
        top: calc(50% - 16px / 2);
        width: var(--dss-sizing-400);
      }
    }
  }
}
