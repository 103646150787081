.plt-component-card {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--plt-border-secondary);
  border-radius: var(--plt-border-radius-300);
  padding: var(--plt-space-800);
}



