:root {
  --dss-font-family-primary: "Inter", sans-serif;
  --dss-font-family-secondary: "Inter", sans-serif;
}

.dss-font-family-primary {
  font-family: var(--dss-font-family-primary);
}

.dss-font-family-secondary {
  font-family: var(--dss-font-family-secondary);
}

.dss-heading-3xl {
  // font-size: var(--dss-font-heading-3xl-font-size);
  // font-weight: var(--dss-font-heading-3xl-font-weight);
  // line-height: var(--dss-font-heading-3xl-line-height);
  // font-family: var(--dss-font-heading-3xl-font-family);
  font: var(--dss-font-heading-3xl);
}

.dss-heading-2xl {
  // font-size: var(--dss-font-heading-2xl-font-size);
  // font-weight: var(--dss-font-heading-2xl-font-weight);
  // line-height: var(--dss-font-heading-2xl-line-height);
  // font-family: var(--dss-font-heading-2xl-font-family);
  font: var(--dss-font-heading-2xl);
}

.dss-heading-xl {
  // font-size: var(--dss-font-heading-xl-font-size);
  // font-weight: var(--dss-font-heading-xl-font-weight);
  // line-height: var(--dss-font-heading-xl-line-height);
  // font-family: var(--dss-font-heading-xl-font-family);
  font: var(--dss-font-heading-xl);
}

.dss-heading-lg {
  // font-size: var(--dss-font-heading-lg-font-size);
  // font-weight: var(--dss-font-heading-lg-font-weight);
  // line-height: var(--dss-font-heading-lg-line-height);
  // font-family: var(--dss-font-heading-lg-font-family);
  font: var(--dss-font-heading-lg);
}

.dss-heading-md {
  // font-size: var(--dss-font-heading-md-font-size);
  // font-weight: var(--dss-font-heading-md-font-weight);
  // line-height: var(--dss-font-heading-md-line-height);
  // font-family: var(--dss-font-heading-md-font-family);
  font: var(--dss-font-heading-md);
}

.dss-heading-sm {
  // font-size: var(--dss-font-heading-sm-font-size);
  // font-weight: var(--dss-font-heading-sm-font-weight);
  // line-height: var(--dss-font-heading-sm-line-height);
  // font-family: var(--dss-font-heading-sm-font-family);
  font: var(--dss-font-heading-sm);
}

.dss-heading-xs {
  // font-size: var(--dss-font-heading-xs-font-size);
  // font-weight: var(--dss-font-heading-xs-font-weight);
  // line-height: var(--dss-font-heading-xs-line-height);
  // font-family: var(--dss-font-heading-xs-font-family);
  font: var(--dss-font-heading-xs);
}

.dss-heading-xxs {
  // font-size: var(--dss-font-heading-xxs-font-size);
  // font-weight: var(--dss-font-heading-xxs-font-weight);
  // line-height: var(--dss-font-heading-xxs-line-height);
  // font-family: var(--dss-font-heading-xxs-font-family);
  font: var(--dss-font-heading-xxs);
}

.dss-body-lg {
  // font-size: var(--dss-font-body-lg-font-size);
  // font-weight: var(--dss-font-body-lg-font-weight);
  // line-height: var(--dss-font-body-lg-line-height);
  // font-family: var(--dss-font-body-lg-font-family);
  font: var(--dss-font-body-lg);
}

.dss-body-md {
  // font-size: var(--dss-font-body-md-font-size);
  // font-weight: var(--dss-font-body-md-font-weight);
  // line-height: var(--dss-font-body-md-line-height);
  // font-family: var(--dss-font-body-md-font-family);
  font: var(--dss-font-body-md);
}

.dss-body-sm {
  // font-size: var(--dss-font-body-sm-font-size);
  // font-weight: var(--dss-font-body-sm-font-weight);
  // line-height: var(--dss-font-body-sm-line-height);
  // font-family: var(--dss-font-body-sm-font-family);
  font: var(--dss-font-body-sm);
}

.dss-body-xs {
  // font-size: var(--dss-font-body-xs-font-size);
  // font-weight: var(--dss-font-body-xs-font-weight);
  // line-height: var(--dss-font-body-xs-line-height);
  // font-family: var(--dss-font-body-xs-font-family);
  font: var(--dss-font-body-xs);
}

.dss-body-xxs {
  // font-size: var(--dss-font-body-xs-font-size);
  // font-weight: var(--dss-font-body-xs-font-weight);
  // line-height: var(--dss-font-body-xs-line-height);
  // font-family: var(--dss-font-body-xs-font-family);
  font: var(--dss-font-body-xxs);
}

.dss-body-compact-md {
  // color: var(--dss-text-primary);
  // font-size: var(--dss-font-body-compact-md-font-size);
  // font-weight: var(--dss-font-body-compact-md-font-weight);
  // line-height: var(--dss-font-body-compact-md-line-height);
  // font-family: var(--dss-font-body-compact-md-font-family);
  font: var(--dss-font-body-compact-md);
}

.dss-body-compact-sm {
  // color: var(--dss-text-primary);
  // font-size: var(--dss-font-body-compact-sm-font-size);
  // font-weight: var(--dss-font-body-compact-sm-font-weight);
  // line-height: var(--dss-font-body-compact-sm-line-height);
  // font-family: var(--dss-font-body-compact-sm-font-family);
  font: var(--dss-font-body-compact-sm);
}

.dss-body-strong-lg-medium {
  // // color: var(--dss-text-primary);
  // font-size: var(--dss-font-body-strong-lg-medium-font-size);
  // font-weight: var(--dss-font-body-strong-lg-medium-font-weight);
  // line-height: var(--dss-font-body-strong-lg-medium-line-height);
  // font-family: var(--dss-font-body-strong-lg-medium-font-family);
  font: var(--dss-font-body-strong-lg-medium);
}

.dss-body-strong-lg-semi-bold {
  // // color: var(--dss-text-primary);
  // font-size: var(--dss-font-body-strong-lg-semi-bold-font-size);
  // font-weight: var(--dss-font-body-strong-lg-semi-bold-font-weight);
  // line-height: var(--dss-font-body-strong-lg-semi-bold-line-height);
  // font-family: var(--dss-font-body-strong-lg-semi-bold-font-family);
  font: var(--dss-font-body-strong-lg-semi-bold);
}

.dss-body-strong-md-medium {
  // // color: var(--dss-text-primary);
  // font-size: var(--dss-font-body-strong-md-medium-font-size);
  // font-weight: var(--dss-font-body-strong-md-medium-font-weight);
  // line-height: var(--dss-font-body-strong-md-medium-line-height);
  // font-family: var(--dss-font-body-strong-md-medium-font-family);
  font: var(--dss-font-body-strong-md-medium);
}

.dss-body-strong-md-semi-bold {
  // // color: var(--dss-text-primary);
  // font-size: var(--dss-font-body-strong-md-semi-bold-font-size);
  // font-weight: var(--dss-font-body-strong-md-semi-bold-font-weight);
  // line-height: var(--dss-font-body-strong-md-semi-bold-line-height);
  // font-family: var(--dss-font-body-strong-md-semi-bold-font-family);
  font: var(--dss-font-body-strong-md-semi-bold);
}

.dss-body-strong-sm-medium {
  // // color: var(--dss-text-primary);
  // font-size: var(--dss-font-body-strong-sm-medium-font-size);
  // font-weight: var(--dss-font-body-strong-sm-medium-font-weight);
  // line-height: var(--dss-font-body-strong-sm-medium-line-height);
  // font-family: var(--dss-font-body-strong-sm-medium-font-family);
  font: var(--dss-font-body-strong-sm-medium);
}

.dss-body-strong-sm-semi-bold {
  // // color: var(--dss-text-primary);
  // font-size: var(--dss-font-body-strong-sm-semi-bold-font-size);
  // font-weight: var(--dss-font-body-strong-sm-semi-bold-font-weight);
  // line-height: var(--dss-font-body-strong-sm-semi-bold-line-height);
  // font-family: var(--dss-font-body-strong-sm-semi-bold-font-family);
  font: var(--dss-font-body-strong-sm-semi-bold);
}

.dss-ui-text-lg {
  // // color: var(--dss-text-primary);
  // font-size: var(--dss-font-uitext-lg-regular-font-size);
  // font-weight: var(--dss-font-uitext-lg-regular-font-weight);
  // line-height: var(--dss-font-uitext-lg-regular-line-height);
  // font-family: var(--dss-font-uitext-lg-regular-font-family);
  font: var(--dss-font-uitext-lg-regular);
}

.dss-ui-text-lg-medium {
  // // color: var(--dss-text-primary);
  // font-size: var(--dss-font-uitext-lg-medium-font-size);
  // font-weight: var(--dss-font-uitext-lg-medium-font-weight);
  // line-height: var(--dss-font-uitext-lg-medium-line-height);
  // font-family: var(--dss-font-uitext-lg-medium-font-family);
  font: var(--dss-font-uitext-lg-medium);
}

.dss-ui-text-lg-semi-bold {
  // // color: var(--dss-text-primary);
  // font-size: var(--dss-font-uitext-lg-semi-bold-font-size);
  // font-weight: var(--dss-font-uitext-lg-semi-bold-font-weight);
  // line-height: var(--dss-font-uitext-lg-semi-bold-line-height);
  // font-family: var(--dss-font-uitext-lg-semi-bold-font-family);
  font: var(--dss-font-uitext-lg-semi-bold);
}

.dss-ui-text-md {
  // // color: var(--dss-text-primary);
  // font-size: var(--dss-font-uitext-md-regular-font-size);
  // font-weight: var(--dss-font-uitext-md-regular-font-weight);
  // line-height: var(--dss-font-uitext-md-regular-line-height);
  // font-family: var(--dss-font-uitext-md-regular-font-family);
  font: var(--dss-font-uitext-md-regular);
}

.dss-ui-text-md-medium {
  // // color: var(--dss-text-primary);
  // font-size: var(--dss-font-uitext-md-medium-font-size);
  // font-weight: var(--dss-font-uitext-md-medium-font-weight);
  // line-height: var(--dss-font-uitext-md-medium-line-height);
  // font-family: var(--dss-font-uitext-md-medium-font-family);
  font: var(--dss-font-uitext-md-medium);
}

.dss-ui-text-md-semi-bold {
  // // color: var(--dss-text-primary);
  // font-size: var(--dss-font-uitext-md-semi-bold-font-size);
  // font-weight: var(--dss-font-uitext-md-semi-bold-font-weight);
  // line-height: var(--dss-font-uitext-md-semi-bold-line-height);
  // font-family: var(--dss-font-uitext-md-semi-bold-font-family);
  font: var(--dss-font-uitext-md-semi-bold);
}

.dss-ui-text-sm {
  // color: var(--dss-text-primary);
  // font-size: var(--dss-font-uitext-sm-regular-font-size);
  // font-weight: var(--dss-font-uitext-sm-regular-font-weight);
  // line-height: var(--dss-font-uitext-sm-regular-line-height);
  // font-family: var(--dss-font-uitext-sm-regular-font-family);
  font: var(--dss-font-uitext-sm-regular);
}

.dss-ui-text-sm-medium {
  // // color: var(--dss-text-primary);
  // font-size: var(--dss-font-uitext-sm-medium-font-size);
  // font-weight: var(--dss-font-uitext-sm-medium-font-weight);
  // line-height: var(--dss-font-uitext-sm-medium-line-height);
  // font-family: var(--dss-font-uitext-sm-medium-font-family);
  font: var(--dss-font-uitext-sm-medium);
}

.dss-ui-text-sm-semi-bold {
  // // color: var(--dss-text-primary);
  // font-size: var(--dss-font-uitext-sm-semi-bold-font-size);
  // font-weight: var(--dss-font-uitext-sm-semi-bold-font-weight);
  // line-height: var(--dss-font-uitext-sm-semi-bold-line-height);
  // font-family: var(--dss-font-uitext-sm-semi-bold-font-family);
  font: var(--dss-font-uitext-sm-semi-bold);
}

.dss-ui-text-xs {
  // // color: var(--dss-text-primary);
  // font-size: var(--dss-font-uitext-xs-regular-font-size);
  // font-weight: var(--dss-font-uitext-xs-regular-font-weight);
  // line-height: var(--dss-font-uitext-xs-regular-line-height);
  // font-family: var(--dss-font-uitext-xs-regular-font-family);
  font: var(--dss-font-uitext-xs-regular);
}

.dss-ui-text-xs-medium {
  // // color: var(--dss-text-primary);
  // font-size: var(--dss-font-uitext-xs-medium-font-size);
  // font-weight: var(--dss-font-uitext-xs-medium-font-weight);
  // line-height: var(--dss-font-uitext-xs-medium-line-height);
  // font-family: var(--dss-font-uitext-xs-medium-font-family);
  font: var(--dss-font-uitext-xs-medium);
}

.dss-ui-text-xs-semi-bold {
  // // color: var(--dss-text-primary);
  // font-size: var(--dss-font-uitext-xs-semi-bold-font-size);
  // font-weight: var(--dss-font-uitext-xs-semi-bold-font-weight);
  // line-height: var(--dss-font-uitext-xs-semi-bold-line-height);
  // font-family: var(--dss-font-uitext-xs-semi-bold-font-family);
  font: var(--dss-font-uitext-xs-semi-bold);
}

.dss-ui-text-xxs {
  // // color: var(--dss-text-primary);
  // font-size: var(--dss-font-uitext-xxs-regular-font-size);
  // font-weight: var(--dss-font-uitext-xxs-regular-font-weight);
  // line-height: var(--dss-font-uitext-xxs-regular-line-height);
  // font-family: var(--dss-font-uitext-xxs-regular-font-family);
  font-size: var(--dss-font-uitext-xxs-regular);
}

.dss-ui-text-xxs-medium {
  // // color: var(--dss-text-primary);
  // font-size: var(--dss-font-uitext-xxs-medium-font-size);
  // font-weight: var(--dss-font-uitext-xxs-medium-font-weight);
  // line-height: var(--dss-font-uitext-xxs-medium-line-height);
  // font-family: var(--dss-font-uitext-xxs-medium-font-family);
  font: var(--dss-font-uitext-xxs-medium);
}

.dss-ui-text-xxs-semi-bold {
  // // color: var(--dss-text-primary);
  // font-size: var(--dss-font-uitext-xxs-semi-bold-font-size);
  // font-weight: var(--dss-font-uitext-xxs-semi-bold-font-weight);
  // line-height: var(--dss-font-uitext-xxs-semi-bold-line-height);
  // font-family: var(--dss-font-uitext-xxs-semi-bold-font-family);
  font: var(--dss-font-uitext-xxs-semi-bold);
}


// colors
.dss-font-color-primary {
  color: var(--dss-color-neutrals-1000) !important;
}

.dss-font-color-secondary {
  color: var(--dss-color-neutrals-700) !important;
}

.dss-font-color-tertiary {
  color: var(--dss-color-neutrals-500) !important;
}
