.plt-icon-box {
  width: 100%;
  flex-direction: column;
  background: var(--plt-background-tertiary);
  border-radius: var(--plt-border-radius-300);
  transition: all .35s;

  .icon-image {
    min-width: var(--dss-sizing-800);
    max-width: var(--dss-sizing-800);
    min-height: var(--dss-sizing-800);
    max-height: var(--dss-sizing-800);
  }

  &-txt {
    gap: var(--plt-space-200);
  }

  &-download {
    min-width: var(--dss-sizing-800);
    max-width: var(--dss-sizing-800);
    min-height: var(--dss-sizing-800);
    max-height: var(--dss-sizing-800);
    // display: none;
  }

  .bg-elem {
    fill: var(--plt-background-surface);
  }

  .arrow-elem {
    stroke: var(--plt-text-primary);
  }
}
