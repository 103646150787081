.dss-tab {
  --_tab-btn-gap: var(--dss-spacing-tab-default-tablist-inline-between-tabs);
  // --_tab-btn-paddingX: var(--dss-spacing-100);
  // --_tab-btn-paddingY: var(--dss-spacing-300);
  --_tab-btn-spacing: var(--dss-spacing-tab-default-tablist-inset-normal);
  --_tab-btn-icon-color: var(--dss-color-icon-tab-default-unselected);
  --_tab-btn-text-color: var(--dss-color-text-tab-default-unseclect);
  --_tab-btn-text-font: var(--dss-font-tab-default-uitext-normal);
  // --_tab-btn-font-weight: var(--dss-font-weight-medium);
  // --_tab-btn-font-size: var(--dss-font-size-400);
  --_tab-btn-active-border-height: var(--dss-border-width-tab-default-large);
  --_tab-btn-border-color: transparent;
  --_tab-btn-hovered-border-color: var(--dss-color-border-tab-default-hovered);
  --_tab-btn-active-border-color: var(--dss-color-border-tab-default-selected);
  --_tab-header-border-height: var(--dss-border-width-tab-default-normal);
  --_tab-header-border-color: var(--dss-color-border-tab-default-stroke);
  --_tab-btn-bg-color: transparent;
  --_tab-btn-border-radius: var(--dss-border-radius-0);
  --_tab-btn-icon-gap: var(--dss-spacing-tab-default-tablist-inline-leadingicon-tabtext);
  // --_tab-btn-badge-padding: var(--dss-spacing-50) var(--dss-spacing-200);
  // --_tab-btn-badge-bg: var(--dss-background-primary);
  // --_tab-btn-badge-border-radius: var(--dss-border-radius-tab-large-full);
  // --_tab-btn-badge-text-color: var(--dss-text-secondary);
  

  &-header {
    display: flex;
    align-items: center;
    gap: var(--_tab-btn-gap);
    border-bottom: var(--_tab-header-border-height) solid var(--_tab-header-border-color);

    &-pill {
      --_tab-btn-gap: var(--dss-spacing-tab-pill-tablist-inline-between-tabs);
      --_tab-header-border-height: var(--dss-spacing-0);
      --_tab-header-border-color: transparent;
      --_tab-btn-icon-gap: var(--dss-spacing-tab-pill-tablist-inline-leadingicon-tabtext);
    }
  }

  &-btn {
    display: flex;
    align-items: center;
    // min-width: 87px;
    gap: var(--_tab-btn-icon-gap);
    padding: var(--_tab-btn-spacing);
    color: var(--_tab-btn-text-color);
    // font-weight: var(--_tab-btn-font-weight);
    // font-size: var(--_tab-btn-font-size);
    font: var(--_tab-btn-text-font);
    cursor: pointer;
    border: none;
    border-bottom: var(--_tab-btn-active-border-height) solid var(--_tab-btn-border-color);
    background-color: var(--_tab-btn-bg-color);
    text-transform: capitalize;
    border-radius: var(--_tab-btn-border-radius);
    transition: all 0.4s;

    &:hover {
      border-bottom: var(--_tab-btn-active-border-height) solid var(--_tab-btn-hovered-border-color);
      --_tab-btn-text-color: var(--dss-color-text-tab-default-hovered);
      --_tab-btn-icon-color: var( --dss-color-icon-tab-default-primary)
    }

    &.active {
      border-bottom: var(--_tab-btn-active-border-height) solid var(--_tab-btn-active-border-color);
      // --_tab-btn-active-border-color: var(--dss-color-border-tab-selected);
      --_tab-btn-text-color: var(--dss-color-text-tab-default-selected);
      --_tab-btn-icon-color: var( --dss-color-icon-tab-default-primary);
    }
    &.disable {
      // border-bottom: var(--_tab-btn-active-border-height) solid var(--_tab-btn-active-border-color);
      // --_tab-btn-active-border-color: var(--dss-color-border-tab-selected);
      --_tab-btn-text-color: var(--dss-color-text-tab-default-disabled);
      --_tab-btn-icon-color: var( var(--dss-color-icon-tab-default-disabled));
    }

    &-pill {
      --_tab-btn-border-radius: var(--dss-border-radius-tab-pill-normal);
      --_tab-btn-spacing: var( --dss-spacing-tab-pill-tablist-inset-normal);
      --_tab-btn-bg-color:var(--dss-color-background-tab-pill-unselected);
      --_tab-btn-hovered-border-color: var(--dss-color-border-tab-pill-hovered);
      --_tab-btn-active-border-color: var(--dss-color-border-tab-pill-selected);
      // --_tab-header-border-height: var(--dss-border-width-tab-default-normal);
      --_tab-btn-active-border-height: var(--dss-spacing-0);
      --_tab-btn-icon-color:var(--dss-color-icon-tab-pill-unselected);
      --_tab-btn-text-color: var(--dss-color-text-tab-pill-unseclect);
      --_tab-btn-text-font: var(--dss-font-tab-pill-uitext-normal);

      &.active {
        --_tab-btn-bg-color: var(--dss-color-background-tab-pill-selected);
        --_tab-btn-text-color: var(--dss-color-text-tab-pill-selected);
       --_tab-btn-icon-color: var(--dss-color-icon-tab-pill-primary);
        --_tab-btn-text-color: var(--dss-color-text-tab-pill-selected);
     
        // --_tab-btn-badge-bg: var(--dss-background-surface);
      }

      &:hover {
        --_tab-btn-bg-color: var( --dss-color-background-tab-pill-hover);
        --_tab-btn-icon-color: var(--dss-color-icon-tab-pill-secondary);
        --_tab-btn-text-color:var(--dss-color-text-tab-pill-hovered);
        // --_tab-btn-badge-bg: var(--dss-background-surface);
      }
      
      &.disable {
        // border-bottom: var(--_tab-btn-active-border-height) solid var(--_tab-btn-active-border-color);
        // --_tab-btn-active-border-color: var(--dss-color-border-tab-selected);
        --_tab-btn-text-color: var(--dss-color-text-tab-fill-disabled);
        --_tab-btn-icon-color: var(--dss-color-icon-tab-pill-disabled);
      }
    }

    // &-badge {
    //   padding: var(--_tab-btn-badge-padding);
    //   background-color: var(--_tab-btn-badge-bg);
    //   border-radius: var(--_tab-btn-badge-border-radius);
    //   color: var(--_tab-btn-badge-text-color);
    // }
  }

  .prefix-icon {
    min-width: var(--dss-sizing-tab-default-icon-normal);
    max-width: var(--dss-sizing-tab-default-icon-normal);
    min-height: var(--dss-sizing-tab-default-icon-normal);
    max-height: var(--dss-sizing-tab-default-icon-normal);
    svg {
      path {
        transition: all 0.4s;
        stroke: var(--_tab-btn-icon-color);
      }

      rect {
        transition: all 0.4s;
        stroke: var(--_tab-btn-icon-color);
      }
    }
  }

  .suffix-icon {
    min-width: var(--dss-sizing-tab-default-icon-dense);
    max-width: var(--dss-sizing-tab-default-icon-dense);
    min-height: var(--dss-sizing-tab-default-icon-dense);
    max-height: var(--dss-sizing-tab-default-icon-dense);
    svg {
      path {
        transition: all 0.4s;
        stroke: var(--_tab-btn-icon-color);
      }
    }
  }

  &-body {
    padding-top: 10px;
  }
}
