.account-container {
  max-width: 790px;

  .image-dropdown {
    position: relative;
    // max-width: 96px;
    gap: var(--plt-space-500);
    .user-image {
      min-width: 96px;
      max-width: 96px;
      min-height: 96px;
      max-height: 96px;
    }
    // .user-dropdown {
    //   top: 100px;
    //   left: 0;
    // }
    // .dropdown-item-icon {
    //   width: 16px;
    //   height: 16px;
    // }
    // .image-dropdown-edit  {
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   position: absolute;
    //   bottom: 8px;
    //   right: 8px;
    //   width: 28px;
    //   height: 28px;
    //   border-radius: 50%;
    //   background: var(--plt-background-primary);
    // }
    .image-edit-wrapper  {
      .image-edit-list {
        list-style: none;
        gap: var(--plt-space-200);
      }
    }
  }

  .filetype {
    &[type="file"] {
      height: 0;
      overflow: hidden;
      width: 0;
    }
    
    &[type="file"] + label {
      outline: none;
      position: relative;
      transition: all 0.3s;
      vertical-align: middle;
      // margin-left: -6px;
      // padding: 0;
      
      // &:hover {
      //   background-color: darken(#f15d22, 10%);
      // }
    }
  }

  .account-row {
    gap: var(--plt-space-500);
    .plt-select-dropdown-wrapper{
      flex: 1;
    }
  }
}

// .password-block {
//   display: flex;
//   flex-direction: column;
//   gap: var(--plt-space-400);
//   max-width: 385px;
//   background-color: var(--plt-background-tertiary);
//   // border: 1px solid var(--plt-background-primary);
//   padding: var(--plt-space-600);
//   border-radius: var(--plt-space-200);

 
// }



