.pagination {
  --pagination-size: var(--dss-sizing-pagination-normal);
  gap: var(--dss-spacing-pagination-inline-between-counts);

  .pagination-list {
    display: flex;
    align-items: center;
    justify-content: center;

    // min-width: var(--dss-sizing-pagination-normal);
    // max-width: var(--dss-sizing-pagination-normal);
    // min-height: var(--dss-sizing-pagination-normal);
    // max-height: var(--dss-sizing-pagination-normal);

    &-icon {
      svg {
        * {
          stroke: var(--dss-color-icon-pagination-default);
          transition: all 0.4s;
        }
      }
    }

    &-disabled {
      &.pagination-list-icon {
        svg {
          * {
            stroke: var(--dss-color-icon-pagination-disabled);
          }
        }
      }
    }

    .pagination-list-item {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: var(--pagination-size);
      max-width: var(--pagination-size);
      aspect-ratio: 1;
      font: var(--dss-font-pagination-text-normal);
      color: var(--dss-color-text-pagination-default);
      border-radius: var(--dss-border-radius-pagination-normal);
      border-width: var(--dss-border-width-pagination-normal);
      // border-radius: var(--dss-border-radius-pagination-dense);
      transition: all 0.3s;
      cursor: pointer;

      &.active {
        background-color: var(--dss-color-background-pagination-pressed);
        color: var(--dss-color-text-pagination-pressed);
      }

      // &:hover:not(.active) {
      //   background-color: var(--dss-color-background-pagination-pressed);
      //   color: var(--dss-color-text-pagination-pressed);
      // }
    }

    &:hover:not(:first-child):not(:last-child) {
      .pagination-list-item:not(.active) {
        background-color: var(--dss-color-background-pagination-pressed);
        color: var(--dss-color-text-pagination-pressed);
      }
    }

    // &:hover:first-child .pagination-list-item {
    //   background: transparent;
    // }

    // &:hover:first-child .pagination-list-item {
    //   background: transparent;
    // }

    // &:hover:last-child .pagination-list-item {
    //   background: transparent;
    // }

  }
}
