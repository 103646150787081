.dss-empty-state {
  &-box {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--dss-spacing-emptystate-stack-icon-text);
    padding: var(--dss-spacing-emptystate-inset-large);
    background: var(--dss-color-background-emptystate-surface);
    border-width: var(--dss-border-width-emptystate-normal);
    border-color: var(--dss-color-border-emptystate-default);
    border-radius: var(--dss-border-radius-emptystate-dense);
    text-align: center;
  }
    &-img {
      min-width: 75px;
      max-width: 75px;
    }

    &-content-wrapper {
      display: flex;
      flex-direction: column;
      gap: var(--dss-spacing-emptystate-stack-text-ctc);
      
    }

    &-content{
      display: flex;
      flex-direction: column;
      gap: var(--dss-spacing-emptystate-stack-text-subtext);
    }

    &-text {
      // font-size: var(--dss-font-emptystate-text-large-font-size);
      // font-weight: var(--dss-font-emptystate-text-large-font-weight);
      // line-height: var(--dss-font-emptystate-text-large-line-height);
      font: var(--dss-font-emptystate-text-large);
      color: var(--dss-color-text-emptystate-primary);
    }

    &-para {
      // font-size: var(--dss-font-emptystate-text-normal-font-size);
      // font-weight: var(--dss-font-emptystate-text-normal-font-weight);
      // line-height: var(--dss-font-emptystate-text-normal-line-height);
      font: var(--dss-font-emptystate-text-normal);
      color: var(--dss-color-text-emptystate-secondary);
    }
  
}