.chrome-picker,
.sketch-picker {
  width: 100% !important;
  padding: 10px;
  border-radius: var(--plt-border-radius-200) !important;
  box-shadow: none !important;
  box-sizing: border-box !important;
  margin-bottom: 1px;

  & > div {
    border-radius: var(--plt-border-radius-200) !important;

    + div {
      border-radius: 0;
      padding: 16px 0 !important;
    }
  }

  .hue-horizontal {
    border-radius: 10px;
  }

  [id*="rc-editable-input"] {
    font-size: 14px !important;
    padding: 6px 10px !important;
    height: auto !important;
    border-radius: 6px !important;
    border: 1px solid #E5E8E8 !important;
    box-shadow: none !important;
    outline: 0px !important;
    transition: all .4s;

    &:focus {
      border-color: var(--dss-color-border-textfield-focused) !important;
    }
  }
}

.shades {

  .chrome-picker,
  .sketch-picker {
    box-shadow: none !important;

    .flexbox-fix {
      * {
        margin-bottom: 0 !important;
      }

      .hue-horizontal {
        display: none !important;
      }

      &:last-child > *:last-child {
        display: none;
      }
    }
  }
}

.plt-color-detail-block {
  display: flex;
  flex-direction: column;
  gap: var(--plt-space-200);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}

.plt-color-picker-container {
  min-width: auto;
}

.change-token-modal {
  .plt-modal-content {
    --modal-width-max: 830px;
  }

  .plt-theme-pallette-lists {
    &:not(:hover) {

      .plt-theme-pallette-item {
        &.active {
          min-width: fit-content;
          max-width: fit-content;
          border-radius: 8px;
          transform: scale(1.1);
          z-index: 1;
        }
      }
    }
  }
}

.plt-color-picker-modal {
  .plt-modal-content {
    --modal-width-max: 375px;
  }
}
