

.dss-modal-content {
  background: var(--dss-color-background-modal-surface);
  border-radius: var(--dss-border-radius-modal-normal) var(--dss-border-radius-modal-normal) 0 0;
  border: var(--dss-border-width-modal-dense) solid var(--dss-color-border-modal-stroke);
  min-width: 472px;
  max-width: 800px; // Adjust max-width as needed
}
.dss-iconbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  // min-width: var(--dss-sizing-button-icon-large);
  // max-width: var(--dss-sizing-button-icon-large);
  // min-height: var(--dss-sizing-button-icon-large);
  // max-height: var(--dss-sizing-button-icon-large);
  // background: var(--dss-background-tertiary);
  padding: 0;
  // border-radius: var(--dss-border-radius-150);
  transition: all 0.35s;
  cursor: pointer;
}
.dss-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--dss-spacing-modal-header-inset-large);
  &-leading {
    display: flex;
    align-items: center;
    gap: var(--dss-spacing-modal-header-inline-leadingicon-titletext);
  }

  .dss-modal-header-icon {
    min-width: var(--dss-sizing-modal-dense);
    max-width: var(--dss-sizing-modal-dense);
    min-height: var(--dss-sizing-modal-dense);
    max-height: var(--dss-sizing-modal-dense);
  }
 
  &-title {
    // font-size: var(--dss-font-modal-text-normal-font-size);
    // font-weight: var(--dss-font-modal-text-normal-font-weight);
    // line-height: var(--dss-font-modal-text-normal-line-height);
    color: var(--dss-color-text-primary);
    font: var(--dss-font-modal-text-normal);
  }
}

.dss-modal-close-icon {
  background: transparent;
  transition: all 0.4s;
  cursor: pointer;

  &:hover {
    transition: all 0.35s;
  }
}

.dss-modal-body {
  min-height: 100%;
  max-height: calc(100vh - 210px);
  overflow-x: hidden;
  overflow-y: auto;
  min-width: 472px;
  background: var(--dss-color-background-modal-surface);
  border-radius: var(--dss-border-radius-modal-dense);
  border: var(--dss-border-width-modal-dense) solid var(--dss-color-border-modal-stroke);
}

.dss-preview {
  display: flex;
  justify-content: center;
 
  padding: var(--dss-spacing-modal-body-inset-large);
  font: var(--dss-font-modal-text-normal);
  position: sticky;
  top: 0;
  z-index: 2;
}

.dss-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: var(--dss-spacing-modal-footer-inline-between-ctc);
  padding: var(--dss-spacing-modal-footer-inset-large);
  min-width: 472px;
  background: var(--dss-color-background-modal-surface);
  border-radius: var(--dss-border-radius-modal-dense) var(--dss-border-radius-modal-dense) var(--dss-border-radius-modal-normal) var(--dss-border-radius-modal-normal);
}



.upload-btn{
  min-width: var(--plt-size-400);
  max-width: var(--plt-size-400);
  min-height: var(--plt-size-400);
  max-height: var(--plt-size-400);
  background: transparent;
  border: none;
}