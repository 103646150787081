:root {
  --plt-logo-size: 122px;
}

.input-field-custom {
  display: flex;
  align-items: center;
  height: 44px;
  padding: var(--plt-space-200) var(--plt-space-300);
  border: var(--plt-border-width-100) solid var(--plt-border-input);
  border-radius: var(--plt-border-radius-100);
  background: var(--plt-background-surface);
  color: var(--plt-text-primary) !important;
  font-size: var(--plt-font-size-300);
  font-weight: var(--plt-font-weight-regular);
  line-height: var(--plt-line-height-120);
  margin-top: var(--plt-space-100);
  transition: all 0.35s;
  outline: none;
  width: 100%;

  &::placeholder {
    color: var(--plt-text-placeholder);
    font-size: var(--plt-font-size-300);
    font-weight: var(--plt-font-weight-regular);
    line-height: var(--plt-line-height-120);
  }

  &:hover {
    border: var(--plt-border-width-100) solid var(--plt-border-tertiary);
  }

  &:focus {
    border: var(--plt-border-width-100) solid var(--plt-border-tertiary);
  }

  &:focus-visible {
    border: var(--plt-border-width-100) solid var(--plt-border-tertiary);
  }

  &-multi {
    height: auto;
    max-width: 445px;

    &.focused {
      border-color: var(--plt-border-input-active);
    }

    &.react-multi-email > span[data-placeholder] {
      color: var(--plt-text-placeholder);
      font-size: var(--plt-font-size-200);
      font-weight: var(--plt-font-weight-regular);
      line-height: var(--plt-line-height-120);
      top: 14px;
      left: var(--plt-space-300);
      padding: 0;
      padding-left: 1px;
    }
  }

}


.primary-button-custom {
  width: 100%;
  border-radius: var(--plt-space-200);
}

.menu {
  position: relative;
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;

  .dropdown-menu {
    width: fit-content;
    position: absolute;
    top: 29px;
    right: 0;
    background: var(--plt-background-surface);
    padding: var(--plt-space-300);
    border: 1px solid var(--plt-border-secondary);
    border-radius: var(--plt-border-radius-150);
    z-index: 1;

    ul {
      list-style: none;

      li {
        display: flex;
        gap: var(--plt-space-200);
        min-width: 177px;
        padding: 10px;
        cursor: pointer;
        border-radius: var(--plt-border-radius-150);
        transition: all 0.35s;

        &:hover {
          background-color: var(--plt-background-secondary);
        }

        + li {
          margin-top: var(--plt-space-100);
        }
      }
    }
  }
}

.plt-icon-wrapper {
  column-gap: var(--plt-space-200);
  width: fit-content;
  margin-left: auto;

  span {
    min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;
  }
}

.plt-token-contents {
  --dss-content-dox-gap-x: 15px;
  --dss-content-dox-width: calc(160px + var(--dss-content-dox-gap-x));
  position: sticky;
  top: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-item-align: start;
  align-self: flex-start;
  gap: 16px;
  min-width: var(--dss-content-dox-width);
  max-width: var(--dss-content-dox-width);
}

.plt-modal-link {
  text-decoration: underline;
  transition: all 0.35s;
  color: var(--plt-text-button-link-primary);

  &-icon {
    display: flex;
    align-items: center;
    min-width: var(--plt-space-500);
    max-width: var(--plt-space-500);
    min-height: var(--plt-space-500);
    max-height: var(--plt-space-500);
  }

  &:hover {
    color: var(--plt-text-button-link-link-hover);
  }

  &-negative {
    color: var(--plt-text-negative);

    &:hover {
      color: var(--plt-semantics-negative-700);

      path {
        stroke: var(--plt-semantics-negative-700);
      }
    }
  }
}

.plt-iconbtn {
  --plt-iconbtn-size: var(--plt-size-800);
  --plt-iconbtn-bg-color: var(--plt-background-tertiary);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: var(--plt-iconbtn-size);
  max-width: var(--plt-iconbtn-size);
  min-height: var(--plt-iconbtn-size);
  max-height: var(--plt-iconbtn-size);
  background: var(--plt-iconbtn-bg-color);
  padding: 0;
  border-radius: var(--plt-border-radius-150);
  border: 1px solid transparent;
  transition: all 0.35s;
  cursor: pointer;

  &-sm {
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;
    // background-color: transparent;
  }

  &.plt-background-surface {
    background: var(--plt-background-surface);
  }

  svg {
    * {
      stroke: var(--plt-text-primary);
      transition: all 0.35s;
    }
  }

  &:hover {
    background: var(--plt-background-tertiary-hover);
  }

  .plt-btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


// color input  picker arrow alignment fix
.flexbox-fix > div > div:last-child {
  margin-top: 0px !important;
  height: fit-content;
  margin-left: 5px;
}

.flexbox-fix > div > div:nth-child(4) {
  margin-top: 0px;
}

.flexbox-fix > .flexbox-fix > div > div:nth-child(2) {
  width: fit-content !important;
}

.pointer-none {
  pointer-events: none;
}

// error form validation

.dss-error-container {
  display: flex;
  align-items: center;
  gap: var(--dss-spacing-100);
  transform: translateY(-6px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
  z-index: -1;

  &.active {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }

  .dss-error-message {
    color: var(--dss-text-negative);
    font-size: var(--dss-font-size-200);
    letter-spacing: var(--dss-font-letter-spacing-normal);
    line-height: var(--_input-validation-line-height);
  }
}




// .css-1jqq78o-placeholder,.css-qbdosj-Input input,.css-1dimb5e-singleValue {
//   font-size: var(--plt-font-size-200);
//   font-weight: var(--plt-font-weight-medium);

// }

// .react-select__menu {
//   box-shadow: none;
//   border: 1px solid var(--plt-border-secondary);
//   border-radius: var(--plt-border-radius-100);
//   padding: var(--plt-space-200);
//   min-width: 155px;
//   right: 0;
// }



.error-img {
  max-width: 246px;
}


.user-image {
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.user-image-sm {
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  border-radius: 50%;
  overflow: hidden;
}

.letter-avatar {
  background-color: var(--plt-background-inverse) !important;
  color: var(--plt-text-on-bg-fill) !important;
  border-color: var(--plt-background-inverse) !important;
  transition: all 0.35s;
}
