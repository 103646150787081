.dss-container,
.plt-modal-overlay:not(.plt) {

  // --plt-neutrals-100: red;
  // --plt-neutrals-1000: green;

  --plt-background-inverse: var(--dss-color-neutrals-1000);
  --plt-background-primary: var(--dss-color-alpha-neutrals-200-a);
  --plt-background-secondary: var(--dss-color-neutrals-300);
  --plt-background-tertiary: var(--dss-color-neutrals-200);
  --plt-background-surface: var(--dss-color-neutrals-100);
  --plt-background-on-bg-default: var(--dss-color-neutrals-100);
  --plt-background-tertiary-hover: var(--dss-color-neutrals-300);
  --plt-background-neutral: var(--dss-color-alpha-neutrals-200-a);

  --plt-text-primary: var(--dss-color-neutrals-1000);
  --plt-text-secondary: var(--dss-color-neutrals-700);
  --plt-text-tertiary: var(--dss-color-neutrals-500);
  --plt-text-on-bg-fill: var(--dss-color-neutrals-100);

  --plt-border-primary: var(--dss-color-neutrals-200);
  --plt-border-secondary: var(--dss-color-neutrals-300);

  --plt-text-button-link-primary: var(--dss-color-primary-500);
  --plt-border-input: var(--plt-border-secondary);
}
