.dss-tag {
  --_tag-spacing: var(--dss-spacing-chip-inset-normal-default);
  --_tag-border-radius: var(--dss-border-radius-chip-normal);
  --_tag-bg-color: var(--dss-color-background-chip-default-default);
  --_tag-border-color: var(--dss-color-border-chip-default-default);
  --_tag-bg-color-hovered: var(--dss-color-background-chip-default-hovered);
  --_tag-bg-color-pressed: var(--dss-color-background-chip-default-pressed);
  --_tag-font-color: var(--dss-color-text-chip-default-default);
  // --_tag-font-size:var(--dss-font-chip-uitext-normal-label-text-font-size);
  // --_tag-font-weight:var(--dss-font-chip-uitext-normal-label-text-font-weight);
  // --_tag-font-lineHeight:var(--dss-font-chip-uitext-normal-label-text-line-height);
  --_tag-font-spacing: var(--dss-font-letter-spacing-normal);
  --_tag-label-font: var(--dss-font-chip-uitext-normal-label-text);
  --_tag-counter-font-color: var(--dss-color-text-chip-default-default);
  --_tag-counter-font: var(--dss-font-chip-uitext-normal-count-text);
  // --_tag-counter-font-size:var(--dss-font-chip-uitext-normal-label-text-font-size);
  // --_tag-counter-font-weight:var(--dss-font-chip-uitext-normal-label-text-font-weight);
  // --_tag-counter-font-lineHeight:var(--dss-font-chip-uitext-normal-label-text-line-height);
  --_tag-prefix-icon-size: var(--dss-sizing-chip-icon-normal);
  --_tag-close-icon-size: var(--dss-sizing-chip-icon-normal);
  --_tag-gap: var(--dss-spacing-chip-inline-normal-text-count);
  --_tag-leading-gap: var(--dss-spacing-chip-inline-normal-leadingicon-text);
  // --_tag-close-padding: var(--dss-spacing-100);
  --_tag-close-border-radius: var(--dss-border-radius-chip-normal);
  --_tag-close-bg-hovered: var(--dss-color-icon-chip-default-surface);
  --_tag-checkicon-color: var(--dss-color-icon-chip-default-checkicon);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--_tag-spacing);
  background-color: var(--_tag-bg-color);
  border: var(--dss-border-width-chip-normal) solid var(--_tag-border-color);
  cursor: pointer;
  transition: all .4s;
  border-radius: var(--_tag-border-radius);
  gap: var(--_tag-gap);
  max-width: max-content;



  .dss-tag-label{
    font: var(--_tag-label-font);
  }
  .dss-tag-counter {
    font: var( --_tag-counter-font);
  }
  // &.dss-tag-spacing-label  {
  //   --_tag-spacing: var(--dss-spacing-chip-inset-normal-default);
  // }
  &.dss-tag-spacing-labelStatusicon  {
    --_tag-spacing: var(--dss-spacing-chip-inset-normal-statusicon);
    .dss-tag-leading{
      --_tag-leading-gap: var(--dss-spacing-chip-inline-normal-statusicon-text);
    }
  }
  &.dss-tag-spacing-labelCloseicon {
    --_tag-spacing: var(--dss-spacing-chip-inset-normal-closeicon);
    .dss-tag-leading{
      --_tag-leading-gap: var(--dss-spacing-chip-inline-normal-text-closableicon);
    }
  }

  span {
    display: none;
  }

  &:hover {
    --_tag-bg-color: var(--_tag-bg-color-hovered);
    --_tag-font-color: var(--dss-color-text-chip-default-hovered);
    --_tag-border-color: var(--dss-color-border-chip-default-hovered);
    --_tag-counter-font-color: var(--dss-color-text-chip-default-hovered);
    .dss-close-icon{
      background-color: var(--_tag-close-bg-hovered);
    }
  }

  &:focus {
    --_tag-bg-color: var(--_tag-bg-color-pressed);
    --_tag-font-color: var(--dss-color-text-chip-default-pressed);
    --_tag-border-color: var(--dss-color-border-chip-default-pressed);
    --_tag-counter-font-color: var(--dss-color-text-chip-default-pressed);
  }

  &-leading {
    display: flex;
    align-items: center;
    gap: var(--_tag-leading-gap);
  }

  &-label{
    color: var(--_tag-font-color);
    // font-size: var(--_tag-font-size);
    // font-weight: var(--_tag-font-weight);
    // line-height: var(--_tag-font-lineHeight);
    letter-spacing: var(--_tag-font-spacing);
  }
  &.disabled {
    --_tag-bg-color: var(--dss-color-background-chip-default-disabled);
    --_tag-font-color: var(--dss-color-text-chip-default-disabled);
    --_tag-border-color: var(--dss-color-border-chip-default-disabled);
    cursor: no-drop;
    &:hover,&:focus{
      --_tag-bg-color: var(--dss-color-background-chip-default-disabled);
      --_tag-font-color: var(--dss-color-text-chip-default-disabled);
      --_tag-border-color: var(--dss-color-border-chip-default-disabled);
    }
    .dss-tag-label {
      color: var(--dss-color-text-chip-default-disabled);
    }
    .dss-tag-counter {
      color: var(--dss-color-text-chip-default-disabled);
    }
    .dss-prefix-icon svg * {
      stroke: var(--dss-color-text-chip-default-disabled);
    }
    .dss-close-icon path {
      stroke: var(--dss-color-text-chip-default-disabled);
    }
  }


  &-large {
    --_tag-gap: var(--dss-spacing-chip-inline-large-text-count);
    --_tag-spacing: var(--dss-spacing-chip-inset-large-default);
    --_tag-leading-gap: var(--dss-spacing-chip-inline-large-leadingicon-text);
    --_tag-label-font: var(--dss-font-chip-uitext-large-label-text);
    --_tag-counter-font: var(--dss-font-chip-uitext-large-count-text);
    // --_tag-bg-color:var(--dss-background-primary);
    // --_tag-bg-color-hovered:var(--dss-background-secondary);
    // --_tag-bg-color-hovered:var(--dss-background-secondary);
    // --_tag-bg-color-pressed:var(--dss-background-secondary);
    // --_tag-font-color:var(--dss-color-text-form-primary);
    // --_tag-font-size:var(--dss-font-size-300);
    // --_tag-font-weight:var(--dss-font-weight-medium);
    // --_tag-font-lineHeight:var(--dss-line-height-120);
    // --_tag-font-spacing:var(--dss-font-letter-spacing-normal);
    // --_tag-prefix-icon-size:var(--dss-sizing-chip-icon-normal);
    // --_tag-prefix-icon-margin-right:var(--dss-spacing-100);
    // --_tag-counter-margin-left:var(--dss-spacing-150);
    // --_tag-close-margin-left:var(--dss-spacing-150);
    // --_tag-close-padding:var(--dss-spacing-100);
    // --_tag-close-border-radius:var(--dss-border-radius-chip-normal);
    // --_tag-close-bg-hovered:var(--dss-background-secondary);
    // --_tag-font-size:var(--dss-font-chip-uitext-large-label-text-font-size);
    // --_tag-font-weight:var(--dss-font-chip-uitext-large-label-text-font-weight);
    // --_tag-font-lineHeight:var(--dss-font-chip-uitext-large-label-text-line-height);
   
    // --_tag-counter-font-size:var(--dss-font-chip-uitext-large-label-text-font-size);
    // --_tag-counter-font-weight:var(--dss-font-chip-uitext-large-label-text-font-weight);
    // --_tag-counter-font-lineHeight:var(--dss-font-chip-uitext-large-label-text-line-height);
    // .dss-tag-label{
    //   font: var(--dss-font-chip-uitext-large-label-text);
    // }
    // .dss-tag-counter {
    //   font: var(--dss-font-chip-uitext-large-count-text);
    // }
    // &.dss-tag-spacing-label  {
    //   --_tag-spacing: var(--dss-spacing-chip-inset-large-default);
    // }
    &.dss-tag-spacing-labelStatusicon  {
      --_tag-spacing: var(--dss-spacing-chip-inset-large-statusicon);
      .dss-tag-leading{
        --_tag-leading-gap: var(--dss-spacing-chip-inline-large-statusicon-text);
      }
    }
    &.dss-tag-spacing-labelCloseicon {
      --_tag-spacing: var(--dss-spacing-chip-inset-large-closeicon);
      .dss-tag-leading{
        --_tag-leading-gap: var(--dss-spacing-chip-inline-large-text-closableicon);
      }
    }
  }

  &-small {
    --_tag-gap: var(--dss-spacing-chip-inline-dense-text-count);
    --_tag-spacing: var(--dss-spacing-chip-inset-dense-default);
    --_tag-leading-gap: var(--dss-spacing-chip-inline-dense-leadingicon-text);
    --_tag-label-font: var(--dss-font-chip-uitext-dense-label-text);
    --_tag-counter-font: var(--dss-font-chip-uitext-dense-count-text);
    // --_tag-font-size:var(--dss-font-chip-uitext-dense-label-text-font-size);
    // --_tag-font-weight:var(--dss-font-chip-uitext-dense-label-text-font-weight);
    // --_tag-font-lineHeight:var(--dss-font-chip-uitext-dense-label-text-line-height);
    // --_tag-counter-font-size:var(--dss-font-chip-uitext-dense-label-text-font-size);
    // --_tag-counter-font-weight:var(--dss-font-chip-uitext-dense-label-text-font-weight);
    // --_tag-counter-font-lineHeight:var(--dss-font-chip-uitext-dense-label-text-line-height);
    // .dss-tag-label{
    //   font: var(--dss-font-chip-uitext-dense-label-text);
    // }
    // .dss-tag-counter {
    //   font: var(--dss-font-chip-uitext-dense-count-text);
    // }
    &.dss-tag-spacing-label  {
      --_tag-spacing: var(--dss-spacing-chip-inset-dense-default);
    }
    &.dss-tag-spacing-labelStatusicon  {
      --_tag-spacing: var(--dss-spacing-chip-inset-dense-statusicon);
      .dss-tag-leading{
        --_tag-leading-gap: var(--dss-spacing-chip-inline-dense-statusicon-text);
      }
    }
    &.dss-tag-spacing-labelCloseicon {
      --_tag-spacing: var(--dss-spacing-chip-inset-dense-closeicon);
      .dss-tag-leading{
        --_tag-leading-gap: var(--dss-spacing-chip-inline-dense-text-closableicon);
      }
    }
  }

  .dss-prefix-icon {
    display: inline-flex;
    min-width: var(--_tag-prefix-icon-size);
    max-width: var(--_tag-prefix-icon-size);
    min-height: var(--_tag-prefix-icon-size);
    max-height: var(--_tag-prefix-icon-size);

    svg {
      width: var(--_tag-prefix-icon-size);
      height: var(--_tag-prefix-icon-size);
      path {
        fill: var(--_tag-checkicon-color);
      }
    }
  }

  .dss-tag-counter {
    display: inline-flex;
    color: var(--_tag-counter-font-color);
    // font-size: var(--_tag-counter-font-size);
    // font-weight: var(--_tag-counter-font-weight);
    // line-height: var(--_tag-counter-font-lineHeight);
  }

  .dss-tag-label {
    display: inline-flex;
    width: max-content;
  }

  .dss-close-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    // padding: var(--_tag-close-padding);
    min-width: var(--_tag-close-icon-size);
    max-width: var(--_tag-close-icon-size);
    min-height: var(--_tag-close-icon-size);
    max-height: var(--_tag-close-icon-size);
    border-radius: var(--_tag-close-border-radius);
    transition: all .4s;
    // &:hover {
    //   background-color: var(--_tag-close-bg-hovered);
    // }
  }

  &-positive {
    --_tag-bg-color: var(--dss-color-background-chip-positive-default);
    --_tag-font-color: var(--dss-color-text-chip-positive-default);
    --_tag-border-color: var(--dss-color-border-chip-positive-default);
    --_tag-checkicon-color: var(--dss-color-icon-chip-positive-checkicon);
    &:hover {
      --_tag-bg-color-hovered: var(--dss-color-background-chip-positive-hovered);
      --_tag-font-color: var(--dss-color-text-chip-positive-hovered);
      --_tag-border-color: var(--dss-color-border-chip-positive-hovered);
      .dss-close-icon{
        background-color: var(--_tag-close-bg-hovered);
      }
    }
  
    &:focus {
      --_tag-bg-color-pressed: var(--dss-color-background-chip-positive-pressed);
      --_tag-font-color: var(--dss-color-text-chip-positive-pressed);
      --_tag-border-color: var(--dss-color-border-chip-positive-pressed);
    }
  }

  &-negative {
    --_tag-bg-color: var(--dss-color-background-chip-negative-default);
    --_tag-font-color: var(--dss-color-text-chip-negative-default);
    --_tag-border-color: var(--dss-color-border-chip-negative-default);
    --_tag-checkicon-color: var(--dss-color-icon-chip-negative-checkicon);
    &:hover {
      --_tag-bg-color-hovered: var(--dss-color-background-chip-negative-hovered);
      --_tag-font-color: var(--dss-color-text-chip-negative-hovered);
      --_tag-border-color: var(--dss-color-border-chip-negative-hovered);
      .dss-close-icon{
        background-color: var(--_tag-close-bg-hovered);
      }
    }
  
    &:focus {
      --_tag-bg-color-pressed: var(--dss-color-background-chip-negative-pressed);
      --_tag-font-color: var(--dss-color-text-chip-negative-pressed);
      --_tag-border-color: var(--dss-color-border-chip-negative-pressed);
    }
  }
}
