.iconography-container {
  max-width: 890px;
}

.plt-iconography-card-wrapper {
  --column-count: 5;
  display: grid;
  grid-template-columns: repeat(var(--column-count), 1fr);
  gap: var(--plt-space-200);
}

.plt-icon-card {
  min-width: 145px;
  max-width: max-content;
  border-radius: var(--plt-border-radius-300);
  background: var(--plt-background-tertiary);
}

.plt-dropdown-container {
  align-items: end;
}

.plt-input-search {
  min-width: 275px;

  .input-field {
    text-indent: 28px;
  }
}
