/* Custom Radio Group Styles */

.plt-custom-radio-group {
  --_plt-radio-gap-horizontal: var(--plt-space-600);
  --_plt-radio-gap-vertical: var(--plt-space-400);
  --_plt-radio-label-padding-left: var(--plt-space-800);
  --_plt-radio-border-width: var(--plt-border-width-100);
  --_plt-radio-border-color: var(--plt-background-primary);
  --_plt-radio-border-radius: var(--plt-border-radius-full);
  --_plt-radio-outer-circle-size: var(--plt-size-600);
  --_plt-radio-outer-inner-size: var(--plt-font-size-300);
  --_plt-radio-hover-border-color: var(--plt-background-selected);
  --_plt-radio-checked-border-color: var(--plt-background-selected);
  --_plt-radio-dot-color: var(--plt-background-selected);
  --_plt-radio-border-width-outlined: var(--plt-border-width-500);
  --_plt-radio-text-disabled: var(--plt-background-disabled);
  --_plt-radio-text-font-size: var(--plt-font-size-200);
  --_plt-radio-text-font-weight: var(--plt-font-weight-regular);
  --_plt-radio-text-color: var(--plt-text-primary);
  padding: var(--plt-space-300) var(--plt-space-600);

  display: flex;
  gap: var(--_plt-radio-gap-horizontal);

  &-vertical {
    flex-direction: column;
    gap: var(--_plt-radio-gap-vertical);
  }

  .plt-radio-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    .plt-radio[type="radio"] {
      &:disabled,
      &.disabled,
      &[aria-disabled="true"] {
        pointer-events: none;

        &:not(:checked)+.plt-radio-label::after {
          opacity: 1;
          transform: scale(1);
        }

        &+.plt-radio-label {
          transition: all .4s;
          color: var(--_plt-radio-text-disabled);

          &::before {
            --_plt-radio-border-color: var(--plt-background-disabled);
            --_plt-radio-hover-border-color: var(--plt-background-disabled);
          }

          &::after {
            --_plt-radio-dot-color: var(--plt-background-disabled);
          }
        }
      }

      &:checked,
      &:not(:checked) {
        position: absolute;
        top: 0;
        left: 0;
        height: 0;
        width: 0;
        opacity: 0;
        visibility: hidden;
      }

      &:checked+.plt-radio-label,
      &:not(:checked)+.plt-radio-label {
        position: relative;
        padding-left: var(--_plt-radio-label-padding-left);
        cursor: pointer;
        display: inline-block;
        font-size: var(--_plt-radio-text-font-size);
        font-weight: var(--_plt-radio-text-font-weight);
        color: var(--_plt-radio-text-color);

        &:hover {
          &::before {
            --_plt-radio-border-color: var(--_plt-radio-hover-border-color);
          }
        }
      }

      &:checked+.plt-radio-label:before,
      &:not(:checked)+.plt-radio-label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: var(--_plt-radio-outer-circle-size);
        height: var(--_plt-radio-outer-circle-size);
        border: var(--_plt-radio-border-width) solid
          var(--_plt-radio-border-color);
        border-radius: var(--_plt-radio-border-radius);
        transition: all 300ms ease;
        transition-property: border;
      }

      &:checked+.plt-radio-label::before {
        border: var(--_plt-radio-border-width) solid
          var(--_plt-radio-checked-border-color);
      }

      &:checked+.plt-radio-label:after,
      &:not(:checked)+.plt-radio-label:after {
        content: "";
        width: var(--_plt-radio-outer-inner-size);
        height: var(--_plt-radio-outer-inner-size);
        background-color: var(--_plt-radio-dot-color);
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 100%;
        transition: all var(--_plt-radio-transition-duration) ease;
        display: inline-block;
      }

      &:not(:checked)+.plt-radio-label::after {
        opacity: 0;
        transform: scale(0);
      }

      &:checked+.plt-radio-label::after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  .plt-radio-label-outlined {
    position: relative;
    padding-left: var(--_plt-radio-label-padding-left);
    cursor: pointer;
    display: inline-block;
    font-size: var(--_plt-radio-text-font-size);
    font-weight: var(--_plt-radio-text-font-weight);
    color: var(--_plt-radio-text-color);

    &::before {
      content: "";
      position: absolute;
      left: 2px;
      top: 0;
      width: var(--_plt-radio-outer-circle-size);
      height: var(--_plt-radio-outer-circle-size);
      border: var(--_plt-radio-border-width) solid
        var(--_plt-radio-border-color);
      border-radius: var(--_plt-radio-border-radius);
      transition: all 300ms ease;
      transition-property: border;
    }

    &:hover::before {
      --_plt-radio-border-color: var(--_plt-radio-hover-border-color);
    }

    &:checked::before {
      border: var(--_plt-radio-border-width-outlined) solid
        var(--_plt-radio-checked-border-color);
    }

    &:after {
      content: "";
      width: var(--_plt-radio-outer-inner-size);
      height: var(--_plt-radio-outer-inner-size);
      background-color: var(--_plt-radio-dot-color);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      transition: all var(--_plt-radio-transition-duration) ease;
      display: inline-block;
    }

    &:not(:checked):after {
      opacity: 0;
      transform: scale(0);
    }

    &:checked:after {
      opacity: 1;
      transform: scale(1);
    }
  }

  .plt-radio-outlined[type="radio"] {
    display: none;

    &:checked + .plt-radio-label-outlined::before {
      border: var(--_plt-radio-border-width-outlined) solid
        var(--_plt-radio-checked-border-color);
    }

    &:disabled,
    &.disabled,
    &[aria-disabled="true"] {
      pointer-events: none;

      &:not(:checked)+.plt-radio-label-outlined::after {
        opacity: 0;
        transform: scale(1);
      }

      &+.plt-radio-label-outlined {
        color: var(--_plt-radio-text-disabled);

        &::before {
          --_plt-radio-hover-border-color: var(--plt-background-disabled);
          border: var(--_plt-radio-border-width-outlined) solid
            var(--plt-background-disabled);
        }

        &::after {
          --_plt-radio-border-color: var(--plt-background-disabled);
        }
      }
    }
  }

  &-xs {
    --_plt-radio-outer-circle-size: var(--plt-size-400);
    --_plt-radio-outer-inner-size: var(--plt-size-200);
  }

  &-md {
    --_plt-radio-outer-circle-size: var(--plt-size-500);
    --_plt-radio-outer-inner-size: var(--plt-size-300);
  }
}

