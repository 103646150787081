.plt-select-members-wrapper {
  .plt-members-group {
    padding: var(--plt-space-300) 0 var(--plt-space-400) 0;
    .plt-members-list {
      margin-top: var(--plt-space-300);
    }
  }
  .plt-member-img {
    min-width: var(--plt-size-600);
    max-width: var(--plt-size-600);
    min-height: var(--plt-size-600);
    max-height: var(--plt-size-600);
    img {
      min-width: var(--plt-size-600);
    max-width: var(--plt-size-600);
    min-height: var(--plt-size-600);
    max-height: var(--plt-size-600);
    }
  }
  
}

.copy-btn-link {
  position: absolute;
  margin-top: 28px;
  margin-left: 24px;
}