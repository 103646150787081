.plt-menu-dropdown {
  position: relative;
  .user-dropdown {
    top: auto;
    right: 8px;
  }
}


.plt-filter-wrapper{
  gap: var(--plt-space-150);
  .plt-filter-list {
    .plt-btn {
      background: var(--plt-background-secondary);
      padding: var(--plt-space-200);
    }
    .user-dropdown {
      top: 40px;
      .dropdown-item {
        min-width: auto;
      }
    }
  }
}


// .plt-role-description {
//   min-width: 100px;
// }

.plt-date-detail {
  max-width: 111px;
}