
.plt-file-group {
  list-style: none;
  .plt-file-list {
    margin-top: var(--plt-space-300);
    gap: var(--plt-space-300);
    .plt-member-img {
      min-width: var(--plt-size-600);
      max-width: var(--plt-size-600);
      min-height: var(--plt-size-600);
      max-height: var(--plt-size-600);
      img {
        min-width: var(--plt-size-600);
        max-width: var(--plt-size-600);
        min-height: var(--plt-size-600);
        max-height: var(--plt-size-600);
      }
  }
  }
}